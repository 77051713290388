import emailjs from '@emailjs/browser';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Footer from 'components/Footer';
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import NavBar from 'components/NavBar';
import { ContactUsPage } from 'constants/images';
import { hotelRooms } from 'data/roomData';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import HeaderTwo from 'layouts/sections/page-sections/page-headers/components/HeaderTwo';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import CountrySelect from './CountrySelect';

dayjs.extend(isSameOrAfter);

function ContactUs() {
  const navigate = useNavigate();
  const formRef = useRef();
  const [selectBasis, setSelectBasis] = useState("Room Only");
  const [isMobile, setIsMobile] = useState(false);
  const [selectHotel, setSelectHotel] = useState("Nuwara Eliya");
  const [selectRoom, setSelectRoom] = useState(
    "Deluxe Double Room without Balcony"
  );

  const sendSimpleMessage = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: form.name,
          to_name: "Heaven Seven Hotels",
          from_email: form.email,
          to_email: "info@heavensevenhotels.com",
          message: `Name : ${form.name} \n Email : ${form.email} \n Address : ${form.address} \n Country : ${form.country} \n Phone Number : ${form.phone} \n Hotel : ${form.hotel} \n Room : ${form.room} \n Basis : ${form.basis} \n Checkin : ${form.checkin} \n Checkout : ${form.checkout} \n Nights : ${form.nights} \n Adult Count: ${form.adult} \n Child Count: ${form.child}`,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          navigate("/pages/thank-you");
        },
        (error) => {
          console.error(error);
          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };
  useEffect(() => {
    // Function to check the window width

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // You can adjust the width as per your requirement
    };

    handleResize(); // Check the initial window size
    window.addEventListener("resize", handleResize); // Add resize event listener

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [checkinDate, setCheckinDate] = useState(dayjs());
  const [checkoutDate, setCheckoutDate] = useState(dayjs().add(1, "day"));
  const [numberOfNights, setNumberOfNights] = useState(1);
  const [form, setForm] = useState({
    name: "",
    email: "",
    address: "",
    country: "",
    phone: "",
    hotel: "Nuwara Eliya",
    room: "Deluxe Double Room without Balcony",
    basis: "Room Only",
    checkin: dayjs().format("YYYY-MM-DD"),
    checkout: dayjs().add(1, "day").format("YYYY-MM-DD"),
    nights: 1,
    adult: 0,
    child: 0,
  });

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    // Handle number inputs with minimum values
    if (name === "adult" || name === "child") {
      const numValue = parseInt(value);
      if (name === "adult" && numValue < 1) return; // Don't allow less than 1 adult
      if (name === "child" && numValue < 0) return; // Don't allow negative children
    }

    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleCheckinChange = (newDate) => {
    setCheckinDate(newDate);
    // Ensure checkout date remains valid
    if (!newDate.isBefore(checkoutDate, "day")) {
      const newCheckoutDate = newDate.add(1, "day");
      setCheckoutDate(newCheckoutDate);
      setNumberOfNights(newCheckoutDate.diff(newDate, "day")); // Update nights
      setForm((prev) => ({
        ...prev,
        checkin: newDate,
        checkout: newCheckoutDate,
        nights: newCheckoutDate.diff(newDate, "day"),
      }));
    } else {
      setNumberOfNights(checkoutDate.diff(newDate, "day")); // Update nights
      setForm((prev) => ({
        ...prev,
        checkin: newDate,
        nights: checkoutDate.diff(newDate, "day"),
      }));
    }
    handleChange({
      target: { name: "checkin", value: newDate.format("YYYY-MM-DD") },
    });
  };

  const handleCheckoutChange = (newDate) => {
    if (newDate.isAfter(checkinDate, "day")) {
      setCheckoutDate(newDate);
      setNumberOfNights(newDate.diff(checkinDate, "day"));
      setForm((prev) => ({
        ...prev,
        checkout: newDate,
        nights: newDate.diff(checkinDate, "day"),
      }));
    }
    handleChange({
      target: { name: "checkout", value: newDate.format("YYYY-MM-DD") },
    });
  };

  const HotelInfo = ({ title, address, email, reservations, restaurant }) => {
    return (
      <MKBox px={4} py={2}>
        <MKTypography
          variant="h4"
          mb={2}
          sx={{ fontWeight: "bold", fontFamily: "Italiana" }}
        >
          {title}
        </MKTypography>
        <Grid
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <LocationOnIcon sx={{ marginRight: "8px" }} />
          <Grid sx={{ marginLeft: 2 }}>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Address
            </MKTypography>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 300, fontSize: 16 }}
            >
              {address}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <EmailIcon sx={{ marginRight: "8px" }} />
          <Grid sx={{ marginLeft: 2 }}>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Email
            </MKTypography>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 300, fontSize: 16 }}
            >
              {email}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <PhoneIcon sx={{ marginRight: "8px" }} />
          <Grid sx={{ marginLeft: 2 }}>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Reservation
            </MKTypography>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 300, fontSize: 16 }}
            >
              {reservations}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <RestaurantIcon sx={{ marginRight: "8px" }} />
          <Grid sx={{ marginLeft: 2 }}>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Restaurant
            </MKTypography>
            <MKTypography
              sx={{ fontFamily: "Lexend", fontWeight: 300, fontSize: 16 }}
            >
              {restaurant}
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>
    );
  };

  const ContactDetails = () => {
    return (
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          pt: 4,
          gap: { xs: 4, md: 0 }, // Adds spacing between stacked items on mobile
        }}
      >
        <Grid sx={{ width: { xs: "100%", md: "47%" } }}>
          <HotelInfo
            title="Heaven Seven Nuwaraeliya"
            address="24A Haddon Hill Rd, Nuwara Eliya 22200"
            email="nuwaraeliya@heavenseven.com"
            reservations="+94 522 234 256"
            restaurant="+94 522 234 256"
          />
        </Grid>

        {/* Vertical Divider */}
        <MKBox
          sx={{
            width: "1px",
            height: "auto",
            backgroundColor: "#ddd",
            mx: 2,
            display: { xs: "none", md: "block" },
          }}
        />

        <Grid sx={{ width: { xs: "100%", md: "50%" } }}>
          <HotelInfo
            title="Heaven Seven Kandy"
            address="25/8 Sangaraja Mawatha, Hillwood College Road, Kandy 20000"
            email="kandy@heavenseven.com"
            reservations="+94 710 482 846"
            restaurant="+94 770 049 528"
          />
        </Grid>
      </MKBox>
    );
  };

  const GeneralContact = () => {
    return (
      <MKBox
        px={{ xs: 2, md: 4 }}
        py={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {/* Address and Email Row */}
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={3}
          sx={{
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
          }}
        >
          {/* Address */}
          <MKBox
            display="flex"
            alignItems="center"
            mr={{ sm: 3 }}
            mb={{ xs: 1, sm: 0 }}
          >
            <LocationOnIcon sx={{ marginRight: "8px" }} />
            <MKBox>
              <MKTypography
                variant="body1"
                sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
              >
                Address
              </MKTypography>
              <MKTypography variant="body2">
                24A Haddon Hill Rd, Nuwara Eliya 22200
              </MKTypography>
            </MKBox>
          </MKBox>

          {/* Vertical Divider */}
          <MKBox
            sx={{
              height: "40px",
              width: "1px",
              backgroundColor: "#ddd",
              mx: 3,
              display: { xs: "none", sm: "block" },
            }}
          />

          {/* Email */}
          <MKBox display="flex" alignItems="center">
            <EmailIcon sx={{ marginRight: "8px" }} />
            <MKBox>
              <MKTypography
                variant="body1"
                sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
              >
                Email
              </MKTypography>
              <MKTypography variant="body2">
                nuwaraeliya@heavenseven.com
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>

        {/* Contact Details Row */}
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
          }}
        >
          {/* Front Office */}
          <MKBox textAlign="center" mx={{ sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MKTypography
              variant="body1"
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Front Office
            </MKTypography>
            <MKTypography variant="body2">+94 522 234 256</MKTypography>
          </MKBox>

          {/* Vertical Divider */}
          <MKBox
            sx={{
              height: "40px",
              width: "1px",
              backgroundColor: "#ddd",
              mx: 3,
              display: { xs: "none", sm: "block" },
            }}
          />

          {/* Hotline */}
          <MKBox textAlign="center" mx={{ sm: 2 }} mb={{ xs: 1, sm: 0 }}>
            <MKTypography
              variant="body1"
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Hotline
            </MKTypography>
            <MKTypography variant="body2">
              +94 52 223 4256, +94 77 003 8956
            </MKTypography>
          </MKBox>

          {/* Vertical Divider */}
          <MKBox
            sx={{
              height: "40px",
              width: "1px",
              backgroundColor: "#ddd",
              mx: 3,
              display: { xs: "none", sm: "block" },
            }}
          />

          {/* Front Office Again */}
          <MKBox textAlign="center" mx={{ sm: 2 }}>
            <MKTypography
              variant="body1"
              sx={{ fontFamily: "Lexend", fontWeight: 500, fontSize: 16 }}
            >
              Front Office
            </MKTypography>
            <MKTypography variant="body2">+94 522 234 256</MKTypography>
          </MKBox>
        </MKBox>
      </MKBox>
    );
  };

  const contactPlaces = [
    {
      title: "Head Office",
      subHead: "(SLTDA Reg.No: SLTDA/SQA/TA/01806)",
      des: "Kandanegedara Road, Weralugama,",
      des2: "Kuliyapitiya, Sri Lanka,",
      des3: "+94 37 2281908",
    },
    {
      title: "UAE",
      des: "AI Bassam Building,4th Floor, Port Saeed,",
      des2: "Deira-Dubai",
      des3: "+971 50 316 6948",
    },
    {
      title: "Australia",
      des: "890 North Road,",
      des2: "Bentleigh East VIC 3165",
      des3: "+61 43 103 7027",
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ backgroundColor: "#FEFDF5" }}>
        {/* The NavBar component should be placed at the top level of your main layout */}
        <NavBar /> {/* Include NavBar at the top */}
        <div style={{ overflow: "hidden" }}>
          <HeaderTwo
            title="Contact Us"
            backgroundImage={ContactUsPage.Header}
            subHead="asasas"
          />
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "40px",
              marginTop: 7,
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                lg={8}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", marginBottom: "20px" }}
              >
                <div className="text-with-lines">
                  <MKTypography
                    variant="h1"
                    color="black"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "60px",
                      fontWeight: 400,
                    })}
                  >
                    Get in touch
                  </MKTypography>
                </div>
                <MKTypography
                  variant="h6"
                  fontWeight="regular"
                  color="black"
                  sx={{
                    textAlign: "center",
                    maxWidth: "90%",
                    fontFamily: "Lexend",
                  }}
                >
                  We hope to welcome you to our world-class hotels soon
                </MKTypography>
              </Grid>
            </Container>
            <ContactDetails />
          </Grid>
          {/* <img
            src={ContactUsPage?.ContactUs_Map}
            alt="My SVG"
            style={{
              width: "90%",
              height: "400px",
              justifySelf: "center",
              display: "flex",
              borderRadius: "20px",
            }}
          /> */}
          {/* <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "40px",
              marginTop: 7,
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                lg={8}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", marginBottom: "20px" }}
              >
                <div className="text-with-lines">
                  <MKTypography
                    variant="h1"
                    color="black"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "60px",
                      fontWeight: 400,
                    })}
                  >
                    General Inquiries
                  </MKTypography>
                </div>
              </Grid>
            </Container>
            <GeneralContact />
          </Grid> */}

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginTop: 7,
              position: "relative",
              paddingBottom: 20,

              "&::before": {
                content: '""',
                position: "absolute",
                width: "100%",
                height: "50%",
                backgroundImage: `url(${ContactUsPage.ContactUsConnect})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                opacity: 1,
                zIndex: 0,
                bottom: 0,
              },
              zIndex: 2,
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                lg={8}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
              >
                <div className="text-with-lines">
                  <MKTypography
                    variant="h1"
                    color="black"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "60px",
                      fontWeight: 400,
                    })}
                  >
                    Connect with Us
                  </MKTypography>
                </div>
              </Grid>
            </Container>
            <Grid
              container
              spacing={2}
              lg={12}
              sx={{ zIndex: 100, justifyContent: "center" }}
            >
              <MKBox
                sx={{
                  backgroundColor: "#fff",
                  padding: { xs: "1rem", md: "2rem" },
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  width: { xs: "90%", md: "100%" },
                  margin: "0 auto",
                }}
                component="form"
                onSubmit={sendSimpleMessage}
                ref={formRef}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {/* Left Column - Personal Details */}
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              label="Name"
                              type="text"
                              variant="standard"
                              fullWidth
                              required
                              name="name"
                              onChange={handleChange}
                              value={form.name}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Email"
                              type="email"
                              variant="standard"
                              fullWidth
                              required
                              name="email"
                              onChange={handleChange}
                              value={form.email}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Address"
                              type="text"
                              variant="standard"
                              fullWidth
                              required
                              name="address"
                              onChange={handleChange}
                              value={form.address}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <CountrySelect
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "country",
                                    value: e.target.value,
                                  },
                                });
                              }}
                              value={form.country}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Contact Number"
                              type="tel"
                              variant="standard"
                              fullWidth
                              required
                              name="phone"
                              onChange={handleChange}
                              value={form.phone}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Right Column - Booking Details */}
                      <Grid item xs={12} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Grid container spacing={2.5}>
                          <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">
                                Select a hotel
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectHotel}
                                name="hotel"
                                onChange={(e) => {
                                  setSelectHotel(e.target.value);
                                  handleChange(e);
                                }}
                              >
                                <MenuItem value={"Nuwara Eliya"}>
                                  Nuwara Eliya Hotel
                                </MenuItem>
                                <MenuItem value={"Kandy"}>Kandy Hotel</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">
                                Select a room
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectRoom}
                                name="room"
                                onChange={(e) => {
                                  setSelectRoom(e.target.value);
                                  handleChange(e);
                                }}
                              >
                                {hotelRooms
                                  .find((hotel) => hotel.location === selectHotel)
                                  ?.rooms.map((room) => (
                                    <MenuItem value={room}>{room}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">
                                Basis
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectBasis}
                                name="basis"
                                onChange={(e) => {
                                  setSelectBasis(e.target.value);
                                  handleChange(e);
                                }}
                              >
                                <MenuItem value="Room Only">Room Only</MenuItem>
                                <MenuItem value="Bed and Breakfast">
                                  Bed and Breakfast
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md ={4}>
                            <FormControl variant="standard" fullWidth>
                              <DatePicker
                                label="Check-in Date"
                                value={checkinDate}
                                onChange={handleCheckinChange}
                                name="checkin"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md ={4}>
                            <FormControl variant="standard" fullWidth>
                              <DatePicker
                                label="Check-out Date"
                                value={checkoutDate}
                                onChange={handleCheckoutChange}
                                name="checkout"
                                minDate={checkinDate.add(1, "day")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md ={4}>
                            <FormControl variant="standard" fullWidth>
                              <TextField
                                label="Number of Nights"
                                value={numberOfNights}
                                InputProps={{ readOnly: true }}
                                variant="standard"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md ={6}>
                            <FormControl variant="standard" fullWidth>
                              <TextField
                                label="Adult Count"
                                type="number"
                                variant="standard"
                                fullWidth
                                required
                                name="adult"
                                onChange={handleChange}
                                inputProps={{ min: 1 }}
                                value={form.adult}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md ={6}>
                            <FormControl variant="standard" fullWidth>
                              <TextField
                                label="Child Count"
                                type="number"
                                variant="standard"
                                fullWidth
                                required
                                name="child"
                                onChange={handleChange}
                                inputProps={{ min: 0 }}
                                value={form.child}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <MKBox display="flex" justifyContent="center">
                      <MKButton
                        variant="contained"
                        size="large"
                        sx={{
                          borderRadius: "20px",
                          padding: { xs: "0.6rem 2rem", md: "0.8rem 4rem" },
                          backgroundColor: "#017836",
                          color: "#fff",
                          width: { xs: "100%", sm: "auto" },
                        }}
                        type="submit"
                      >
                        Submit
                      </MKButton>
                    </MKBox>
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>
          <Footer />
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default ContactUs;
