import React, { useEffect, useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { UilSuitcase } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";

const BookNow = () => {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  // Scroll handler to toggle sticky state
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const stickyStartPosition = 400; // Adjust based on when you want it to start sticking

      setIsSticky(scrollTop > stickyStartPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      <div
        style={{
          position: isSticky ? "fixed" : "absolute",
          top: isSticky ? "200px" : "400px",
          right: 0,
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          backgroundColor: "black",
          padding: "10px 20px",
          transform: "translate(33%, 0%) rotate(-90deg)",
          display: "flex",
          flexDirection: "column",
          zIndex: 1000,
          transition: "top 0.3s ease", // Smooth transition
          cursor: "pointer",
        }}
      >
        <Grid
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
          onClick={() => {
            navigate("/pages/contact-us");
          }}
        >
          <MKTypography
            color="white"
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              fontFamily: "Poppins, sans-serif",
              //
              lineHeight: "30px",
            }}
          >
            Book Now
          </MKTypography>
          <UilSuitcase color="white" size="20px" />
        </Grid>
      </div>
    </div>
  );
};

export default BookNow;
