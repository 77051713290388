import React, { useRef, useState, useEffect } from "react";
import MKButton from "components/MKButton";
import { useMediaQuery } from "@mui/material";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";
import ReviewCardHome from "components/ReviewCardHome";
import Footer from "components/Footer";
import foodImg from "assets/images/homePage/food.jpeg";
import { AccomadationPage, HomePage } from "constants/images";
import { UilArrowRight } from "@iconscout/react-unicons";
import BookNow from "components/BookNowButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ElfsightWidgetFrame from "components/GoogleReview";
import { ExperienceContent } from "constants/experianceContent";
import NavBar from "components/NavBar";
import "./styles.css";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

function Home() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const faq = [
    {
      title: "What are the check-in and check-out times at the hotel?",
      answer:
        "Check in time is from 2:00 PM, and check-out is until 12:00 PM. Early check-ins or late check-outs may be arranged upon request, subject to availability.",
    },
    {
      title: "Do you provide guided tours of nearby attractions?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "What dining options are available at the hotel?",
      answer:
        "Our hotels feature on-site restaurants offering a range of local and international cuisines. We also provide options for private dining, room service, and specially curated meals for dietary preferences or occasions.",
    },
    {
      title: "Is Wi-Fi available at the hotel?",
      answer:
        "Yes, complimentary high-speed Wi-Fi is available throughout the hotel, including guest rooms, public areas, and dining spaces.",
    },
    {
      title: "Can I host events or meetings at the hotel?",
      answer:
        "Yes, our hotel offers event spaces for meetings, conferences, weddings, and other special occasions. Our dedicated events team can help you with planning and coordination.",
    },
    {
      title: "Is parking available at the hotel?",
      answer:
        "Yes, complimentary parking is available for all our guests. We also provide valet services upon request.",
    },
  ];

  const ElfsightWidget = () => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://static.elfsight.com/platform/platform.js";
      script.async = true;

      // Delay appending the script to avoid ResizeObserver errors
      requestAnimationFrame(() => {
        document.body.appendChild(script);
      });

      return () => {
        document
          .querySelectorAll(
            'script[src="https://static.elfsight.com/platform/platform.js"]'
          )
          .forEach((el) => el.remove());
      };
    }, []);

    return (
      <div
        className="elfsight-app-2ac7c9b6-a4a8-4f4c-ad2c-73f0faa65178"
        data-elfsight-app-lazy
      ></div>
    );
  };

  const AccomadationCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          flex: 1,
          marginBottom: 5,
          position: "relative", // Important for overlay positioning
          "&:hover .overlay": {
            opacity: 1, // Show overlay on hover
          },
        })}
      >
        {/* Hover Overlay */}
        <Grid
          className="overlay"
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={({ breakpoints, typography: { size } }) => ({
            position: "absolute",
            width: "100%",
            height: "500px",
            [breakpoints.down("sm")]: {
              width: "100%",
              height: "350px",
            },
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay effect
            color: "#fff",
            textAlign: "center",
            opacity: 0, // Initially hidden
            transition: "opacity 0.5s ease-in-out",
          })}
        >
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
              fontFamily: "Italiana",
              fontSize: "30px",
              fontWeight: 400,
              width: "90%",
            })}
          >
            Room Size: {item?.size}
          </MKTypography>
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
              fontFamily: "Italiana",
              fontSize: "30px",
              fontWeight: 400,
              width: "90%",
            })}
          >
            Occupancy: {item?.occupancy}
          </MKTypography>
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
              fontFamily: "Italiana",
              fontSize: "30px",
              fontWeight: 400,
              width: "90%",
            })}
          >
            {item?.bed}
          </MKTypography>
          <MKButton
            variant="outlined"
            onClick={() => {
              if (item?.description === "Heaven Seven Nuwaraeliya") {
                navigate("/pages/acc_nuwaraeliya");
              } else {
                navigate("/pages/accomadation");
              }
            }}
            sx={{
              mt: 2,
              borderColor: "#fff",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
              zIndex: 1000,
            }}
          >
            Explore More
          </MKButton>
        </Grid>
        <Grid>
          <Grid
            container
            display="flex"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "500px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          >
            <Grid
              sx={{
                backgroundColor: "#ffffff",
                zIndex: 100,
                width: "70%",
                position: "absolute",
                marginTop: 3,
              }}
            >
              <MKTypography
                sx={({ breakpoints }) => ({
                  fontSize: "16px",
                  fontFamily: "Lexend",
                  lineHeight: "25px",
                  fontWeight: 400,
                  color: "#484848",
                  [breakpoints.down("sm")]: {
                    fontSize: "30px",
                  },
                  textAlign: "center",
                })}
              >
                {item?.description}
              </MKTypography>
            </Grid>

            <Grid
              sx={{
                border: "solid",
                borderWidth: 1,
                width: "95%",
                height: "95%",
                borderColor: "#ffffff",
                zIndex: 100,
                marginLeft: 1,
                alignSelf: "center",
              }}
            ></Grid>
          </Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "25px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#484848",
                [breakpoints.down("sm")]: {
                  fontSize: "30px",
                },
                textAlign: "center",
              })}
            >
              {item?.title}
            </MKTypography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const ExploreCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Grid sx={{ marginBottom: 5 }}>
        <Card
          sx={({ breakpoints }) => ({
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            borderRadius: 0,
            marginBottom: 2,
            marginX: 3,
          })}
        >
          <Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={({ breakpoints }) => ({
                backgroundImage: `url(${item?.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "400px",
                zIndex: 100,
                [breakpoints.down("sm")]: {
                  width: "100%",
                  height: "300px",
                },
              })}
            >
              <Grid
                sx={{
                  border: "solid",
                  borderWidth: 1,
                  width: "95%",
                  height: "95%",
                  borderColor: "#ffffff",
                  zIndex: 100,
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <MKTypography
          sx={({ breakpoints }) => ({
            fontSize: "25px",
            fontFamily: "Italiana",
            lineHeight: "30px",
            fontWeight: 400,
            color: "#ffffff",
            backgroundColor: "transparent !important",
            textAlign: "center",
            marginTop: 2, // Add spacing above the title
            [breakpoints.down("sm")]: {
              fontSize: "20px", // Adjust font size for smaller screens
              lineHeight: "25px", // Adjust line height for smaller screens
            },
          })}
        >
          {item?.title}
        </MKTypography>
      </Grid>
    );
  };

  const OffersCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          flex: 1,
          marginBottom: 5,
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "350px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          ></Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            "&:last-child": { paddingBottom: "8px" },
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "25px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#484848",
                [breakpoints.down("sm")]: {
                  fontSize: "30px",
                },
              })}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "14px",
                fontFamily: "Lexend",
                lineHeight: "15px",
                fontWeight: 500,
                marginTop: 1,
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              Available on
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "12px",
                fontFamily: "Lexend",
                lineHeight: "15px",
                fontWeight: 400,
                [breakpoints.down("sm")]: {
                  fontSize: "12px",
                },
              })}
            >
              {item?.description}
            </MKTypography>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            <MKTypography
              onClick={() => navigate("/pages/contact-us")}
              sx={{
                fontSize: 13,
                fontFamily: "Lexend",
                color: "#017836",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
            >
              Contact us for inquiries
              <UilArrowRight />
            </MKTypography>
            <MKTypography
              sx={{
                fontSize: 12,
                fontFamily: "Lexend",
                color: "#484848",
                alignItems: "center",
                display: "flex",
              }}
            >
              {item?.date}
            </MKTypography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const BlogCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          flex: 1,
          marginBottom: 5,
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "275px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          ></Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid
            container
            sx={{
              gap: 1,
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <MKTypography
              sx={{
                fontSize: 16,
                fontFamily: "Lexend",
                color: "#484848",
                alignItems: "center",
                display: "flex",
              }}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              sx={{
                fontSize: 13,
                fontFamily: "Lexend",
                color: "#484848",
                alignItems: "center",
                display: "flex",
              }}
            >
              Read More
              <UilArrowRight />
            </MKTypography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const accCardData = [
    {
      title: "Deluxe Double Room",
      image1: AccomadationPage.NuwaraEliya_Super_D1,
      size: "25 m²",
      occupancy: "2 Adults",
      bed: "1 King Bed",
      description: "Heaven Seven Nuwaraeliya",
      btnText: ["Half Day", "Scenery", "Biodiversity"],
    },
    {
      title: "Super Deluxe TPL room",
      size: "125 m²",
      occupancy: "3 Adults",
      bed: "2 Beds",
      image1: AccomadationPage.Kandy_delux_double_1,
      description: "Heaven Seven Kandy",
      btnText: ["Full Day", "Scenery", "Biodiversity"],
    },
    {
      title: "Attic room",
      size: "25 m²",
      occupancy: "3 Adults",
      bed: "1 King Bed",
      image1: AccomadationPage.NuwaraEliya_Attic1,
      description: "Heaven Seven Nuwaraeliya",
      btnText: ["Full Day", "Scenery", "Picnic Spot"],
    },
  ];

  const exploreCardData = [
    {
      title: "Kandy Perahara",
      image1: HomePage.Home_Exp1,
    },
    {
      title: "Horton Plains",
      image1: HomePage.Home_Exp2,
    },
    {
      title: "Gregory Park",
      image1: HomePage.Home_Exp3,
    },
  ];

  const offerCardData = [
    {
      title: "Romantic Getaway Package",
      image1: HomePage.Home_Offer1,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
    {
      title: " Family Vacation Bundle",
      image1: HomePage.Home_Offer2,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Honeymoon Special",
      image1: HomePage.Home_Offer3,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Corporate & Business Package",
      image1: HomePage.Home_Offer4,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
  ];
  const blogCardData = [
    {
      title: "Garden of Dreams",
      image1: HomePage.Home_Blog3,
    },
    {
      title: "World Best Scenes",
      image1: HomePage.Home_Blog4,
    },
    {
      title: "City of Kings - Kandy",
      image1: HomePage.Home_Blog5,
    },
  ];

  const blogCardData2 = [
    {
      title: "Sigiriya the Ancient Rock",
      image1: HomePage.Home_Blog1,
    },
    {
      title: "Where Tea is Originated",
      image1: HomePage.Home_Blog2,
    },
  ];

  return (
    <div>
      <div style={{ overflow: "hidden", backgroundColor: "white" }}>
        <NavBar />
        {/* Explore our travel solutions */}
        <NavBar />
        <HeaderOne
          bgImage={HomePage.Home_Header}
          title={"Heaven Seven"}
          description={"A Destination Close to Nature, Crafted with Love"}
        />
        <BookNow />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: 6,
          }}
          mt={8}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Our Hotels
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Enjoy the beauty of Sri Lanka with our hotels in Kandy and
                Nuwara Eliya. Set among green tea estates and hills, they
                combine comfort and peace, giving you stunning views of the
                island's famous scenery.
              </MKTypography>
            </Grid>
          </Container>
          {!isMobile ? (
            <Grid container sx={{ justifyContent: "center" }} lg={11}>
              <Grid lg={4}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundImage: `url(${HomePage.Hotel1})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "450px",
                    position: "relative", // Important for overlay positioning
                    "&:hover .overlay": {
                      opacity: 1, // Show overlay on hover
                    },
                  }}
                >
                  {/* Border Box */}
                  <Grid
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      width: "95%",
                      height: "95%",
                      borderColor: "#ffffff",
                      position: "absolute",
                    }}
                  />

                  {/* Hover Overlay */}
                  <Grid
                    className="overlay"
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay effect
                      color: "#fff",
                      textAlign: "center",
                      opacity: 0, // Initially hidden
                      transition: "opacity 0.5s ease-in-out",
                    }}
                  >
                    <MKTypography
                      variant="h1"
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                        fontFamily: "Italiana",
                        fontSize: "40px",
                        fontWeight: 400,
                        width: "70%",
                      })}
                    >
                      Heaven Seven Kandy
                    </MKTypography>
                    <MKButton
                      variant="outlined"
                      onClick={() => navigate("/pages/accomadation")}
                      sx={{
                        mt: 2,
                        borderColor: "#fff",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#000",
                        },
                      }}
                    >
                      Explore More
                    </MKButton>
                  </Grid>
                </Grid>

                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundImage: `url(${HomePage.Hotel3})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "300px",
                    marginTop: 8,
                  }}
                >
                  <Grid
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      width: "95%",
                      height: "95%",
                      borderColor: "#ffffff",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid lg={3}>
                <Grid
                  container
                  sx={{
                    height: "100vh", // Full viewport height
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "white",
                  }}
                >
                  {/* Left Content */}
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        transform: "rotate(-90deg)",
                        position: "absolute",
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#A7A7A7",
                        top: "150px",
                        left: "1%",
                      }}
                    >
                      KANDY
                    </Typography>
                  </Grid>

                  {/* Center Content */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    {/* Vertical Line Above the Text */}
                    <Box
                      sx={{
                        width: "1px",
                        height: "calc(50% - 4rem)", // Half-height minus text area
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        position: "absolute",
                        top: 0,
                      }}
                    />
                    {/* Center Text */}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        zIndex: 2,
                        fontWeight: "bold",
                        fontSize: "14px",
                        letterSpacing: "0.1rem",
                        textTransform: "uppercase",
                        textAlign: "center",
                        backgroundColor: "white",
                        width: "90%",
                        px: 2, // Padding to avoid line overlap
                      }}
                    >
                      In the Heart of Sri Lanka's Central Highlands
                    </Typography>
                    {/* Vertical Line Below the Text */}
                    <Box
                      sx={{
                        width: "1px",
                        height: "calc(50% - 4rem)",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        position: "absolute",
                        bottom: 0,
                      }}
                    />
                  </Grid>

                  {/* Right Content */}
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        transform: "rotate(-90deg)",
                        position: "absolute",
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#A7A7A7",
                        right: -40,
                        bottom: "150px",
                      }}
                    >
                      NUWARAELIYA
                    </Typography>
                  </Grid>

                  {/* "OUR HOTELS" Vertical Text */}
                  <Typography
                    variant="h1"
                    sx={{
                      position: "absolute",
                      fontWeight: "bold",
                      fontSize: "6rem",
                      color: "#D9D9D933",
                      textTransform: "uppercase",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%) rotate(-90deg)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    OUR HOTELS
                  </Typography>
                </Grid>
              </Grid>
              <Grid lg={4}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundImage: `url(${HomePage.Hotel2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  <Grid
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      width: "95%",
                      height: "95%",
                      borderColor: "#ffffff",
                    }}
                  />
                </Grid>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundImage: `url(${HomePage.Hotel4})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "450px",
                    marginTop: 8,
                    position: "relative",
                    "&:hover .overlay": {
                      opacity: 1, // Show overlay on hover
                    },
                  }}
                >
                  {/* Border Box */}
                  <Grid
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      width: "95%",
                      height: "95%",
                      borderColor: "#ffffff",
                      position: "absolute",
                    }}
                  />
                  {/* Hover Overlay */}
                  <Grid
                    className="overlay"
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      color: "#fff",
                      textAlign: "center",
                      opacity: 1,
                      transition: "opacity 0.5s ease-in-out",
                    }}
                  >
                    <MKTypography
                      variant="h1"
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                        fontFamily: "Italiana",
                        fontSize: "40px",
                        fontWeight: 400,
                        width: "70%",
                      })}
                    >
                      Heaven Seven Nuwaraeliya
                    </MKTypography>
                    <MKButton
                      variant="outlined"
                      onClick={() => navigate("/pages/acc_nuwaraeliya")}
                      sx={{
                        mt: 2,
                        borderColor: "#fff",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#000",
                        },
                      }}
                    >
                      Explore More
                    </MKButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {isMobile ? (
            <Grid sx={{ width: "100%" }}>
              <Grid
                onClick={() => navigate("/pages/accomadation")}
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${HomePage.Hotel1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "450px",
                  position: "relative", // Important for overlay positioning
                  "&:hover .overlay": {
                    opacity: 1, // Show overlay on hover
                  },
                }}
              >
                {/* Border Box */}
                <Grid
                  sx={{
                    border: "solid",
                    borderWidth: 1,
                    width: "95%",
                    height: "95%",
                    borderColor: "#ffffff",
                    position: "absolute",
                  }}
                />

                {/* Hover Overlay */}
                <Grid
                  className="overlay"
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay effect
                    color: "#fff",
                    textAlign: "center",
                    opacity: 1,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  <MKTypography
                    variant="h1"
                    color="white"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "40px",
                      fontWeight: 400,
                      width: "70%",
                    })}
                  >
                    Heaven Seven Kandy
                  </MKTypography>
                  <MKButton
                    variant="outlined"
                    onClick={() => navigate("/pages/accomadation")}
                    sx={{
                      mt: 2,
                      borderColor: "#fff",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                      },
                    }}
                  >
                    Explore More
                  </MKButton>
                </Grid>
              </Grid>
              <Grid
                container
                display="flex"
                onClick={() => navigate("/pages/acc_nuwaraeliya")}
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${HomePage.Hotel4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "450px",
                  marginTop: 8,
                  position: "relative",
                  "&:hover .overlay": {
                    opacity: 1, // Show overlay on hover
                  },
                }}
              >
                {/* Border Box */}
                <Grid
                  sx={{
                    border: "solid",
                    borderWidth: 1,
                    width: "95%",
                    height: "95%",
                    borderColor: "#ffffff",
                    position: "absolute",
                  }}
                />
                {/* Hover Overlay */}
                <Grid
                  className="overlay"
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "#fff",
                    textAlign: "center",
                    opacity: 1,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  <MKTypography
                    variant="h1"
                    color="white"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "40px",
                      fontWeight: 400,
                      width: "70%",
                    })}
                  >
                    Heaven Seven Nuwaraeliya
                  </MKTypography>
                  <MKButton
                    variant="outlined"
                    onClick={() => navigate("/pages/acc_nuwaraeliya")}
                    sx={{
                      mt: 2,
                      borderColor: "#fff",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                      },
                    }}
                  >
                    Explore More
                  </MKButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: 7,
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Accommodations
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Indulge in Relaxation with Our Stylish and Comfortable Rooms
                Where Luxury Meets the Warmth of Home.
              </MKTypography>
            </Grid>
          </Container>
          <Grid container spacing={3} lg={10}>
            {accCardData?.map((item, index) => (
              <Grid item xs={12} sm={6} lg={4} key={index}>
                <AccomadationCard item={item} index={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: 3,
          }}
        >
          <MKButton
            variant="outlined"
            color="black"
            onClick={() => {
              navigate("/pages/acc_nuwaraeliya");
            }}
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              zIndex: 10,
            }}
          >
            Nuwara Eliya Hotel
          </MKButton>
          <MKButton
            variant="outlined"
            color="black"
            onClick={() => {
              navigate("/pages/accomadation");
            }}
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              zIndex: 10,
            }}
          >
            Kandy Hotel
          </MKButton>
        </Grid>
        {!isMobile ? (
          <img
            src={HomePage?.Map}
            alt="My SVG"
            style={{ width: "90%", height: "100%" }}
          />
        ) : null}
        {/*Explore*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            backgroundColor: "#002814",
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${AccomadationPage.Mountains})`,
              backgroundSize: "80%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines-light">
                <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Explore the Surroundings
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="white"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Explore the beauty and stunning views around our hotel. Every
                moment is a chance to find something special and make amazing
                memories.
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            spacing={2}
            lg={10}
            sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}
          >
            {ExperienceContent?.slice(0, 3).map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                key={index}
                onClick={() => {
                  navigate("/pages/experience", { state: { item } });
                }}
              >
                <ExploreCard item={item} index={index} />
              </Grid>
            ))}
            <MKButton
              variant="outlined"
              color="white"
              onClick={() => {
                navigate("/pages/experience");
              }}
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              Explore More Experiences
            </MKButton>
            <MKButton
              variant="outlined"
              color="white"
              onClick={() => {
                navigate("/pages/contactUsTrail");
              }}
              sx={{
                marginLeft: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: { xs: 2, lg: 0 },
              }}
            >
              Contact us for inquiries
            </MKButton>
          </Grid>
        </Grid>
        {/*Reviews*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Guests Reviews
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Hear directly from our guests about their memorable experiences.
                Their words and praise highlight our dedication to delivering
                outstanding hospitality
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            spacing={2}
            lg={10}
            sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}
          >
            <ElfsightWidget />

            {/* <MKButton
              variant="outlined"
              color="black"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 5,
              }}
            >
              Explore More Experiences
            </MKButton> */}
          </Grid>
        </Grid>

        {/*Offers */}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            position: "relative",
            paddingBottom: 20,
            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${HomePage.OfferBg})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              opacity: 1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                You Might Also Like
              </MKTypography>
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Heaven Seven Offers
                </MKTypography>
              </div>
            </Grid>
          </Container>
          <Grid container spacing={2} lg={11.5} sx={{}}>
            {offerCardData?.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <OffersCard item={item} index={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/*Blogs */}
        {/* <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            position: "relative",
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Explore Our Blog
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Discover captivating stories, travel tips, and insider insights
                crafted to inspire your adventures and enhance your journey with
                us.
              </MKTypography>
            </Grid>
          </Container>
          <Grid container spacing={2} lg={11.5} sx={{}}>
            {isMobile ? (
              <Grid
                sx={{
                  width: "100%",
                  paddingLeft: "16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BlogCard item={blogCardData2[0]} />
                <BlogCard item={blogCardData2[1]} />
              </Grid>
            ) : null}
            {!isMobile ? (
              <Grid
                sx={{
                  width: "100%",
                  paddingLeft: "16px",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <BlogCard item={blogCardData2[0]} />
                <Grid
                  sx={{
                    width: "26%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MKTypography
                      variant="h1"
                      color="black"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                        fontFamily: "Italiana",
                        fontSize: "80px",
                        fontWeight: 400,
                        flexDirection: "row",
                        lineHeight: 1,
                      })}
                    >
                      BL
                    </MKTypography>
                    <Box
                      sx={{
                        width: "4rem",
                        height: "4px",
                        backgroundColor: "#000000",
                      }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "4rem",
                        height: "4px",
                        backgroundColor: "#000000",
                      }}
                    />
                    <MKTypography
                      variant="h1"
                      color="black"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                        fontFamily: "Italiana",
                        fontSize: "80px",
                        fontWeight: 400,
                        flexDirection: "row",
                        lineHeight: 1,
                      })}
                    >
                      OG
                    </MKTypography>
                  </Grid>
                  <MKTypography
                    variant="h1"
                    color="black"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "80px",
                      fontWeight: 400,
                      flexDirection: "row",
                      lineHeight: 1,
                    })}
                  >
                    NEW
                  </MKTypography>
                  <Grid
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MKTypography
                      variant="h1"
                      color="black"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                        fontFamily: "Italiana",
                        fontSize: "80px",
                        fontWeight: 400,
                        flexDirection: "row",
                        lineHeight: 1,
                      })}
                    >
                      S
                    </MKTypography>
                    <Box
                      sx={{
                        width: "4rem",
                        height: "4px",
                        backgroundColor: "#000000",
                      }}
                    />
                  </Grid>
                </Grid>

                <BlogCard item={blogCardData2[1]} />
              </Grid>
            ) : null}
            {blogCardData?.map((item, index) => (
              <Grid item xs={12} sm={6} lg={4} key={index}>
                <BlogCard item={item} index={index} />
              </Grid>
            ))}
          </Grid>
        </Grid> */}
        {/*FAQ*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            position: "relative",
            paddingBottom: 5,
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  FAQ
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Find quick answers to common questions in our FAQ section. We've
                compiled essential information to make your experience with us
                smooth and hassle free.
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            alignItems="center"
            sx={{ width: "70%" }}
          >
            <Divider
              variant="middle"
              sx={{
                height: 2,
                width: "100%",
                backgroundColor: "#C9C5BA",
              }}
            />
            <Grid container display={"flex"} flexDirection="column">
              {faq.map((item, index) => (
                <Accordion key={index} sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="black" />}
                    aria-controls="panel1-content"
                    id={`panel1-header-${index}`}
                    sx={{ boxShadow: "none" }}
                  >
                    {item?.title}
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#F4F4F4" }}>
                    <MKTypography sx={{ fontSize: 13, fontFamily: "Lexend" }}>
                      {item?.answer}
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
