import React, { useState, useEffect } from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderTwo";
import MKBox from "components/MKBox";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Footer from "components/Footer";
import { ContactUsPage, HomePage } from "constants/images";
import NavBar from "components/NavBar"; // Import NavBar
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

function ContactUsTrail() {
  const [isMobile, setIsMobile] = useState(false);
  const [selectHotel, setSelectHotel] = useState("Nuwara Eliya Hotel");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    reasonForContact: "",
    message: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDetails = {
      hotel: selectHotel,
      ...formData,
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: formData.name,
          to_name: "Heaven Seven Hotels",
          from_email: formData.email,
          to_email: "info@heavensevenhotels.com",
          message: `Name : ${formData.name} \n Email : ${formData.email} \n Phone Number : ${formData.phoneNumber} \n Hotel : ${formData.hotel} \n Reason for Contact : ${formData.reasonForContact} \n Message : ${formData.message}`, 
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          navigate("/pages/thank-you");
        },
        (error) => {
          console.error(error);
          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  useEffect(() => {
    // Function to check the window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize(); // Check the initial window size
    window.addEventListener("resize", handleResize); // Add resize event listener

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ContactForm = () => {
    return (
      <MKBox
        component="form"
        onSubmit={handleSubmit}
        sx={{
          backgroundColor: "#fff",
          padding: "2rem",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" sx={{ width: "98%" }} fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Select a hotel
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectHotel}
              label="Age"
              onChange={(e) => {
                setSelectHotel(e.target.value);
              }}
            >
              <MenuItem value={"Nuwara Eliya Hotel"}>
                Nuwara Eliya Hotel
              </MenuItem>
              <MenuItem value={"Kandy Hotel"}>Kandy Hotel</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="First Name"
              variant="standard"
              fullWidth
              required
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              sx={{ color: "#000000" }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Last Name"
              variant="standard"
              fullWidth
              required
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              type="email"
              variant="standard"
              fullWidth
              required
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Phone Number"
              type="tel"
              variant="standard"
              fullWidth
              required
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Reason for Contact"
              variant="standard"
              fullWidth
              required
              name="reasonForContact"
              value={formData.reasonForContact}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Message"
              multiline
              rows={4}
              variant="standard"
              fullWidth
              required
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <MKBox display="flex" justifyContent="center" mt={3}>
          <MKButton
            type="submit"
            variant="contained"
            size="large"
            sx={{
              borderRadius: "20px",
              padding: "0.8rem 4rem",
              backgroundColor: "#017836",
              color: "#fff",
            }}
          >
            Submit
          </MKButton>
        </MKBox>
      </MKBox>
    );
  };

  return (
    <div style={{ backgroundColor: "#FEFDF5" }}>
      <NavBar />
      <div style={{ overflow: "hidden" }}>
        <HeaderTwo
          title="Contact Us"
          backgroundImage={ContactUsPage.Header}
          subHead="asasas"
        />

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: 7,
            position: "relative",
            paddingBottom: 20,

            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "50%",
              backgroundImage: `url(${ContactUsPage.ContactUsConnect})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              opacity: 1,
              zIndex: 0,
              bottom: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Connect with Us
                </MKTypography>
              </div>
            </Grid>
          </Container>
          <Grid
            container
            spacing={2}
            lg={11.5}
            sx={{ zIndex: 100, justifyContent: "center" }}
          >
            <MKBox
              component="form"
              onSubmit={handleSubmit}
              sx={{
                backgroundColor: "#fff",
                padding: "2rem",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" sx={{ width: "98%" }} fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select a hotel
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectHotel}
                    label="Age"
                    onChange={(e) => {
                      setSelectHotel(e.target.value);
                    }}
                  >
                    <MenuItem value={"Nuwara Eliya Hotel"}>
                      Nuwara Eliya Hotel
                    </MenuItem>
                    <MenuItem value={"Kandy Hotel"}>Kandy Hotel</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    variant="standard"
                    fullWidth
                    required
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    sx={{ color: "#000000" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    variant="standard"
                    fullWidth
                    required
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    type="email"
                    variant="standard"
                    fullWidth
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Phone Number"
                    type="tel"
                    variant="standard"
                    fullWidth
                    required
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Reason for Contact"
                    variant="standard"
                    fullWidth
                    required
                    name="reasonForContact"
                    value={formData.reasonForContact}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    multiline
                    rows={4}
                    variant="standard"
                    fullWidth
                    required
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <MKBox display="flex" justifyContent="center" mt={3}>
                <MKButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: "20px",
                    padding: "0.8rem 4rem",
                    backgroundColor: "#017836",
                    color: "#fff",
                  }}
                >
                  Submit
                </MKButton>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default ContactUsTrail;
