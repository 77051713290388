import React, { useState, useEffect } from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import View from "layouts/sections/components/View";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { ReactComponent as NoSmoke } from "assets/icons/tabler_smoking-no.svg";
import { ReactComponent as Feature } from "assets/icons/feather.svg";
import MKBox from "components/MKBox";
import NavBar from "components/NavBar";
import { AccomadationIcons } from "constants/Icons";
import Slider from "react-slick";
import CustomPagination from "components/CustomPagination";
import { ExperienceContent } from "constants/experianceContent";
import DefaultReviewCard from "components/DefaultReviewCard";
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";
import {
  UilBedDouble,
  UilParkingSquare,
  UilUtensils,
  UilWifi,
  UilSnowFlake,
  UilClock,
  UilCloud,
} from "@iconscout/react-unicons";
import { Card, CardContent, Box, Divider } from "@mui/material";
import Footer from "components/Footer";
import { AccomadationPage, HomePage } from "constants/images";
import { UilArrowRight } from "@iconscout/react-unicons";
import "./styles.css";
import { useNavigate } from "react-router-dom";

function Accomadation_NuwaraEliya() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();

  const roomData = [
    {
      title: "Deluxe Double Room without Balcony",
      des: "This room for 2 comes with a flat-screen TV and an attached bathroom with shower facilities and a bidet. Guests can make use of the common living area with a sofa.",
      roomSize: "22 m²",
      views: [],
      facilities: [
        "Desk",
        "TV",
        "Linen",
        "Safety deposit box",
        "Tile/marble floor",
        "Electric kettle",
        "Telephone",
        "Cable channels",
        "Towels",
        "Wardrobe or closet",
        "Satellite channels",
        "Tea/Coffee maker",
        "Extra-long beds (> 2 meters)",
      ],
      images: [
        AccomadationPage.NuwaraEliya_Delux_Double_1,
        AccomadationPage.NuwaraEliya_Delux_Double_2,
        AccomadationPage.NuwaraEliya_Delux_Double_3,
      ],
    },
    {
      title: "Family Room",
      des: "This spacious suite comes with a separate seating area, heating facilities and a private bathroom with shower facilities and a bidet.",
      roomSize: "35 m²",
      views: ["Balcony", "Garden view", "Mountain view"],
      facilities: [
        "Desk",
        "Safety deposit box",
        "Sofa",
        "Towels",
        "Seating Area",
        "Tea/Coffee maker",
        "TV",
        "Linen",
        "Tile/marble floor",
        "Electric kettle",
        "Telephone",
        "Cable channels",
        "Wardrobe or closet",
        "Satellite channels",
        "Dining area",
        "Extra-long beds (> 2 metres)",
      ],
      images: [
        AccomadationPage.NuwaraEliya_Delux_Double_Family_1,
        AccomadationPage.NuwaraEliya_Delux_Double_Family_2,
        AccomadationPage.NuwaraEliya_Delux_Double_Family_3,
        AccomadationPage.NuwaraEliya_Delux_Double_Family_4,
      ],
    },
    {
      title: "Deluxe Double Room with Balcony",
      des: "Featuring a private balcony, this double room has air conditioning, seating area and garden view.",
      roomSize: "25 m²",
      views: [
        "Balcony",
        "Lake view",
        "Garden view",
        "Mountain view",
        "Landmark view",
        "City view",
      ],
      facilities: [
        "Desk",
        "Safety deposit box",
        "Towels",
        "Tea/Coffee maker",
        "TV",
        "Linen",
        "Tile/marble floor",
        "Electric kettle",
        "Telephone",
        "Cable channels",
        "Wardrobe or closet",
        "Satellite channels",
        "Extra-long beds (> 2 metres)",
      ],
      images: [AccomadationPage.NuwaraEliya_Super_D1],
    },
    {
      title: "Attic Room",
      des: "Offering free toiletries, this double room includes a private bathroom with a shower, a bidet and a hairdryer.",
      roomSize: "22 m²",
      views: [],
      facilities: [
        "Desk",
        "TV",
        "Linen",
        "Safety deposit box",
        "Tile/marble floor",
        "Electric kettle",
        "Telephone",
        "Cable channels",
        "Towels",
        "Wardrobe or closet",
        "Satellite channels",
        "Tea/Coffee maker",
        "Hardwood or parquet floors",
        "Extra-long beds (> 2 metres)",
      ],
      images: [
        AccomadationPage.NuwaraEliya_Attic1,
        AccomadationPage.NuwaraEliya_Attic2,
        AccomadationPage.NuwaraEliya_Attic3,
      ],
    },
    {
      title: "Superior Quadruple Room",
      des: "This quadruple room features a minibar, flat-screen TV with cable channels, lake views, as well as chocolate for guests.",
      roomSize: "35 m²",
      views: [
        "Balcony",
        "Lake view",
        "Garden view",
        "Mountain view",
        "Landmark view",
        "City view",
      ],
      facilities: [
        "Desk",
        "Safety deposit box",
        "Flat-screen TV",
        "Wake-up service",
        "Iron",
        "Fan",
        "Towels",
        "Tea/Coffee maker",
        "TV",
        "Refrigerator",
        "Linen",
        "Minibar",
        "Tile/marble floor",
        "Electric kettle",
        "Heating",
        "Telephone",
        "Cable channels",
        "Wardrobe or closet",
        "Satellite channels",
        "Extra long beds (> 2 metres)",
      ],
      images: [
        AccomadationPage.NuwaraEliya_Super_Q1,
        AccomadationPage.NuwaraEliya_Super_Q2,
        AccomadationPage.NuwaraEliya_Super_Q3,
      ],
    },
    {
      title: "Superior Triple Room",
      des: "This triple room features a minibar, flat-screen TV with cable channels, lake views, as well as chocolate for guests.",
      roomSize: "25 m²",
      views: [
        "Balcony",
        "Lake view",
        "Garden view",
        "Mountain view",
        "Landmark view",
        "City view",
      ],
      facilities: [
        "Desk",
        "Safety deposit box",
        "Flat-screen TV",

        "Wake-up service",
        "Iron",
        "Fan",
        "Towels",
        "Tea/Coffee maker",
        "TV",
        "Refrigerator",
        "Linen",
        "Minibar",
        "Tile/marble floor",
        "Electric kettle",
        "Heating",
        "Telephone",
        "Cable channels",
        "Wardrobe or closet",
        "Satellite channels",
        "Extra-long beds (> 2 metres)",
      ],
      images: [
        AccomadationPage.NuwaraEliya_Super_T1,
        AccomadationPage.NuwaraEliya_Super_T2,
        AccomadationPage.NuwaraEliya_Super_T3,
        AccomadationPage.NuwaraEliya_Super_T4,
        AccomadationPage.NuwaraEliya_Super_T5,
      ],
    },
    {
      title: "Superior Double Room",
      des: "The hot tub is the standout feature of this double room. This double room has a minibar, flat-screen TV with cable channels, lake views, as well as chocolate for guests.",
      roomSize: "25 m²",
      views: [
        "Balcony",
        "Lake view",
        "Garden view",
        "Mountain view",
        "Landmark view",
        "City view",
      ],
      facilities: [
        "Desk",
        "Safety deposit box",
        "Flat-screen TV",

        "Wake-up service",
        "Iron",
        "Fan",
        "Towels",
        "Tea/Coffee maker",
        "TV",
        "Refrigerator",
        "Linen",
        "Minibar",
        "Tile/marble floor",
        "Electric kettle",
        "Heating",
        "Telephone",
        "Cable channels",
        "Wardrobe or closet",
        "Satellite channels",
        "Hot tub",
        "Extra-long beds (> 2 metres)",
      ],
      images: [AccomadationPage.NuwaraEliya_Super_D1],
    },
  ];

  const expCardData = [
    {
      title: "Lover's Leap Waterfall",
      image1: AccomadationPage.Exp1,
      description: "Approximately 5.5K.M From the Hotel",
      btnText: ["Half Day", "Scenery", "Biodiversity"],
    },
    {
      title: "Horton Plains",
      image1: AccomadationPage.Exp2,
      description: "Approximately 7.5K.M From the Hotel",
      btnText: ["Full Day", "Scenery", "Biodiversity"],
    },
    {
      title: "Victoria Park",
      image1: AccomadationPage.Exp3,
      description: "Approximately 3.2K.M From the Hotel",
      btnText: ["Full Day", "Scenery", "Picnic Spot"],
    },
    {
      title: "Gregory Park",
      image1: AccomadationPage.Exp4,
      description: "Approximately 1.9K.M From the Hotel",
      btnText: ["Full Day", "Nature", "Photography"],
    },
  ];

  const offerCardData = [
    {
      title: "Seasonal / Limited-Time Discounts",
      image1: HomePage.Home_Offer1,
      description:
        "“Winter Wonderland” Deal for Nuwara Eliya: Offer discounted rates during the cooler months when visitors flock to Nuwara Eliya for the crisp climate.",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Exclusive Packages",
      image1: AccomadationPage.Nuwara_Eliya_Offer_2,
      description:
        "Romantic Getaway Package: For couples, this can include candlelit dinners in Kandy or Nuwara Eliya. ",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Early Bird & Last-Minute Offers",
      image1: HomePage.Home_Offer3,
      description:
        "Early Bird Discounts: Discounted rates for guests who book several months in advance for holidays, allowing them to enjoy savings on luxury accommodations.",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Corporate & Business Packages- NE",
      image1: HomePage.Home_Offer4,
      description:
        "Corporate Retreats: Offer deals for business groups looking for a quiet, luxurious retreat for meetings, team-building activities, and workshops.",
      date: "Valid Till 31 Dec",
    },
  ];

  const OffersCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          flex: 1,
          minHeight: "540px",
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "350px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          ></Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            "&:last-child": { paddingBottom: "8px" },
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "25px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#484848",
                [breakpoints.down("sm")]: {
                  fontSize: "30px",
                },
              })}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "14px",
                fontFamily: "Lexend",
                lineHeight: "15px",
                fontWeight: 500,
                marginTop: 1,
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              Available on
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "12px",
                fontFamily: "Lexend",
                lineHeight: "15px",
                fontWeight: 400,
                [breakpoints.down("sm")]: {
                  fontSize: "12px",
                },
              })}
            >
              {item?.description}
            </MKTypography>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            <MKTypography
              onClick={() => navigate("/pages/contact-us")}
              sx={{
                fontSize: 13,
                fontFamily: "Lexend",
                color: "#017836",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
            >
              Contact us for inquiries
              <UilArrowRight />
            </MKTypography>
            <MKTypography
              sx={{
                fontSize: 12,
                fontFamily: "Lexend",
                color: "#484848",
                alignItems: "center",
                display: "flex",
              }}
            >
              {item?.date}
            </MKTypography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const NextArrow = ({ onClick }) => (
    <div
      style={{ zIndex: 100 }}
      className="custom-arrow next-arrow"
      onClick={onClick}
    >
      &gt; {/* Use &gt; for > */}
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      style={{ zIndex: 100 }}
      className="custom-arrow prev-arrow"
      onClick={onClick}
    >
      &lt; {/* Use &lt; for < */}
    </div>
  );

  const backgroundImages = [
    AccomadationPage?.Acc_Pub4,
    AccomadationPage?.Acc_Pub4,
    AccomadationPage?.Acc_Pub4,
  ];

  const CustomCard = ({ item, index }) => {
    const isEven = index % 2 === 0;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % item?.images.length);
    };

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? item?.images.length - 1 : prevIndex - 1
      );
    };

    return (
      <Grid>
        <Grid
          sx={{
            padding: 2,
            backgroundColor: "white",
            borderRadius: 5,
            width: "100%",
          }}
        >
          <Grid
            sx={{
              padding: 1,
              borderRadius: "15px",
            }}
          >
            <Card
              sx={({ breakpoints }) => ({
                display: "flex",
                flexDirection: "row",
                borderRadius: "15px",
                boxShadow: "none",
                flex: 1,
                [breakpoints.down("sm")]: {
                  flexDirection: "column",
                  alignItems: "center",
                },
              })}
            >
              <Grid key={index}>
                <Box
                  sx={({ breakpoints }) => ({
                    position: "absolute",
                    width: "550px",
                    height: "500px",
                    backgroundColor: "#f0f0f0",
                    zIndex: 0,
                    [breakpoints.down("sm")]: {
                      position: "absolute",
                      width: "90vw",
                      height: "300px",
                      top: "10px",
                      left: "0",
                      marginLeft: "-16px",
                      marginRight: "-16px",
                    },
                  })}
                />

                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={({ breakpoints }) => ({
                    backgroundImage: `url(${item?.images[currentIndex]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "550px",
                    height: "500px",
                    zIndex: 100,
                    position: "relative",
                    top: "20px",
                    left: "20px",
                    transition: "opacity 0.3s ease-in-out",
                    [breakpoints.down("sm")]: {
                      width: "90vw",
                      height: "300px",
                      top: "10px",
                      left: "0",
                      marginLeft: "-16px",
                      marginRight: "-16px",
                    },
                  })}
                >
                  <Grid
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      width: "95%",
                      height: "95%",
                      borderColor: "#ffffff",
                      zIndex: 100,
                    }}
                  />
                  <PrevArrow onClick={handlePrev} />
                  <NextArrow onClick={handleNext} />
                </Grid>
              </Grid>

              <CardContent
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  position: "relative",
                  marginLeft: 10,
                  paddingTop: 0,
                  top: "20px",
                  [breakpoints.down("sm")]: {
                    marginLeft: 0,
                    top: "10px",
                    alignItems: "center",
                    textAlign: "center",
                  },
                })}
              >
                <Grid lg={12}>
                  <MKTypography
                    color="black"
                    sx={({ breakpoints }) => ({
                      fontSize: "35px",
                      fontFamily: "Italiana",
                      lineHeight: "40px",
                      fontWeight: 400,
                      width: "100%",
                      [breakpoints.down("sm")]: {
                        fontSize: "30px",
                      },
                    })}
                  >
                    {item?.title}
                  </MKTypography>
                  <MKTypography
                    color="black"
                    sx={({ breakpoints }) => ({
                      fontSize: "18px",
                      fontFamily: "Lexend",
                      lineHeight: "20px",
                      fontWeight: 400,
                      [breakpoints.down("sm")]: {
                        fontSize: "14px",
                      },
                    })}
                  >
                    {item?.des}
                  </MKTypography>
                  <Divider
                    variant="middle"
                    sx={({ breakpoints }) => ({
                      height: 2,
                      width: "100%",
                      backgroundColor: "#A7A7A7",
                      margin: 1,
                      [breakpoints.down("sm")]: {
                        margin: "0.5rem 0",
                      },
                    })}
                  />
                </Grid>

                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  lg={12}
                  gap={4}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: {
                      flexDirection: "column",
                      gap: 2,
                    },
                  })}
                >
                  {/* {item?.facilities.map((facility) => {
                    return (
                      
                    );
                  })} */}
                  <Grid
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                      src={AccomadationIcons.HomeIcon}
                      alt="Logo"
                    />
                    <MKTypography
                      color="black"
                      sx={{
                        fontSize: "13px",
                        fontFamily: "Lexend",
                        fontWeight: 300,
                        lineHeight: "20px",
                      }}
                    >
                      Room Size
                    </MKTypography>
                    <MKTypography
                      color="black"
                      sx={{
                        fontSize: "13px",
                        fontFamily: "Lexend",
                        fontWeight: 300,
                        lineHeight: "20px",
                      }}
                    >
                      {item?.roomSize}
                    </MKTypography>
                  </Grid>
                </Grid>
                {item?.views && item?.views?.length > 0 ? (
                  <MKTypography
                    sx={({ breakpoints }) => ({
                      fontSize: "20px",
                      fontFamily: "Lexend",
                      lineHeight: "30px",
                      fontWeight: 500,
                      color: "#017836",
                      marginTop: 2,
                      [breakpoints.down("sm")]: {
                        fontSize: "16px",
                      },
                    })}
                  >
                    Views
                  </MKTypography>
                ) : null}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: {
                      justifyContent: "center",
                    },
                  })}
                >
                  {item?.views && item?.views.length > 0
                    ? item?.views.map((view, index) => (
                        <React.Fragment key={view}>
                          {/* Text */}
                          <Grid item>
                            <MKTypography
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Lexend",
                                fontWeight: 300,
                              }}
                            >
                              {view}
                            </MKTypography>
                          </Grid>

                          {/* Divider (not after the last item) */}
                          {index < item?.views?.length - 1 && (
                            <Grid item>
                              <MKTypography sx={{ margin: "0 10px" }}>
                                |
                              </MKTypography>
                            </Grid>
                          )}
                        </React.Fragment>
                      ))
                    : null}
                </Grid>
                <Box sx={{ marginTop: 2 }}>
                  {/* Title */}
                  <MKTypography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#017836",
                    }}
                  >
                    Room Facilities
                  </MKTypography>

                  {/* Facilities Grid */}
                  <Grid container>
                    {item?.facilities && item.facilities?.length > 0
                      ? item?.facilities?.map((facility, index) => (
                          <Grid xs={12} sm={6} md={4} lg={4} key={index}>
                            <Box display="flex" alignItems="center" gap={1}>
                              {/* Check Icon */}
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: 0,
                                }}
                                src={AccomadationIcons.CheckmarkIcon}
                                alt="Logo"
                              />
                              {/* Facility Name */}
                              <MKTypography
                                sx={{
                                  fontSize: "13px",
                                  fontFamily: "Lexend",
                                  fontWeight: 300,
                                }}
                              >
                                {facility}
                              </MKTypography>
                            </Box>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </Box>
                <Grid
                  container
                  display="flex"
                  lg={12}
                  justifyContent={({ breakpoints }) => ({
                    [breakpoints.down("sm")]: "center",
                  })}
                >
                  <MKButton
                    variant="contained"
                    color="black"
                    sx={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      backgroundColor: "#017836",
                      marginTop: 2,
                      height: "50px",
                    }}
                  >
                    Book Now
                  </MKButton>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ElfsightWidget = () => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://static.elfsight.com/platform/platform.js";
      script.async = true;

      // Delay appending the script to avoid ResizeObserver errors
      try {
        setTimeout(() => {
          document.body.appendChild(script);
        }, 1000);
      } catch (error) {
        console.warn("Script appending failed:", error);
      }

      return () => {
        try {
          setTimeout(() => {
            document
              .querySelectorAll(
                'script[src="https://static.elfsight.com/platform/platform.js"]'
              )
              .forEach((el) => el.remove());
          }, 1000);
        } catch (error) {
          console.warn("Script removal failed:", error);
        }
      };
    }, []);

    return (
      <div
        className="elfsight-app-2ac7c9b6-a4a8-4f4c-ad2c-73f0faa65178"
        data-elfsight-app-lazy
      ></div>
    );
  };

  const ExpCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          minHeight: "600px",
          flex: 1,
          marginBottom: 5,
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "350px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          >
            <Grid
              sx={{
                border: "solid",
                borderWidth: 1,
                width: "95%",
                height: "95%",
                borderColor: "#ffffff",
                zIndex: 100,
              }}
            />
          </Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "24px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#017836",
                [breakpoints.down("sm")]: {
                  fontSize: "25px",
                },
              })}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "14px",
                fontFamily: "Lexend",
                lineHeight: "20px",
                fontWeight: 400,
                marginTop: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3, // Limits text to 5 lines
                overflow: "hidden",
                textOverflow: "ellipsis",
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              {item?.description[0]}
            </MKTypography>
          </Grid>

          <Grid
            container
            sx={{
              gap: 1,
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: 10,
                borderColor: "#A7A7A7",
                padding: 1,
                alignItems: "center",
                gap: 1,
                borderStyle: "solid",
                borderWidth: 0.75,
              }}
            >
              <UilClock color="#484848" width="15px" height="15px" />

              <MKTypography
                sx={{ fontWeight: 400, color: "#484848", fontSize: 13 }}
              >
                {item?.duration}
              </MKTypography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: 10,
                borderColor: "#A7A7A7",
                padding: 1,
                alignItems: "center",
                gap: 1,
                borderStyle: "solid",
                borderWidth: 0.75,
              }}
            >
              <UilCloud width="15px" height="15px" />
              <MKTypography
                sx={{ fontWeight: 400, color: "#484848", fontSize: 13 }}
              >
                {item?.category.split(",").slice(0, 2).join(",").trim()}
              </MKTypography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: 10,
                borderColor: "#A7A7A7",
                padding: 1,
                alignItems: "center",
                gap: 1,
                borderStyle: "solid",
                borderWidth: 0.75,
              }}
            >
              <Feature
                sx={{ fill: "red", fontWeight: 400 }}
                width="14px"
                height="14px"
                minHeight="14px"
                minWidth="14px"
              />
              <MKTypography
                sx={{ fontWeight: 400, color: "#484848", fontSize: 13 }}
              >
                {item?.attraction.split(",").slice(0, 2).join(",").trim()}
              </MKTypography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    const scrollToSection = () => {
      if (window.location.hash) {
        const id = window.location.hash.substring(1); // Remove the #
        const element = document.getElementById(id);

        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 500); // Ensure page is fully loaded before scrolling
        }
      }
    };

    // Run only when hash changes
    scrollToSection();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    customPaging: () => <MKBox />,
    afterChange: (current) => setCurrentSlide(current),
    arrows: false,
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* The NavBar component should be placed at the top level of your main layout */}
      <NavBar /> {/* Include NavBar at the top */}
      <div style={{ overflow: "hidden" }}>
        {/*Accommadation*/}
        <HeaderOne
          bgImage={AccomadationPage.Header}
          title="Heaven Seven Nuwara Eliya"
          description="Nestled in the heart of Nuwara Eliya, our hotel provides the perfect gateway to explore breathtaking landscapes, charming attractions, and serene trails. Unwind after your adventures with refreshing drinks, cozy lounges, and a relaxing atmosphere"
        />
        <Grid
          container
          id="accommodation-section"
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Accommodations
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Indulge in the ultimate comfort with our thoughtfully designed
                rooms. Your serene retreat awaits, blending luxury and the
                warmth of home.
              </MKTypography>
            </Grid>
          </Container>
          <Box
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Grid flexDirection={"column"} container lg={11} sx={{}}>
              {roomData?.map((item, index) => {
                return <CustomCard item={item} index={index} />;
              })}
            </Grid>
          </Box>
        </Grid>
        {/*EXP*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            backgroundColor: "#002814",
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${AccomadationPage.Mountains})`,
              backgroundSize: "80%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines-light">
                <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Nearby Experiences
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="white"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Discover the essence of Nuwara Eliya with captivating
                landscapes, cultural treasures, and unforgettable adventures
                waiting just steps from our hotel.
              </MKTypography>
            </Grid>
          </Container>
          <Grid container spacing={2} lg={11.5}>
            {ExperienceContent?.slice(4, 8).map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index}
                onClick={() => {
                  navigate("/pages/experience", { state: { item } });
                }}
              >
                <ExpCard item={item} index={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/*Hotel Galery*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${AccomadationPage.HotelObj})`,
              backgroundSize: "80%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top right",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
          mt={8}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                textAlign: "center",
                marginBottom: "20px",
                marginTop: 15,
              }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Hotel Gallery
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Take a visual journey through our hotel's stunning gallery,
                showcasing the perfect blend of elegance, comfort, and the
                captivating charm of our surroundings.
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Nuwara_Eliya_Gall_1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "300px",
                }}
              ></Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Nuwara_Eliya_Gall_2})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "300px",
                  marginTop: "15px",
                }}
              ></Grid>
            </Grid>
            <Grid sx={{ height: "500px" }}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Nuwara_Eliya_Gall_3})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "615px",
                  marginX: "15px",
                }}
              ></Grid>
            </Grid>
            <Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Nuwara_Eliya_Gall_4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "300px",
                }}
              ></Grid>
              {/* <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Nuwara_Eliya_Gall_5})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "300px",
                  marginTop: "15px",
                }}
              ></Grid> */}
            </Grid>
          </Grid>
          <MKButton
            variant="outlined"
            color="black"
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              marginTop: 3,
            }}
            onClick={() => navigate("/pages/gallery")}
          >
            View More
          </MKButton>
        </Grid>

        {/*Resturant*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundImage: `url(${AccomadationPage.Resturant})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            "@media (max-width:768px)": {
              display: "none",
            },
          }}
          mt={8}
        >
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
              marginTop: 6,
              marginBottom: 6,
            }}
          >
            <Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Acc_Pub1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "615px",
                  borderRadius: 4,
                }}
              ></Grid>
            </Grid>
            <Grid
              sx={{
                width: "30%",
                marginX: "15px",
              }}
            >
              <Grid sx={{ flexDirection: "column", display: "flex" }}>
                <MKTypography
                  variant="h6"
                  fontWeight="regular"
                  color="white"
                  sx={{
                    textAlign: "center",
                    maxWidth: "90%",
                    fontFamily: "Italiana",
                    fontSize: "40px",
                    marginTop: 12,
                  }}
                >
                  Restaurant & Pub
                </MKTypography>
                <MKTypography
                  variant="h6"
                  fontWeight="regular"
                  color="white"
                  sx={{
                    textAlign: "center",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    lineHeight: "15px",
                  }}
                >
                  Savor exquisite flavors and handcrafted drinks in a warm,
                  inviting atmosphere. Whether it's a casual meal or a night of
                  lively conversation, our restaurant and pub promise memorable
                  moments.
                </MKTypography>
              </Grid>
              <Grid sx={{ marginTop: 18 }}>
                <Slider
                  {...sliderSettings}
                  style={{ height: "100%", width: "100%" }}
                >
                  {backgroundImages.map((item, index) => (
                    <MKBox key={index} height="100%">
                      <Grid
                        container
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          backgroundImage: `url(${item})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          width: "100%",
                          height: "250px",
                          borderRadius: 4,
                        }}
                      />
                    </MKBox>
                  ))}
                </Slider>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomPagination
                    currentSlide={currentSlide}
                    slideCount={3}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Acc_Pub2})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "300px",
                  borderRadius: 4,
                }}
              ></Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundImage: `url(${AccomadationPage.Acc_Pub3})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "400px",
                  height: "300px",
                  marginTop: "15px",
                  borderRadius: 4,
                }}
              ></Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*Reviews*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Guest Testimonials
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Hear from our guests as they share their memorable experiences.
                From our exceptional service to the cozy ambiance, their stories
                showcase the unique charm that makes every stay special.
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center" }}
            spacing={2}
            lg={11.5}
          >
            <ElfsightWidget />
          </Grid>
          {/*Offers */}
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "40px",
              marginTop: 7,
              position: "relative",
              paddingBottom: 10,
              "&::before": {
                content: '""',
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${HomePage.OfferBg})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom center",
                opacity: 1,
                zIndex: 0,
              },
              zIndex: 2,
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                lg={8}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
              >
                <MKTypography
                  variant="h6"
                  fontWeight="regular"
                  color="black"
                  sx={{
                    textAlign: "center",
                    maxWidth: "90%",
                    fontFamily: "Lexend",
                  }}
                >
                  You Might Also Like
                </MKTypography>
                <div className="text-with-lines">
                  <MKTypography
                    variant="h1"
                    color="black"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                      },
                      fontFamily: "Italiana",
                      fontSize: "60px",
                      fontWeight: 400,
                    })}
                  >
                    Heaven Seven Offers
                  </MKTypography>
                </div>
              </Grid>
            </Container>
            <Grid container spacing={2} lg={11.5} sx={{}}>
              {offerCardData?.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <OffersCard item={item} index={index} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Accomadation_NuwaraEliya;
