import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import MKTypography from "components/MKTypography";
import FacebookIcon from "@mui/icons-material/Facebook";
import { YouTube, WhatsApp } from "@mui/icons-material";
import Instagram from "@mui/icons-material/Instagram";
import { AccomadationPage, Logos } from "constants/images";
import { useNavigate } from "react-router-dom";

const socials = [
  {
    icon: <FacebookIcon />,
    link: "https://web.facebook.com/heavensevenhotels/?_rdc=1&_rdr#",
  },
  {
    icon: <Instagram />,
    link: "https://www.instagram.com/heavensevenhotels/?hl=en",
  },
  // {
  //   icon: <YouTube />,
  //   link: "",
  // },
  // {
  //   icon: <WhatsApp />,
  //   link: "",
  // },
];

const Footer = () => {
  const navigate = useNavigate();

  const onItemClick = (item) => {
    console.log("ITEM", item);

    switch (item) {
      case "Home":
        navigate("/home");
        return;
      case "Hotel":
        navigate("/pages/acc_nuwaraeliya");
        return;
      case "Nuwara Eliya":
        navigate("/pages/acc_nuwaraeliya");
        return;
      case "Kandy":
        navigate("/pages/accomadation");
        return;
      case "Gallery":
        navigate("/pages/gallery");
        return;
      case "Experiences":
        navigate("/pages/experience");
        return;
      case "Contact Us":
        navigate("/pages/contact-us");
        return;
      case "About":
        navigate("/pages/about-us");
        return;
      default:
        navigate("/home");
        return;
    }
  };

  return (
    <Box
      sx={{
        color: "#fff",
        padding: "40px 20px",
        backgroundColor: "#002814",
        position: "relative", // Required for pseudo-element positioning
        "&::before": {
          content: '""',
          position: "absolute",
          top: "10%",
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${AccomadationPage.Mountains})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          opacity: 0.05,
          zIndex: 0,
        },
      }}
    >
      <Grid container spacing={4}>
        {/* Logo and Description */}
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={{ xs: "center", md: "flex-start" }}
            sx={{
              marginLeft: { xs: 0, md: 6 },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <img
              src={Logos.FooterLogo}
              alt="Heaven Seven Logo"
              style={{ marginBottom: "16px", width: "80px" }}
            />
            <Typography
              variant="body2"
              sx={{
                lineHeight: 1,
                width: { xs: "100%", md: "80%" },
                fontSize: 15,
                marginTop: 2,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Experience the epitome of hospitality and timeless elegance with
              Heaven Seven Hotels. Uniting luxury with comfort in spectacular
              locations, we ensure each visit is uniquely memorable.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: 10,
                fontSize: "12px",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              © Haven Seven - All rights reserved
            </Typography>
          </Box>
        </Grid>

        {/* Hotels Section */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <MKTypography
            color="white"
            variant="h6"
            sx={{ marginBottom: "8px", fontFamily: "Lexend" }}
          >
            Hotels
          </MKTypography>
          <Box
            sx={{
              borderBottom: "1px solid #fff",
              marginBottom: "8px",
              width: "50%",
              display: "block",
              margin: { xs: "0 auto 8px auto", md: "0 0 8px 0" },
            }}
          />
          <Typography
            fontFamily={"Lexend"}
            sx={{ color: "#A7A7A7", fontSize: 14 }}
            variant="body2"
          >
            Heaven Seven Kandy
          </Typography>
          <Typography
            sx={{ color: "#A7A7A7", fontSize: 14 }}
            fontFamily={"Lexend"}
            variant="body2"
          >
            Heaven Seven Nuwaraeliya
          </Typography>
        </Grid>

        {/* Quick Links Section */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <MKTypography
            color="white"
            variant="h6"
            sx={{ marginBottom: "8px", fontFamily: "Lexend" }}
          >
            Quick Links
          </MKTypography>
          <Box
            sx={{
              borderBottom: "1px solid #fff",
              marginBottom: "8px",
              width: "50%",
              margin: { xs: "0 auto 8px auto", md: "0 0 8px 0" },
            }}
          />
          <MKTypography
            onClick={() => onItemClick("Home")}
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              zIndex: 1,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
          >
            Home
          </MKTypography>
          <Typography
            onClick={() => onItemClick("Hotel")}
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
          >
            Hotels
          </Typography>
          <Typography sx={{ color: "#A7A7A7", fontSize: 14 }} variant="body2">
            Special Offers
          </Typography>
          <Typography
            onClick={() => onItemClick("Gallery")}
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
          >
            Gallery
          </Typography>
          <Typography
            onClick={() => onItemClick("Experiences")}
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
          >
            Experiences
          </Typography>
          <Typography
            onClick={() => onItemClick("Contact Us")}
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
          >
            Contact Us
          </Typography>
          <Typography
            onClick={() => onItemClick("About")}
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
          >
            About Us
          </Typography>
        </Grid>

        {/* Contact Section */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <MKTypography
            color="white"
            variant="h6"
            sx={{ marginBottom: "8px", fontFamily: "Lexend" }}
          >
            Contact
          </MKTypography>
          <Box
            sx={{
              borderBottom: "1px solid #fff",
              marginBottom: "8px",
              width: "50%",
              margin: { xs: "0 auto 8px auto", md: "0 0 8px 0", zIndex: 1000 },
            }}
          />
          <Typography variant="body2">
            <strong>Email</strong>
          </Typography>
          <Typography
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              zIndex: 1,
              cursor: "pointer",
              position: "relative",
            }}
            variant="body2"
            onClick={() =>
              window.open("mailto:info@heavensevenhotels.com", "_blank")
            }
          >
            info@heavensevenhotels.com
          </Typography>
          <Typography variant="body2">
            <strong>Telephone</strong>
          </Typography>
          <Typography sx={{ color: "#A7A7A7", fontSize: 14 }} variant="body2">
            +94 522 234 256
          </Typography>
          <Typography variant="body2">
            <strong>Address</strong>
          </Typography>
          <Typography
            sx={{
              color: "#A7A7A7",
              fontSize: 14,
              textDecoration: "underline",
              maxWidth: { xs: "100%", md: "auto" },
              margin: { xs: "0 auto", md: "0" },
            }}
            variant="body2"
          >
            244, Haddon Hill Road, Nuwara Eliya, Nuwara Eliya District 22200.
          </Typography>
          <Box
            display="flex"
            gap={3}
            marginTop="16px"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            {socials.map(({ icon, link }, key) => (
              <MKTypography
                key={key}
                variant="h5"
                color="white"
                opacity={1}
                sx={{ cursor: "pointer", zIndex: 1 }}
                onClick={() => window.open(link, "_blank")}
              >
                {icon}
              </MKTypography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
