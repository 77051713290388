import React, { useRef, useState, useEffect } from "react";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ReviewCardHome from "components/ReviewCardHome";
import HeaderThree from "layouts/sections/page-sections/page-headers/components/HeaderThree";
import { ReactComponent as Feather } from "../../assets/icons/feather.svg";
import { ReactComponent as Cloud } from "../../assets/icons/cloud.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Sun } from "../../assets/icons/sun.svg";
import CheckIcon from "@mui/icons-material/Check";
import Footer from "components/Footer";
import { UilClock, UilCloud } from "@iconscout/react-unicons";
import foodImg from "assets/images/homePage/food.jpeg";
import { AccomadationPage, HomePage, ExperiencePage } from "constants/images";
import { UilArrowRight } from "@iconscout/react-unicons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slider from "react-slick";
import { ExperienceContent } from "constants/experianceContent";
import { useLocation } from "react-router-dom";
import "./styles.css";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";

function Experience() {
  const navigate = useNavigate();
  const location = useLocation();
  const [experience, setExperiance] = useState(
    location.state?.item || ExperienceContent[0]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [experience]);

  const handleExperienceSelect = (exp) => {
    setExperiance(exp);
    window.history.pushState({}, '', `/pages/experience/${exp.title}`);
  };

  const faq = [
    {
      title: "What are the check-in and check-out times at the hotel?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Do you provide guided tours of nearby attractions?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "What dining options are available at the hotel?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Do you offer transportation services between Colombo and Kandy?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Can I request a room with a specific view or location?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Are pets allowed at your locations?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
  ];

  const expCardData = [
    {
      title: "Lover's Leap Waterfall",
      image1: AccomadationPage.Exp1,
      description: "Approximately 5.5K.M From the Hotel",
      btnText: ["Half Day", "Scenery", "Biodiversity"],
    },
    {
      title: "Victoria Park",
      image1: AccomadationPage.Exp3,
      description: "Approximately 3.2K.M From the Hotel",
      btnText: ["Full Day", "Scenery", "Picnic Spot"],
    },
    {
      title: "Gregory Park",
      image1: AccomadationPage.Exp4,
      description: "Approximately 1.9K.M From the Hotel",
      btnText: ["Full Day", "Nature", "Photography"],
    },
  ];

  const expArray = [
    {
      icon: <Clock />,
      text1: "Duration:",
      text2: experience?.duration,
    },
    {
      icon: <Cloud />,
      text1: "Category:",
      text2: experience?.category,
    },
    {
      icon: <Feather />,
      text1: "Attraction:",
      text2: experience?.attraction,
    },
    {
      icon: <Sun />,
      text1: "Climate:",
      text2: experience?.climate,
    },
  ];

  const ExpCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: 0,
          flex: 1,
          marginBottom: 5,
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "350px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          >
            <Grid
              sx={{
                border: "solid",
                borderWidth: 1,
                width: "95%",
                height: "95%",
                borderColor: "#ffffff",
                zIndex: 100,
              }}
            />
          </Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "25px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#017836",
                [breakpoints.down("sm")]: {
                  fontSize: "30px",
                },
              })}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "14px",
                fontFamily: "Lexend",
                lineHeight: "20px",
                fontWeight: 400,
                marginTop: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1, // Limits text to 5 lines
                overflow: "hidden",
                textOverflow: "ellipsis",
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              {item?.description[0]}
            </MKTypography>
          </Grid>

          <Grid
            container
            sx={{
              gap: 1,
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "row", sm: "row" },
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: 10,
                borderColor: "#A7A7A7",
                padding: 1,
                alignItems: "center",
                gap: 1,
                borderStyle: "solid",
                borderWidth: 0.75,
              }}
            >
              <UilClock color="#484848" width="15px" height="15px" />

              <MKTypography
                sx={{ fontWeight: 400, color: "#484848", fontSize: 13 }}
              >
                {item?.duration}
              </MKTypography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: 10,
                borderColor: "#A7A7A7",
                padding: 1,
                alignItems: "center",
                gap: 1,
                borderStyle: "solid",
                borderWidth: 0.75,
              }}
            >
              <UilCloud width="15px" height="15px" />
              <MKTypography
                sx={{ fontWeight: 400, color: "#484848", fontSize: 12 }}
              >
                {item?.category}
              </MKTypography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: 10,
                borderColor: "#A7A7A7",
                padding: 1,
                alignItems: "center",
                gap: 1,
                borderStyle: "solid",
                borderWidth: 0.75,
              }}
            >
              <Feather
                sx={{ fill: "red", fontWeight: 400 }}
                width="14px"
                height="14px"
                minHeight="14px"
                minWidth="14px"
              />
              <MKTypography
                sx={{ fontWeight: 400, color: "#484848", fontSize: 12 }}
              >
                {item?.attraction}
              </MKTypography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const Checklist = () => {
    return (
      <MKBox sx={{ maxWidth: "900px", margin: "auto", padding: "2rem 0" }}>
        <Grid container spacing={2}>
          {/* What to Wear */}
          <Grid item xs={12} md={5}>
            <MKTypography variant="h6" fontWeight="bold">
              What to Wear
            </MKTypography>
            {experience.wear.map((item, index) => (
              <MKBox
                key={index}
                display="flex"
                alignItems="center"
                gap={1}
                mt={1}
              >
                <CheckIcon sx={{ color: "green" }} />
                <MKTypography variant="body1">{item}</MKTypography>
              </MKBox>
            ))}
          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={2} display="flex" justifyContent="center">
            <MKBox
              sx={{ width: "1px", height: "100%", backgroundColor: "#ccc" }}
            />
          </Grid>

          {/* What to Bring */}
          <Grid item xs={12} md={5}>
            <MKTypography variant="h6" fontWeight="bold">
              What to Bring
            </MKTypography>
            {experience.bring.map((item, index) => (
              <MKBox
                key={index}
                display="flex"
                alignItems="center"
                gap={1}
                mt={1}
              >
                <CheckIcon sx={{ color: "green" }} />
                <MKTypography variant="body1">{item}</MKTypography>
              </MKBox>
            ))}
          </Grid>
        </Grid>
      </MKBox>
    );
  };

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      &gt; {/* Use &gt; for > */}
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      &lt; {/* Use &lt; for < */}
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow onClick={() => {}} />,
    prevArrow: <PrevArrow onClick={() => {}} />,
  };

  return (
    <div>
      <div style={{ overflow: "hidden", backgroundColor: "white" }}>
        {/* Explore our travel solutions */}
        <HeaderThree
          title={experience?.title}
          backgroundImage={experience?.image}
          subHead="sub"
          headerFontSize={55}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: 6,
          }}
          mt={8}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={11}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Overall Experience
                </MKTypography>
              </div>
              {experience?.description && experience?.description.length > 0
                ? experience?.description.map((item, index) => (
                    <MKTypography
                      variant="h6"
                      fontWeight="regular"
                      color="black"
                      sx={{
                        textAlign: "left",
                        maxWidth: "100%",
                        fontFamily: "Lexend",
                      }}
                    >
                      {item}
                    </MKTypography>
                  ))
                : null}
            </Grid>
          </Container>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
            lg={10}
          >
            {expArray.map((exp) => {
              return (
                <Grid sx={{ flexDirection: "row", display: "flex", gap: 2 }}>
                  {exp?.icon}
                  <MKTypography
                    variant="h1"
                    color="black"
                    sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("xl")]: {
                        fontSize: size["2xl"],
                      },
                      fontFamily: "Lexend",
                      fontSize: "16px",
                    })}
                  >
                    <span style={{ fontWeight: "bold" }}>{exp.text1}</span>
                    <span style={{ fontWeight: 300, marginLeft: 4 }}>
                      {exp.text2}
                    </span>
                  </MKTypography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: 7,
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={12}
              flexDirection="column"
              justifyContent="left"
              sx={{
                textAlign: "left",
                marginBottom: "20px",
                marginTop: 2,
              }}
            >
              <div className="text-with-lines-2">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Highlights
                </MKTypography>
              </div>
            </Grid>
          </Container>

          <Grid container spacing={3} lg={10}>
            <MKBox component="ul" pl={2}>
              {experience?.highlights && experience?.highlights.length > 0
                ? experience?.highlights.map((item, index) => (
                    <MKTypography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "20px",
                        color: "#000000",
                        marginBottom: "30px",
                      }}
                      component="li"
                      variant="body1"
                    >
                      <strong>{item?.key}</strong> {item?.value}
                    </MKTypography>
                  ))
                : null}
            </MKBox>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "550px",
            backgroundImage: `url(${experience?.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white", // Ensuring text is visible over the image
            px: 2,
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)", // Slight overlay for better text visibility
            },
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              position: "relative",
              fontFamily: "Italiana",
              fontWeight: 500,
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "3.5rem",
                color: "white",
              }, // Responsive font size
            }}
          >
            "Nature’s Masterpiece in the Highlands."
          </Typography>
        </Box>
        {/*Explore*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            zIndex: 2,
          }}
        >
          <Grid
            container
            spacing={2}
            lg={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            <Checklist />
          </Grid>
        </Grid>

        {/*FAQ*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            position: "relative",
            paddingBottom: 5,
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  FAQ
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Find quick answers to common questions in our FAQ section. We've
                compiled essential information to make your experience with us
                smooth and hassle-free.
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            alignItems="center"
            sx={{ width: "70%" }}
          >
            <Divider
              variant="middle"
              sx={{
                height: 2,
                width: "100%",
                backgroundColor: "#C9C5BA",
              }}
            />
            <Grid container display={"flex"} flexDirection="column">
              {experience.faq.map((item, index) => (
                <Accordion key={index} sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="black" />}
                    aria-controls="panel1-content"
                    id={`panel1-header-${index}`}
                    sx={{ boxShadow: "none" }}
                  >
                    {item?.que}
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#F4F4F4" }}>
                    <MKTypography sx={{ fontSize: 13, fontFamily: "Lexend" }}>
                      {item?.ans}
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/*EXP*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={11}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "50px",
                    fontWeight: 400,
                  })}
                >
                  More Experiences You Might Like
                </MKTypography>
              </div>
            </Grid>
          </Container>
          <Grid
            container
            spacing={2}
            lg={11.5}
            sx={{ justifyContent: "center", display: "flex" }}
          >
            {ExperienceContent.filter((exp) => {
              const group = experience?.id <= 4 ? [1, 2, 3, 4] : [5, 6, 7, 8];
              return group.includes(exp.id) && exp.id !== experience?.id;
            })
              .slice(0, 3)
              .map((exp, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={exp.id}
                  onClick={() => handleExperienceSelect(exp)}
                >
                  <ExpCard item={exp} index={index} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Experience;
