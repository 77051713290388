import { AccomadationPage, HomePage } from "constants/images";

export const ExperienceContent = [
  {
    id: 1,
    title: "Temple of the Tooth Relic",
    image: AccomadationPage.Experience1,
    description: [
      "The Temple of the Tooth Relic, located in Kandy, Sri Lanka, is one of the most sacred Buddhist sites in the world. It houses the revered tooth relic of Gautama Buddha, making it an important pilgrimage destination. The temple's architecture is a blend of Kandyan, South Indian, and Buddhist influences, featuring intricate carvings, golden decorations, and stunning paintings.",
      "Visitors can witness the daily rituals (Poojas) held three times a day, where monks offer flowers, incense, and prayers to the sacred relic. The temple is also the heart of Esala Perahera, an annual grand festival featuring beautifully adorned elephants, dancers, and drummers.",
      "Surrounded by the peaceful Kandy Lake, the temple offers a blend of spirituality, history, and cultural heritage. Whether you're a pilgrim or a tourist, the Temple of the Tooth provides a serene and enlightening experience.",
    ],
    duration: "1-2 Hours",
    category: "Cultural & Religious",
    attraction: "Sacred Relic",
    climate: "Mild to Warm",
    highlights: [
      {
        key: "The Sacred Tooth Relic:",
        value:
          "Enshrined within a golden casket, the relic is worshiped by thousands of devotees.",
      },
      {
        key: "Temple Architecture:",
        value:
          "Marvel at the Kandyan-era architecture, including beautifully carved wooden pillars and golden roofs.",
      },
      {
        key: "The Esala Perahera:",
        value:
          "One of Asia’s most spectacular Buddhist festivals held in July/August, featuring elephants, dancers, and fire performances.",
      },
      {
        key: "Audience Hall:",
        value:
          "A historical open-air pavilion used for royal and religious ceremonies.",
      },
      {
        key: "Museum and Artifacts:",
        value:
          "The temple complex includes museums showcasing Buddhist relics, ancient manuscripts, and artifacts.",
      },
      {
        key: "Kandy Lake:",
        value:
          "A picturesque man-made lake surrounding the temple, ideal for a peaceful walk.",
      },
      {
        key: "Best Time to Visit:",
        value: " Early morning or evening (to witness the pooja ceremonies).",
      },
    ],
    wear: [
      "Modest, white or light-colored clothing (shoulders and knees must be covered)",
      "Comfortable footwear (must be removed before entering the temple)",
      "A shawl or scarf (for extra modesty)",
    ],
    bring: [
      "Camera (Photography is allowed in most areas)",
      "Water bottle",
      "Small offering (flowers or incense)",
      "Sunglasses and sunscreen",
    ],
    faq: [
      {
        que: "What is the Temple of the Tooth Relic?",
        ans: "The Temple of the Tooth Relic (Sri Dalada Maligawa) is a sacred Buddhist temple in Kandy, Sri Lanka, that houses the left canine tooth of Lord Buddha. It is one of the most revered religious sites in Buddhism.",
      },
      {
        que: "Where is the temple located?",
        ans: "The temple is in the heart of Kandy, next to Kandy Lake, within the royal palace complex of the former Kingdom of Kandy.",
      },
      {
        que: "How should I dress when visiting the temple?",
        ans: `Dress modestly - Wear clothing that covers your shoulders and knees.
white or light-colored outfits are preferred as a sign of respect.
Remove shoes and hats before entering the temple premises.`,
      },
      {
        que: "Can I take photos inside the temple?",
        ans: "Yes, but photography is restricted inside the inner shrine where the relic is kept.Flash photography and selfies in sacred areas are discouraged.",
      },
      {
        que: "What is the best time to visit the temple?",
        ans: "The temple is less crowded in the early morning and evening, making it the best time for a peaceful experience. If you want to witness the pooja ceremonies, visit during prayer times.",
      },
      {
        que: "What is the Esala Perahera festival?",
        ans: "The Esala Perahera is a grand annual Buddhist festival held in July/August, featuring beautifully decorated elephants, traditional dancers, drummers, and fire performers. It is one of the biggest cultural events in Sri Lanka.",
      },
      {
        que: "How long does a visit to the temple take?",
        ans: "A typical visit lasts 1–2 hours, but during festivals or peak times, it may take longer.",
      },
      {
        que: "Are there guides available?",
        ans: "Yes, official guides are available at the entrance for a fee. You can also hire an audio guide or use an online guidebook for a self-guided tour.",
      },
      {
        que: "Is the temple accessible for people with disabilities?",
        ans: "Yes, the temple has ramps and pathways for wheelchair access, but some areas may be difficult to navigate due to historical architecture.",
      },
    ],
  },
  {
    id: 2,
    title: "Royal Botanical Gardens",
    image: AccomadationPage.Experience2,
    description: [
      "The Royal Botanical Gardens, Peradeniya, is the largest and most impressive botanical garden in Sri Lanka, located just 5.5 km from Kandy. Spanning 147 acres, the gardens are home to over 4,000 species of plants, including towering trees, exotic flowers, and medicinal herbs.",
      "A key attraction is the Orchid House, showcasing rare and vibrant orchid species. The Avenue of Royal Palms offers a picturesque pathway lined with majestic palm trees, while the Giant Javan Fig Tree provides a perfect resting spot with its massive, spreading canopy.",
      "Beyond its beauty, the Royal Botanical Gardens serve as a center for plant research, conservation, and education, making it a perfect destination for nature lovers, photographers, and anyone seeking a peaceful retreat.",
    ],
    duration: "Half Day",
    category: "Nature & Gardens",
    attraction: "Orchid House, Palm Avenues",
    climate: "Mild & Humid",
    highlights: [
      {
        key: "Orchid House:",
        value:
          "A must-visit section showcasing a stunning collection of orchids, including rare and indigenous species.",
      },
      {
        key: "Avenue of Royal Palms:",
        value:
          "A scenic pathway lined with majestic palm trees, perfect for a leisurely stroll.",
      },
      {
        key: "Giant Javan Fig Tree:",
        value:
          "A massive tree with a wide-spreading canopy, creating a natural shelter and a perfect spot to relax.",
      },
      {
        key: "Cannonball Tree:",
        value:
          "An iconic tree with large, round fruits, planted by British royalty in the early 1900s.",
      },
      {
        key: "Medicinal Plants Section:",
        value:
          "A collection of native herbs used in traditional Ayurvedic medicine.",
      },
      {
        key: "Suspension Bridge:",
        value:
          "A hidden gem within the gardens, offering a unique perspective of the landscape.",
      },
      {
        key: "Best Time to Visit:",
        value:
          "Morning or late afternoon to enjoy the cooler temperatures and avoid crowds.",
      },
    ],
    wear: [
      "Comfortable Walking Shoes",
      "Light, Breathable Clothes",
      "Hat and Sunglasses",
      "Rain Jacket or Umbrella (for unexpected showers)",
    ],
    bring: [
      "Water and Snacks",
      "Camera or Smartphone for Photography",
      "Sunscreen and Insect Repellent",
      "Notebook (if interested in plant species and conservation)",
    ],
    faq: [
      {
        que: "What are the opening hours of the Royal Botanical Gardens?",
        ans: "The gardens are open daily from 7:30 AM to 5:00 PM.",
      },
      {
        que: "How long does it take to explore the gardens?",
        ans: "A complete tour takes around 2–3 hours, but visitors can spend more time relaxing and enjoying the scenery.",
      },
      {
        que: "Are guided tours available?",
        ans: "Yes, guided tours are available for an additional fee, providing insights into the garden’s history and plant species.",
      },
      {
        que: "Is the garden wheelchair accessible?",
        ans: "Yes, most of the main paths are wheelchair-friendly.",
      },
      {
        que: "How do I get to the Royal Botanical Gardens from Kandy?",
        ans: "You can reach the gardens by tuk-tuk (15 minutes from Kandy city), public bus (Route 644 from Kandy), or taxi/private vehicle.",
      },
    ],
  },

  {
    id: 3,
    title: "Kandy Lake",
    image: AccomadationPage.Experience3,
    description: [
      "Located in the heart of Kandy, Kandy Lake (also known as Kiri Muhuda, meaning 'Sea of Milk') is a serene and picturesque attraction that enhances the beauty of the city. Built in 1807 by King Sri Wickrama Rajasinghe, the lake is an integral part of Kandy’s history and provides a peaceful escape.",
      "Surrounded by lush greenery and historical sites, Kandy Lake is a popular spot for walking, jogging, and birdwatching. A unique feature of the lake is the small island in the center, believed to have been a private bathing area for the king’s harem.",
      "Whether you take a leisurely stroll, enjoy a boat ride, or relax on a bench, Kandy Lake is a must-visit spot for travelers seeking tranquility and scenic beauty.",
    ],
    duration: "1–2 Hours",
    category: "Nature & Scenic Walks",
    attraction: "Lakeside Walk, Birdwatching, History",
    climate: "Mild & Humid",
    highlights: [
      { key: "Lakeside Promenade:", value: "A 2.1 km scenic walking path." },
      {
        key: "Island in the Lake:",
        value:
          "A mysterious island believed to have been a royal bathing area.",
      },
      {
        key: "Temple of the Tooth Relic View:",
        value: "The lake offers stunning views of the iconic temple.",
      },
      {
        key: "Birdwatching & Wildlife:",
        value: "Spot pelicans, egrets, kingfishers, and monitor lizards.",
      },
      {
        key: "Best Time to Visit:",
        value:
          "Early morning or evening for cooler temperatures and great views.",
      },
    ],
    wear: [
      "Comfortable Walking Shoes",
      "Light, Breathable Clothes",
      "Hat and Sunglasses",
      "Modest Clothing (if visiting nearby temples)",
    ],
    bring: [
      "Water and Snacks",
      "Camera",
      "Sunscreen and Insect Repellent",
      "Binoculars (if interested in birdwatching)",
    ],
    faq: [
      {
        que: "Is there an entrance fee to visit Kandy Lake?",
        ans: "No, visiting Kandy Lake is free of charge.",
      },
      {
        que: "Can we take a boat ride on the lake?",
        ans: "Boat rides are not regularly available, but some private operators offer them on request.",
      },
      {
        que: "How long does it take to walk around Kandy Lake?",
        ans: "It takes approximately 30–40 minutes to complete a full 2.1 km walk around the lake.",
      },
      {
        que: "Are there restaurants or cafés near Kandy Lake?",
        ans: "Yes, several restaurants and cafés are located nearby, offering local and international cuisine with stunning lake views.",
      },
      {
        que: "Is Kandy Lake safe to visit at night?",
        ans: "While the area is generally safe, it's best to visit before sunset as it can get quieter at night.",
      },
      {
        que: "Can we swim in Kandy Lake?",
        ans: "No, swimming is not allowed due to safety and environmental concerns.",
      },
      {
        que: "What is the best way to reach Kandy Lake?",
        ans: "Kandy Lake is centrally located and easily accessible by walking, tuk-tuk, public bus, or taxi.",
      },
    ],
  },
  {
    id: 4,
    title: "Kandy Viewpoint",
    image: AccomadationPage.Kandy_View,
    description: [
      "Perched on a hilltop overlooking Kandy city, the Kandy Viewpoint offers a breathtaking panoramic view of the entire city, including the iconic Kandy Lake, Temple of the Tooth Relic, and surrounding lush greenery.",
      "This scenic spot is one of the best places to witness the beauty of Kandy, especially during sunrise or sunset when the golden hues create a mesmerizing landscape.",
      "Located just a short drive or steep uphill walk from the city center, the viewpoint provides a peaceful escape from the bustling streets below. It is a popular spot for photography, sightseeing, and simply enjoying the refreshing breeze.",
      "Whether you are a nature lover, a photographer, or just looking for a relaxing place to admire Kandy’s beauty, the Kandy Viewpoint is a must-visit attraction.",
    ],
    duration: "30 Minutes – 1 Hour",
    category: "Scenic Views & Photography",
    attraction: "Panoramic City Views, Sunrise/Sunset Spot",
    climate: "Cool & Breezy",
    highlights: [
      {
        key: "Panoramic View of Kandy:",
        value:
          "Enjoy a stunning 360-degree view of Kandy’s cityscape, including Kandy Lake, the Temple of the Tooth Relic, and lush hills.",
      },
      {
        key: "Best Spot for Sunrise & Sunset:",
        value:
          "The viewpoint is especially popular for its breathtaking sunrises and golden-hour sunsets, making it a photographer’s paradise.",
      },
      {
        key: "Easy Access from Kandy City:",
        value:
          "Located just 1 km from Kandy city center, accessible by tuk-tuk, car, or a short hike.",
      },
      {
        key: "Local Souvenir Shops & Snack Stalls:",
        value:
          "Small shops around the viewpoint sell handicrafts, postcards, and local snacks, allowing visitors to take home a piece of Kandy.",
      },
    ],
    wear: [
      "Comfortable Walking Shoes (for the uphill walk)",
      "Light, Breathable Clothes (for the warm weather)",
      "A Light Jacket (if visiting in the early morning or evening)",
    ],
    bring: [
      "Camera or Smartphone for Photography",
      "Water and Snacks",
      "Sunglasses and Hat (for sunny days)",
      "Tripod (if taking time-lapse or long-exposure shots)",
    ],
    faq: [
      {
        que: "Is there an entrance fee for Kandy Viewpoint?",
        ans: "No, visiting the Kandy Viewpoint is free of charge.",
      },
      {
        que: "How do I get to Kandy Viewpoint?",
        ans: "You can reach the viewpoint by walking (about 15–20 minutes uphill from Kandy Lake), tuk-tuk (quick and affordable ride from the city), or car/private vehicle (small parking area available).",
      },
      {
        que: "What is the best time to visit?",
        ans: "The best time to visit is early morning (before 8 AM) or evening (around 5–6 PM) for the most stunning views and cooler weather.",
      },
      {
        que: "Can I take professional photos or videos at the viewpoint?",
        ans: "Yes, photography is allowed, and the spot is perfect for capturing the beauty of Kandy.",
      },
    ],
  },
  {
    id: 5,
    title: "Lover’s Leap Waterfall",
    image: AccomadationPage.Experience5,
    description: [
      "Nestled in the scenic hills of Nuwara Eliya, Lover’s Leap Waterfall is a stunning 30-meter cascade that tumbles down rugged cliffs surrounded by lush tea plantations.",
      "The waterfall is not only a breathtaking natural wonder but also holds a romantic legend—a story of two lovers who, forbidden from being together, leaped off the cliff, giving the waterfall its name.",
      "Located just 3 km from Nuwara Eliya town, Lover’s Leap is easily accessible and is a popular spot for nature lovers, hikers, and photographers.",
      "The serene atmosphere, fresh mountain air, and the sound of rushing water make it a perfect getaway from the busy town life.",
    ],
    duration: "30 Minutes – 1 Hour",
    category: "Nature & Adventure",
    attraction: "Scenic Waterfall, Hiking, Photography",
    climate: "Cool & Misty",
    highlights: [
      {
        key: "Stunning 30-Meter Waterfall:",
        value:
          "Witness the majestic cascade that flows beautifully down rocky cliffs, creating a mesmerizing sight.",
      },
      {
        key: "Romantic Legend:",
        value:
          "The waterfall is named after a tragic love story, adding a mystical and historical charm to the experience.",
      },
      {
        key: "Scenic Hiking Trail:",
        value:
          "A short trek through tea plantations and rocky paths leads to the waterfall, offering stunning views along the way.",
      },
      {
        key: "Panoramic Viewpoint:",
        value:
          "The area around Lover’s Leap offers breathtaking views of Nuwara Eliya and the surrounding tea estates.",
      },
      {
        key: "Serene Atmosphere:",
        value:
          "The calm and refreshing environment makes it a great place to relax and connect with nature.",
      },
    ],
    wear: [
      "Comfortable Hiking Shoes (for the rocky trail)",
      "Light, Breathable Clothes (for the mild weather)",
      "A Jacket or Sweater (as it can get chilly)",
    ],
    bring: [
      "Camera or Smartphone for Photography",
      "Water and Snacks",
      "Sunglasses and Hat (for sunny days)",
      "Binoculars (to spot birds and distant views)",
    ],
    faq: [
      {
        que: "How do I get to Lover’s Leap Waterfall?",
        ans: "The waterfall is 3 km from Nuwara Eliya town. You can reach it by walking or hiking (a moderate 30-minute walk through tea plantations), or by tuk-tuk/car (follow the road towards Pedro Tea Estate and take a short walk from the drop-off point).",
      },
      {
        que: "Is there an entrance fee?",
        ans: "No, visiting Lover’s Leap Waterfall is free of charge.",
      },
      {
        que: "What is the best time to visit?",
        ans: "The best time to visit is early morning or late afternoon, when the weather is cool and the light is perfect for photography. The waterfall is most impressive after rainfall.",
      },
      {
        que: "Is the hike difficult?",
        ans: "The hike is moderate with some rocky and uneven paths, but it’s manageable for most visitors.",
      },
      {
        que: "Can I swim in the waterfall?",
        ans: "No, swimming is not safe due to strong currents and slippery rocks.",
      },
      {
        que: "Are there any nearby attractions?",
        ans: "Yes! Pedro Tea Estate is located nearby, where you can tour a tea factory and enjoy scenic views of tea plantations.",
      },
      {
        que: "Is it suitable for families and elderly visitors?",
        ans: "Yes, but elderly visitors should be cautious, as the hiking path can be steep and uneven.",
      },
    ],
  },
  {
    id: 6,
    title: "Horton Plains National Park",
    image: AccomadationPage.Exp2,
    description: [
      "Horton Plains National Park is a breathtaking UNESCO World Heritage Site located in the central highlands of Sri Lanka, known for its misty landscapes, unique biodiversity, and stunning viewpoints.",
      "Spanning 3,160 hectares, this protected nature reserve is home to rolling grasslands, dense cloud forests, rare wildlife, and spectacular waterfalls.",
      "One of the main highlights of Horton Plains is the famous World’s End, a sheer 870-meter drop, offering panoramic views of the lowlands below.",
      "The Baker’s Falls, a picturesque 20-meter waterfall, is another must-visit attraction within the park.",
      "The cool climate, scenic hiking trails, and diverse wildlife, including the Sri Lankan sambar deer, purple-faced langurs, and rare endemic birds, make this an ideal destination for nature lovers, hikers, and wildlife enthusiasts.",
    ],
    duration: "3 – 5 Hours",
    category: "Nature & Wildlife, Hiking, Scenic Views",
    attraction: "World’s End, Baker’s Falls, Mini World’s End",
    climate: "Cool & Misty",
    highlights: [
      {
        key: "World’s End:",
        value:
          "A dramatic 870-meter cliff offering stunning panoramic views on clear days.",
      },
      {
        key: "Baker’s Falls:",
        value:
          "A beautiful 20-meter cascade surrounded by lush greenery and a great spot for photography.",
      },
      {
        key: "Mini World’s End:",
        value:
          "A smaller cliff (270 meters high) with scenic views, located along the same trail.",
      },
      {
        key: "Rich Biodiversity:",
        value:
          "Home to rare wildlife including the Sri Lankan sambar deer, leopards, and endemic bird species.",
      },
      {
        key: "Scenic Hiking Trails:",
        value:
          "A 9 km circular trail takes you through grasslands, cloud forests, and rocky paths, making it a refreshing and adventurous experience.",
      },
    ],
    wear: [
      "Comfortable Hiking Shoes (for uneven and muddy terrain)",
      "Layered Clothing (as it can be chilly in the morning but warm later)",
      "Cap or Hat (for sun protection)",
    ],
    bring: [
      "Camera or Smartphone for Photography",
      "Plenty of Water and Snacks",
      "Sunglasses and Sunscreen",
      "Binoculars for Birdwatching",
      "Raincoat or Jacket (as weather changes quickly)",
    ],
    faq: [
      {
        que: "How do I get to Horton Plains National Park?",
        ans: "Horton Plains is located 32 km from Nuwara Eliya and can be reached by private vehicle or tuk-tuk (from Nuwara Eliya or Ohiya) or by train to Ohiya Station, followed by a tuk-tuk ride to the park entrance.",
      },
      {
        que: "What is the entrance fee?",
        ans: "Entrance fees vary based on nationality. For foreigners, it’s around $25 per person, plus additional vehicle charges.",
      },
      {
        que: "What is the best time to visit?",
        ans: "The best time to visit is early morning (6 AM – 10 AM) when the skies are clear, and you can get a perfect view from World’s End before the mist rolls in.",
      },
      {
        que: "Is the hike difficult?",
        ans: "The 9 km circular hike is moderate with some rocky and uneven paths but is manageable for most visitors.",
      },
      {
        que: "Are there restrooms and food stalls inside?",
        ans: "No, there are no shops or food stalls inside the park, so visitors should bring their own food and water. Basic restroom facilities are available at the entrance.",
      },
      {
        que: "Can I see wildlife at Horton Plains?",
        ans: "Yes! The park is home to Sri Lankan sambar deer, wild boars, leopards (rarely seen), purple-faced langurs, and over 20 species of endemic birds.",
      },
      {
        que: "Can I swim at Baker’s Falls?",
        ans: "No, swimming is not allowed due to strong currents and slippery rocks.",
      },
      {
        que: "Is it suitable for families and elderly visitors?",
        ans: "Yes, but elderly visitors should be prepared for a long walk and bring necessary supportive gear if needed.",
      },
    ],
  },
  {
    id: 7,
    title: "Victoria Park",
    image: AccomadationPage.Exp3,
    description: [
      "Victoria Park is a beautifully landscaped public park located in the heart of Nuwara Eliya, offering a peaceful escape with vibrant flower gardens, well-maintained walking paths, and lush greenery.",
      "Originally established in 1897 to commemorate Queen Victoria’s Diamond Jubilee, this 25-acre park is a popular attraction for families, nature lovers, and bird watchers.",
      "The park is home to over 90 species of colorful flowers, including roses, orchids, and azaleas, which bloom beautifully during April and August – September.",
      "The small lake, charming bridges, and neatly trimmed lawns make it an ideal place for a leisurely stroll or a picnic.",
      "Visitors can also spot rare bird species, such as the Indian Pitta and Kashmir Flycatcher, making it a great spot for birdwatching.",
      "Whether you want to relax amidst fresh mountain air, take stunning photographs, or enjoy a peaceful afternoon with family, Victoria Park is a must-visit attraction in Nuwara Eliya.",
    ],
    duration: "1 – 2 Hours",
    category: "Nature & Relaxation, Sightseeing, Photography",
    attraction: "Flower Gardens, Walking Trails, Birdwatching",
    climate: "Cool & Misty",
    highlights: [
      {
        key: "Beautiful Flower Gardens:",
        value:
          "A seasonal display of vibrant roses, orchids, and exotic plants, making it a paradise for photographers.",
      },
      {
        key: "Scenic Walking Trails:",
        value:
          "Well-paved walking paths surrounded by towering trees and lush greenery, offering a relaxing and refreshing experience.",
      },
      {
        key: "Birdwatching:",
        value:
          "Home to rare species like the Indian Pitta, Kashmir Flycatcher, and Scaly Thrush, attracting bird enthusiasts from around the world.",
      },
      {
        key: "Children’s Playground:",
        value:
          "A fun play area with swings, slides, and recreational activities for kids.",
      },
      {
        key: "Small Lake & Bridges:",
        value:
          "A picturesque pond with charming bridges, creating a romantic and peaceful atmosphere.",
      },
    ],
    wear: [
      "Casual and Comfortable Clothing",
      "A Light Jacket or Sweater (as Nuwara Eliya’s weather is cool)",
      "Comfortable Walking Shoes",
    ],
    bring: [
      "Camera or Smartphone for Photography",
      "Sunglasses & Hat for Sun Protection",
      "Light Snacks & Water",
      "Binoculars for Birdwatching (if interested)",
    ],
    faq: [
      {
        que: "Where is Victoria Park located?",
        ans: "Victoria Park is centrally located in Nuwara Eliya, near the Nuwara Eliya Post Office and Gregory Lake, making it easily accessible.",
      },
      {
        que: "What is the best time to visit?",
        ans: "The best time to visit is during April (flower season) and August – September, when the blooms are at their peak. The park is open daily from 7 AM – 6 PM.",
      },
      {
        que: "Is Victoria Park family-friendly?",
        ans: "Yes! It’s a great place for families, with plenty of open space, a playground, and relaxing picnic spots.",
      },
      {
        que: "Are there food stalls inside?",
        ans: "No, but there are plenty of cafes and restaurants nearby where you can grab a meal. Visitors can bring light snacks and water into the park.",
      },
      {
        que: "Can I take professional photos or have a photoshoot here?",
        ans: "Yes, professional photography is allowed, but some areas may require prior permission or an additional fee.",
      },
    ],
  },
  {
    id: 8,
    title: "Gregory Lake",
    image: AccomadationPage.Exp4, // Replace with your actual image reference from Figma
    description: [
      "Gregory Lake is one of the most scenic and popular attractions in Nuwara Eliya, offering a perfect blend of relaxation and outdoor activities.",
      "Originally built during the British colonial period in 1873 under Sir William Gregory's governance, this artificial lake has become a favorite destination for both locals and tourists seeking a refreshing escape.",
      "Surrounded by misty hills and lush greenery, the lake is ideal for boat rides, cycling, and leisurely walks along its well-maintained pathways.",
      "Visitors can enjoy swan pedal boats, jet skiing, speed boat rides, and even horseback riding along the lakeside.",
      "The beautifully landscaped gardens and lakeside benches provide a peaceful setting for picnics and relaxation.",
      "Gregory Lake is a must-visit spot in Nuwara Eliya, whether you're looking for adventure, scenic beauty, or simply a place to unwind amidst the cool mountain breeze.",
    ],
    duration: "1 – 2 Hours",
    category: "Nature & Relaxation, Sightseeing, Outdoor Activities",
    attraction: "Boating, Cycling, Horse Riding, Scenic Views",
    climate: "Cool & Misty",
    highlights: [
      {
        key: "Boating & Water Sports:",
        value:
          "Enjoy swan paddle boats, speed boat rides, and jet skiing on the calm waters of Gregory Lake.",
      },
      {
        key: "Lakeside Walks & Cycling:",
        value:
          "Scenic walking trails and bicycle rentals allow visitors to explore the lake at a leisurely pace.",
      },
      {
        key: "Horseback Riding:",
        value:
          "Experience horseback riding along the lake, a popular activity for both kids and adults.",
      },
      {
        key: "Picnic & Relaxation:",
        value:
          "Well-maintained gardens and shaded benches make it a perfect spot for picnics and enjoying the cool weather.",
      },
      {
        key: "Breathtaking Views:",
        value:
          "The misty hills and reflections on the lake create a picture-perfect setting for photography enthusiasts.",
      },
    ],
    wear: [
      "Comfortable & Casual Clothing",
      "Light Jacket or Sweater (as the weather is cool)",
      "Sneakers or Comfortable Walking Shoes",
    ],
    bring: [
      "Camera or Smartphone for Photography",
      "Sunglasses & Hat for Sun Protection",
      "Light Snacks & Water",
      "Extra Clothes (if planning water activities)",
    ],
    faq: [
      {
        que: "Where is Gregory Lake located?",
        ans: "Gregory Lake is centrally located in Nuwara Eliya, along the Badulla Road, making it easily accessible from the main town.",
      },
      {
        que: "What is the best time to visit?",
        ans: "The lake is beautiful year-round, but the best time to visit is from December to April, when the weather is cool and clear. Early mornings and evenings provide the best scenic views.",
      },
      {
        que: "Are there any entrance fees?",
        ans: "Yes, there is a small entrance fee for visitors. Additional charges apply for boating, cycling, and other activities.",
      },
      {
        que: "Is Gregory Lake family-friendly?",
        ans: "Absolutely! With plenty of space for kids to run around, a variety of activities, and picnic areas, it's a great spot for families.",
      },
      {
        que: "Are food stalls available?",
        ans: "Yes, there are food stalls and small cafés around the lake offering local snacks, tea, and refreshments.",
      },
      {
        que: "Can I rent a bicycle or a boat?",
        ans: "Yes, bicycle rentals are available for a scenic ride around the lake, and various boat options (paddle boats, motorboats, etc.) can be rented for water activities.",
      },
    ],
  },
];
