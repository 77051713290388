import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";

function ReviewCardHome({ color, image, name, place, review, rating }) {
  const ratings = {
    0.5: [
      <Icon color="#EBB00F" key={1}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    1: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    1.5: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star_half
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    2: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    2.5: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star_half
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    3: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_outline
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    3.5: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star_half
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    4: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_outline
      </Icon>,
    ],
    4.5: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star_half
      </Icon>,
    ],
    5: [
      <Icon sx={{ color: "#EBB00F" }} key={1}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={2}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={3}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={4}>
        star
      </Icon>,
      <Icon sx={{ color: "#EBB00F" }} key={5}>
        star
      </Icon>,
    ],
  };

  return (
    <MKBox
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={"#F8F8F8"}
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        {image && <MKAvatar src={image} alt={name} size="xl" shadow="md" />}
      </Grid>
      <MKTypography
        variant="body2"
        my={2}
        sx={{
          lineHeight: 1.2,
          fontFamily: "Lexend",
          textAlign: "center",
          color: "#484848",
          fontWeight: 300,
        }}
      >
        {review}
      </MKTypography>
      <MKBox>
        <MKTypography
          variant="h4"
          color={
            color === "transparent" || color === "light" ? "text" : "white"
          }
          my={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .material-icons-round": {
              ml: -0.375,
            },
          }}
        >
          {ratings[rating]}
        </MKTypography>
        <MKTypography
          display="block"
          variant={image ? "button" : "h6"}
          fontWeight="bold"
          color="black"
          sx={{
            fontFamily: "Lexend",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {name}
        </MKTypography>
        <MKTypography
          display="block"
          variant={image ? "button" : "h6"}
          fontWeight="bold"
          sx={{
            fontFamily: "Lexend",
            fontWeight: 400,
            color: "#484848",
            textAlign: "center",
          }}
        >
          20-04-2024
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Setting default values for the props of DefaultReviewCard
ReviewCardHome.defaultProps = {
  color: "transparent",
  image: "",
};

// Typechecking props for the DefaultReviewCard
ReviewCardHome.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
};

export default ReviewCardHome;
