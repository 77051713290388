import Link from "@mui/material/Link";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { UilBars, UilPhoneAlt } from "@iconscout/react-unicons";
import { ReactComponent as Ausi } from "assets/icons/australia.svg";
import CustomSelect from "components/CustomSelect";
import "./styles.css";
import { BorderRight, Widgets } from "@mui/icons-material";

function NavBar() {
  const [scrolled, setScrolled] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const navItems = [
    { name: "Home" },
    {
      name: "Hotels",
      dropdown: ["Nuwara Eliya", "Kandy"],
    },
    { name: "Gallery" },
    { name: "About" },
    { name: "Contact Us" },
  ];

  const onItemClick = (item) => {
    console.log("ITEM", item);
    setMobileMenuOpen(false); // Close mobile menu when an item is clicked

    switch (item) {
      case "Home":
        navigate("/home");
        return;
      case "Hotel":
        navigate("/pages/acc_nuwaraeliya");
        return;
      case "Nuwara Eliya":
        navigate("/pages/acc_nuwaraeliya");
        return;
      case "Kandy":
        navigate("/pages/accomadation");
        return;
      case "Gallery":
        navigate("/pages/gallery");
        return;
      case "Experiences":
        navigate("/pages/experience");
        return;
      case "Contact Us":
        navigate("/pages/contact-us");
        return;
      case "About":
        navigate("/pages/about-us");
        return;
      default:
        navigate("/home");
        return;
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener when component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const countryList = [
    { label: "AUS", value: "aus" },
    { label: "AUS", value: "aus" },
    { label: "AUS", value: "aus" },
  ];

  return (
    <nav
      className={`navbar ${scrolled ? "scrolled" : ""} ${
        mobileMenuOpen ? "mobile-menu-open" : ""
      }`}
    >
      {/* Mobile Menu Button (visible only on mobile) */}
      <div className="mobile-menu-button" onClick={toggleMobileMenu}>
        <UilBars color={"white"} />
        <MKTypography
          component={Link}
          href="#"
          variant="button"
          color={"white"}
          fontWeight="regular"
        >
          MENU
        </MKTypography>
      </div>

      {/* Logo on the left (only visible on desktop) */}
      <div className="logo desktop-only">
        <UilBars color={"white"} />
        <MKTypography
          component={Link}
          href="#"
          variant="button"
          color={"white"}
          fontWeight="regular"
          onClick={(e) => onItemClick("Menu")}
        >
          Menu
        </MKTypography>
      </div>

      {/* Navigation items in the center */}
      <ul className={`nav-items ${scrolled ? "scrolled" : ""}`}>
        {navItems.map((item) => (
          <MKBox
            component="li"
            key={item.name}
            onMouseEnter={() => !mobileMenuOpen && setHoveredItem(item.name)}
            onMouseLeave={() => !mobileMenuOpen && setHoveredItem(null)}
          >
            <MKTypography
              component={Link}
              href="#"
              variant="button"
              sx={{ color: scrolled || mobileMenuOpen ? "black" : "white" }}
              fontWeight="regular"
              p={1}
              onClick={(e) => {
                e.preventDefault();
                if (mobileMenuOpen && item.dropdown) {
                  // Toggle sub-menu open state
                  setHoveredItem(hoveredItem === item.name ? null : item.name);
                } else {
                  onItemClick(item.name);
                }
              }}
            >
              {item.name}
            </MKTypography>

            {/* DESKTOP DROPDOWN */}
            {!mobileMenuOpen && item.dropdown && hoveredItem === item.name && (
              <MKBox className="dropdown-menu">
                {item.dropdown.map((subItem) => (
                  <MKTypography
                    key={subItem}
                    component={Link}
                    variant="button"
                    fontWeight="regular"
                    href="#"
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      textAlign: "center",
                    }}
                    onClick={() => onItemClick(subItem)}
                  >
                    {subItem}
                  </MKTypography>
                ))}
              </MKBox>
            )}

            {/* MOBILE DROPDOWN */}
            {mobileMenuOpen && item.dropdown && hoveredItem === item.name && (
              <MKBox className="mobile-submenu">
                {item.dropdown.map((subItem) => (
                  <MKTypography
                    key={subItem}
                    component={Link}
                    variant="button"
                    fontWeight="regular"
                    href="#"
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "1rem",
                      display: "block",
                    }}
                    onClick={() => onItemClick(subItem)}
                  >
                    {subItem}
                  </MKTypography>
                ))}
              </MKBox>
            )}
          </MKBox>
        ))}
      </ul>

      {/* Button on the right */}
      <div className={`cta ${scrolled ? "scrolled" : ""} desktop-only`}>
        <MKBox
          component="ul"
          display={{ xs: "none", lg: "flex" }}
          sx={{ listStyle: "none" }}
        >
          <MKButton
            sx={{ gap: 2, width: "50%" }}
            variant="contained"
            color={scrolled ? "black" : "white"}
          >
            <UilPhoneAlt />
            +94 522 234 256
          </MKButton>
        </MKBox>
      </div>
    </nav>
  );
}

export default NavBar;
