import React, { useRef, useState, useEffect } from "react";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ReviewCardHome from "components/ReviewCardHome";
import HeaderThree from "layouts/sections/page-sections/page-headers/components/HeaderThree";
import { ReactComponent as Feather } from "../../assets/icons/feather.svg";
import { ReactComponent as Cloud } from "../../assets/icons/cloud.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Sun } from "../../assets/icons/sun.svg";
import CheckIcon from "@mui/icons-material/Check";
import Footer from "components/Footer";
import foodImg from "assets/images/homePage/food.jpeg";
import { AccomadationPage, HomePage, ExperiencePage } from "constants/images";
import { UilArrowRight } from "@iconscout/react-unicons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Slider,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./styles.css";

import {
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

function GiftVouchers() {
  const navigate = useNavigate();

  const faq = [
    {
      title: "What are the check-in and check-out times at the hotel?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Do you provide guided tours of nearby attractions?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "What dining options are available at the hotel?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Do you offer transportation services between Colombo and Kandy?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Can I request a room with a specific view or location?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
    {
      title: "Are pets allowed at your locations?",
      answer:
        "Yes, we offer guided tours of the most popular attractions near both our Nuwara Eliya and Kandy hotels. Our knowledgeable guides will help you explore the rich cultural heritage, stunning landscapes, and hidden gems of the region. Tours can be customized to your interests and schedule. Please contact our front desk for more details or to make a reservation.",
    },
  ];

  const expCardData = [
    {
      title: "Lover's Leap Waterfall",
      image1: AccomadationPage.Exp1,
      description: "Approximately 5.5K.M From the Hotel",
      btnText: ["Half Day", "Scenery", "Biodiversity"],
    },
    {
      title: "Victoria Park",
      image1: AccomadationPage.Exp3,
      description: "Approximately 3.2K.M From the Hotel",
      btnText: ["Full Day", "Scenery", "Picnic Spot"],
    },
    {
      title: "Gregory Park",
      image1: AccomadationPage.Exp4,
      description: "Approximately 1.9K.M From the Hotel",
      btnText: ["Full Day", "Nature", "Photography"],
    },
  ];

  const expArray = [
    {
      icon: <Clock />,
      text1: "Duration:",
      text2: "Half Day",
    },
    {
      icon: <Cloud />,
      text1: "Category:",
      text2: "Scenery",
    },
    {
      icon: <Feather />,
      text1: "Attraction:",
      text2: "Picnic Spot",
    },
    {
      icon: <Sun />,
      text1: "Climate:",
      text2: "Cold",
    },
  ];

  const ExpCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          flex: 1,
          marginBottom: 5,
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "350px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          >
            <Grid
              sx={{
                border: "solid",
                borderWidth: 1,
                width: "95%",
                height: "95%",
                borderColor: "#ffffff",
                zIndex: 100,
              }}
            />
          </Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "25px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#017836",
                [breakpoints.down("sm")]: {
                  fontSize: "30px",
                },
              })}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "14px",
                fontFamily: "Lexend",
                lineHeight: "20px",
                fontWeight: 400,
                marginTop: 1,
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              {item?.description}
            </MKTypography>
          </Grid>

          <Grid
            container
            sx={{
              gap: 1,
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <MKButton
              circular
              size="small"
              variant="outlined"
              color="black"
              sx={{
                fontSize: "12px",
                padding: 0.5,
                gap: 0.5,
              }}
            >
              <Clock style={{ color: "red" }} width="15px" height="15px" />
              {item?.btnText[0]}
            </MKButton>
            <MKButton
              circular
              size="small"
              variant="outlined"
              color="black"
              sx={{
                fontSize: "12px",
                padding: 0.5,
                gap: 0.5,
              }}
            >
              <Cloud color="#484848" width="15px" height="15px" />
              {item?.btnText[1]}
            </MKButton>
            <MKButton
              circular
              size="small"
              variant="outlined"
              color="black"
              sx={{
                fontSize: "12px",
                padding: 0.5,
                gap: 0.5,
              }}
            >
              <Feather color="#484848" width="15px" height="15px" />
              {item?.btnText[2]}
            </MKButton>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const Checklist = () => {
    const wearItems = [
      "Comfortable Hiking Shoes",
      "Layered Clothing",
      "Hat and Sunglasses",
      "Light, Breathable Clothes",
      "Rain Jacket or Poncho",
    ];

    const bringItems = [
      "Water and Snacks",
      "Camera or Smartphone",
      "Backpack",
      "Sunscreen and Insect Repellent",
      "Rain Jacket or Poncho",
    ];

    return (
      <MKBox sx={{ maxWidth: "900px", margin: "auto", padding: "2rem 0" }}>
        <Grid container spacing={2}>
          {/* What to Wear */}
          <Grid item xs={12} md={5}>
            <MKTypography variant="h6" fontWeight="bold">
              What to Wear
            </MKTypography>
            {wearItems.map((item, index) => (
              <MKBox
                key={index}
                display="flex"
                alignItems="center"
                gap={1}
                mt={1}
              >
                <CheckIcon sx={{ color: "green" }} />
                <MKTypography variant="body1">{item}</MKTypography>
              </MKBox>
            ))}
          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={2} display="flex" justifyContent="center">
            <MKBox
              sx={{ width: "1px", height: "100%", backgroundColor: "#ccc" }}
            />
          </Grid>

          {/* What to Bring */}
          <Grid item xs={12} md={5}>
            <MKTypography variant="h6" fontWeight="bold">
              What to Bring
            </MKTypography>
            {bringItems.map((item, index) => (
              <MKBox
                key={index}
                display="flex"
                alignItems="center"
                gap={1}
                mt={1}
              >
                <CheckIcon sx={{ color: "green" }} />
                <MKTypography variant="body1">{item}</MKTypography>
              </MKBox>
            ))}
          </Grid>
        </Grid>
      </MKBox>
    );
  };

  const VoucherFilter = () => {
    const [priceRange, setPriceRange] = useState([5000, 50000]);

    const handlePriceChange = (event, newValue) => {
      setPriceRange(newValue);
    };

    const categories = [
      { name: "Heaven Seven Nuwaraeliya", count: 2 },
      { name: "Heaven Seven Kandy", count: 2 },
      { name: "Heaven Seven Christmas Feast", count: 1 },
      { name: "Heaven Seven Year End Combo", count: 1 },
    ];

    return (
      <Box p={3}>
        {/* Search Bar */}
        <Box mb={3}>
          <TextField
            variant="outlined"
            placeholder="Search by Voucher"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ borderRadius: 5 }}
          />
        </Box>

        {/* Price Range */}
        <Box p={3} borderRadius={5} mb={3}>
          <Typography variant="h5" fontWeight="bold">
            Price Range
          </Typography>
          <Typography variant="body2" gutterBottom>
            Average Voucher Price: Rs 10000
          </Typography>
          <Slider
            value={priceRange}
            onChange={() => {
              handlePriceChange();
            }}
            valueLabelDisplay="off"
            min={5000}
            max={50000}
            sx={{ mb: 2 }}
          />
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography>Rs.{priceRange[0]}</Typography>
            <Typography>Rs.{priceRange[1]}</Typography>
          </Box>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: 2,
            }}
          >
            Filter by Price
          </Button>
        </Box>

        {/* Category */}
        <Box p={3} borderRadius={5}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Category
          </Typography>
          <List>
            {categories.map((category, index) => (
              <Box key={index}>
                <ListItem disablePadding>
                  <ListItemText
                    primary={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography>{category.name}</Typography>
                        <Typography>({category.count})</Typography>
                      </Box>
                    }
                  />
                </ListItem>
                {index !== categories.length - 1 && <Divider />}
              </Box>
            ))}
          </List>
        </Box>
      </Box>
    );
  };

  const CustomVoucherFilter = () => {
    const [priceRange, setPriceRange] = useState([5000, 50000]);

    const handlePriceChange = (event, newValue) => {
      setPriceRange(newValue);
    };

    const categories = [
      { name: "Heaven Seven Nuwaraeliya", count: 2 },
      { name: "Heaven Seven Kandy", count: 2 },
      { name: "Heaven Seven Christmas Feast", count: 1 },
      { name: "Heaven Seven Year End Combo", count: 1 },
    ];
    return (
      <Grid>
        <Box mb={3}>
          <TextField
            variant="outlined"
            placeholder="Search by Voucher"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ borderRadius: 5 }}
          />
        </Box>
        <Box p={3} borderRadius={5} mb={3}>
          <Typography variant="h5" fontWeight="bold">
            Price Range
          </Typography>
          <Typography variant="body2" gutterBottom>
            Average Voucher Price: Rs 10000
          </Typography>
          <Slider
            value={priceRange}
            onChange={() => {
              handlePriceChange();
            }}
            valueLabelDisplay="off"
            min={5000}
            max={50000}
            sx={{ mb: 2, color: "black" }}
          />
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography>Rs.{priceRange[0]}</Typography>
            <Typography>Rs.{priceRange[1]}</Typography>
          </Box>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: 2,
            }}
          >
            Filter by Price
          </Button>
        </Box>
      </Grid>
    );
  };

  return (
    <div>
      <div style={{ overflow: "hidden", backgroundColor: "white" }}>
        {/* Explore our travel solutions */}
        <HeaderThree
          title="Gift Vouchers"
          backgroundImage={HomePage.Home_Header}
          subHead="sub"
          headerFontSize={55}
        />
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: 6,
          }}
          mt={8}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={11}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Gift of Choice
                </MKTypography>
              </div>
            </Grid>
          </Container>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
            lg={10}
          >
            {/* <VoucherFilter /> */}
            <CustomVoucherFilter />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ marginBottom: 8, display: "flex", justifyContent: "center" }}
        ></Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: 7,
            position: "relative", // Required for pseudo-element positioning
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={12}
              flexDirection="column"
              justifyContent="left"
              sx={{
                textAlign: "left",
                marginBottom: "20px",
                marginTop: 2,
              }}
            >
              <div className="text-with-lines-2">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Highlights
                </MKTypography>
              </div>
            </Grid>
          </Container>

          <Grid container spacing={3} lg={10}>
            <MKBox component="ul" pl={2}>
              <MKTypography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "20px",
                  color: "#000000",
                  marginBottom: "30px",
                }}
                component="li"
                variant="body1"
              >
                <strong>Misty Grasslands and Forests:</strong> The park is a mix
                of open grasslands and dense cloud forests. The misty
                atmosphere, especially in the early morning, adds a mystical
                charm.
              </MKTypography>
              <MKTypography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "20px",
                  color: "#000000",
                  marginBottom: "30px",
                }}
                component="li"
                variant="body1"
              >
                <strong>World’s End:</strong> A sheer cliff with a drop of
                around 870 meters, offering spectacular views of the surrounding
                hills and, on clear days, even the southern coastline.
              </MKTypography>
              <MKTypography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "20px",
                  color: "#000000",
                  marginBottom: "30px",
                }}
                component="li"
                variant="body1"
              >
                <strong>Circular Trail:</strong> A popular 9.5 km loop that
                takes about 3–4 hours to complete. The trail is relatively easy
                but involves some steep climbs and rocky paths.
              </MKTypography>
              <MKTypography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "20px",
                  color: "#000000",
                  marginBottom: "30px",
                }}
                component="li"
                variant="body1"
              >
                <strong>Mammals:</strong> Sambar deer are frequently spotted,
                and with luck, you might even see a leopard or wild boar.
              </MKTypography>
              <MKTypography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "20px",
                  color: "#000000",
                  marginBottom: "30px",
                }}
                component="li"
                variant="body1"
              >
                <strong>Best Time to Visit:</strong> Early morning (before 10
                AM) to avoid the mist obscuring the views, especially at World’s
                End.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
        <img
          src={HomePage?.Map}
          alt="My SVG"
          style={{ width: "90%", height: "100%" }}
        />
        {/*Explore*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            zIndex: 2,
          }}
        >
          <Grid
            container
            spacing={2}
            lg={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            <Checklist />
          </Grid>
        </Grid>

        {/*FAQ*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            position: "relative",
            paddingBottom: 5,
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  FAQ
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Find quick answers to common questions in our FAQ section. We've
                compiled essential information to make your experience with us
                smooth and hassle-free.
              </MKTypography>
            </Grid>
          </Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            alignItems="center"
            sx={{ width: "70%" }}
          >
            <Divider
              variant="middle"
              sx={{
                height: 2,
                width: "100%",
                backgroundColor: "#C9C5BA",
              }}
            />
            <Grid container display={"flex"} flexDirection="column">
              {faq.map((item, index) => (
                <Accordion key={index} sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="black" />}
                    aria-controls="panel1-content"
                    id={`panel1-header-${index}`}
                    sx={{ boxShadow: "none" }}
                  >
                    {item?.title}
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#F4F4F4" }}>
                    <MKTypography sx={{ fontSize: 13, fontFamily: "Lexend" }}>
                      {item?.answer}
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/*EXP*/}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            marginTop: 7,
            "&::before": {
              content: '""',
              position: "absolute",
              top: "10%",
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={11}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "50px",
                    fontWeight: 400,
                  })}
                >
                  More Experiences You Might Like
                </MKTypography>
              </div>
            </Grid>
          </Container>
          <Grid
            container
            spacing={2}
            lg={11.5}
            sx={{ justifyContent: "center", display: "flex" }}
          >
            {expCardData?.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <ExpCard item={item} index={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default GiftVouchers;
