import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import countries from "country-list"; // Import country list

// Format country data with flag emojis
const countryOptions = countries.getData().map((country) => ({
  code: country.code,
  name: country.name,
  label: `${country.name} (${country.code})`,
  flag: `https://flagcdn.com/w40/${country.code.toLowerCase()}.png`,
}));

const CountrySelect = ({ value, onChange }) => {
  return (
    <Autocomplete
      options={countryOptions}
      getOptionLabel={(option) => option?.label || ""}
      renderOption={(props, option) => (
        <li {...props}>
          <img
            src={option.flag}
            alt=""
            style={{ width: 20, marginRight: 10 }}
          />
          {option.label}
        </li>
      )}
      value={value ? countryOptions.find(option => option.name === value) : null}
      onChange={(event, newValue) => {
        onChange({
          target: {
            name: "country",
            value: newValue ? newValue.name : ""
          }
        });
      }}
      renderInput={(params) => <TextField {...params}  required label="Country of Origin" variant="standard"/>}
    />
  );
};

export default CountrySelect;