import React from "react";
import { useNavigate } from "react-router-dom";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import NavBar from "components/NavBar";
import Footer from "components/Footer";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ThankYou() {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#FEFDF5", minHeight: "100vh" }}>
      <NavBar />
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
            textAlign: "center",
          }}
        >
          <Grid item xs={12} md={8}>
            <MKBox
              sx={{
                backgroundColor: "#fff",
                padding: "4rem 2rem",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CheckCircleIcon 
                sx={{ 
                  fontSize: 80, 
                  color: "#017836",
                  marginBottom: 2 
                }} 
              />
              <MKTypography
                variant="h1"
                sx={{
                  fontFamily: "Italiana",
                  fontSize: { xs: "36px", md: "48px" },
                  marginBottom: 2,
                }}
              >
                Thank You for Contacting Us!
              </MKTypography>
              <MKTypography
                variant="body1"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  color: "#666",
                  marginBottom: 4,
                }}
              >
                We have received your message and will get back to you as soon as possible.
              </MKTypography>
              <MKButton
                variant="contained"
                onClick={() => navigate("/")}
                sx={{
                  borderRadius: "20px",
                  padding: "0.8rem 4rem",
                  backgroundColor: "#017836",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#015826",
                  },
                }}
              >
                Return to Home
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

export default ThankYou; 