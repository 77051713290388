import React from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import View from "layouts/sections/components/View";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import { ReactComponent as LiBeach } from "../../assets/icons/li_beach.svg";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderTwo";
import HeaderThree from "layouts/sections/page-sections/page-headers/components/HeaderThree";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "components/Footer";
import MKBox from "components/MKBox";
import {
  UilPlaneDeparture,
  UilTicket,
  UilUtensils,
  UilBedDouble,
  UilSearch,
  UilArrowLeft,
  UilArrowRight,
} from "@iconscout/react-unicons";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import coconutHllImg from "assets/images/homePage/coconut_hill.jpeg";
import sigiriyaImg from "assets/images/homePage/sigiriya.jpeg";
import soulmateImg from "assets/images/homePage/soulmate.jpeg";
import archImg from "assets/images/homePage/9arch.jpeg";
import buddha2Img from "assets/images/homePage/buddha2.jpeg";
import hinduImg from "assets/images/homePage/hindu.jpeg";
import galleImg from "assets/images/homePage/galle.jpeg";
import firBall from "assets/images/homePage/fireball.jpeg";
import NavBar from "components/NavBar";
import NavBarTwo from "components/NavBarTwo";
import { BlogsPage } from "constants/images";
import { BlogArticlePage } from "constants/images";
import {
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Divider,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./styles.css";

function BlogArticle() {
  const navigate = useNavigate();
  const travelPcgs = [
    {
      title: "Hidden Gems of Sri Lanka: Off-the-Beaten-Path Destinations",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_4,
    },
    {
      title: "Adventure Travel in Sri Lanka: Hiking, Surfing, and Beyond",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_5,
    },
    {
      title: "Warm Smiles and Open Arms: Experiencing Sri Lankan Hospitality",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_6,
    },
  ];
  return (
    <div style={{ backgroundColor: "#FEFDF5" }}>
      <div style={{ overflowX: "hidden" }}>
        <HeaderThree
          title="Unwind on the Golden Beaches of Sri Lanka’s South Coast"
          backgroundImage={BlogArticlePage.Header}
          subHead="sub"
          headerFontSize={55}
        />
        <Grid
          container
          justifyContent="center" // Center the main grid
          sx={{
            marginBottom: "40px",
            width: "100%", // Ensure full width of the container
          }}
        >
          <Grid
            sx={{
              display: "flex",
              marginBottom: "40px",
              backgroundColor: "#FEFDF5",
              width: "80%",
              marginTop: 7,
            }}
          >
            <Grid container>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "90%",
                  margin: "0 auto",
                  padding: 2,
                  flexDirection: "column",
                }}
              >
                <MKTypography
                  color="black"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    lineHeight: "30px",
                  }}
                >
                  Sri Lanka’s South Coast is a tropical paradise, perfect for
                  travelers looking to relax and enjoy the sun, sand, and sea.
                  Stretching from Hikkaduwa to Tangalle, this region boasts some
                  of the country’s most picturesque and pristine beaches,
                  offering a variety of activities and experiences for all types
                  of visitors. Unawatuna, a popular beach destination, is known
                  for its calm, swimmable waters and vibrant beachfront
                  restaurants. Here, visitors can enjoy snorkeling, diving, and
                  even yoga by the beach. Just a short distance away, the
                  historic city of Galle beckons with its iconic Dutch Fort, a
                  UNESCO World Heritage site, where you can take a leisurely
                  stroll through cobbled streets and visit charming cafes and
                  boutiques.
                </MKTypography>
                <MKTypography
                  color="black"
                  fontWeight="bold"
                  mt={5}
                  mb={4}
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Lexend, serif",
                  }}
                >
                  1. Hikkaduwa
                </MKTypography>
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MKBox
                    component="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    src={BlogArticlePage.Blog_Article_1}
                    alt={"bgImage"}
                  />
                </MKBox>
                <MKTypography
                  color="black"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    lineHeight: "30px",
                  }}
                  mt={4}
                >
                  Hikkaduwa Beach, located on Sri Lanka’s southern coast, is a
                  vibrant paradise that attracts beach lovers, surfers, and
                  nature enthusiasts from around the world. Known for its
                  pristine sands, swaying coconut palms, and brilliant blue
                  waters, this beach offers a quintessential tropical
                  experience. Hikkaduwa is also famous for its coral reefs,
                  which make it one of the best spots for snorkeling and diving
                  in Sri Lanka. The shallow waters near the shore allow visitors
                  to observe colorful coral formations and a variety of marine
                  life, including vibrant fish, sea turtles, and even rays,
                  making underwater exploration a must-do.
                </MKTypography>
                <MKTypography
                  color="black"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    lineHeight: "30px",
                  }}
                  mt={4}
                >
                  For surf enthusiasts, Hikkaduwa provides some of the best
                  waves in the country, especially from November to April when
                  the waters are ideal. The beach area is lined with local
                  vendors, cozy beachside cafés, and restaurants serving fresh
                  seafood and Sri Lankan delicacies, giving visitors a taste of
                  island life. Whether you’re there for water sports, relaxing
                  by the waves, or exploring the nightlife, Hikkaduwa offers a
                  dynamic mix of activities. With its stunning natural beauty
                  and welcoming atmosphere, Hikkaduwa Beach is a gem that
                  captures the essence of Sri Lanka’s coastal allure.
                </MKTypography>

                <MKTypography
                  color="black"
                  fontWeight="bold"
                  mt={5}
                  mb={4}
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Lexend, serif",
                  }}
                >
                  2. Unawatuna
                </MKTypography>
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MKBox
                    component="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    src={BlogArticlePage.Blog_Article_2}
                    alt={"bgImage"}
                  />
                </MKBox>
                <MKTypography
                  color="black"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Lexend, sans-serif",
                    lineHeight: "30px",
                  }}
                  mt={4}
                >
                  Unawatuna Beach, situated near the historic city of Galle on
                  Sri Lanka’s southern coast, is a breathtaking destination
                  renowned for its curved bay, golden sands, and tranquil
                  turquoise waters. This idyllic beach offers a relaxed vibe,
                  making it a favorite among travelers seeking a peaceful
                  escape. Sheltered by a coral reef, Unawatuna’s calm waters
                  make it ideal for swimming, snorkeling, and diving, where
                  visitors can explore vibrant coral gardens and encounter a
                  variety of marine life, including tropical fish and occasional
                  sea turtles.
                </MKTypography>
                <MKTypography
                  color="black"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Lexend, sans-serif",
                    lineHeight: "30px",
                  }}
                  mt={4}
                >
                  The palm-lined beach is surrounded by a range of cozy
                  guesthouses, charming beachside cafés, and seafood restaurants
                  where fresh catches are prepared daily, giving a true taste of
                  coastal Sri Lankan flavors. For those interested in local
                  culture, the iconic Japanese Peace Pagoda nearby provides
                  panoramic views of the coastline, and the Rumassala Hill
                  offers a scenic hike with legends tied to the ancient Ramayana
                  epic. Unawatuna also has a vibrant yet laid-back nightlife,
                  with beach bars and live music. Combining natural beauty,
                  cultural intrigue, and a friendly atmosphere, Unawatuna Beach
                  is a captivating spot that invites relaxation and discovery.
                </MKTypography>
                {/* <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={4}
              >
                <MKButton
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "20px",
                  }}
                  size="small"
                  circular
                  variant="outlined"
                  color="black"
                >
                  <UilArrowLeft style={{ marginRight: 5 }} />
                  Back to Blogs
                </MKButton>
                <MKButton
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  size="small"
                  circular
                  variant="contained"
                  color="black"
                >
                  Next Article
                  <UilArrowRight style={{ marginLeft: 5 }} />
                </MKButton>
              </MKBox> */}
              </Box>
            </Grid>
          </Grid>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <MKTypography
                variant="h1"
                color="black"
                className="text-with-lines"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  fontFamily: "Italiana, serif",
                  fontSize: "48px",
                  fontWeight: 400,
                })}
              >
                Articles You Might Like
              </MKTypography>
            </Grid>
          </Container>
          <Grid container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "90%",
                margin: "0 auto",
                padding: 3,
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                {travelPcgs.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4} // Adjusted for a 3-column layout
                    sx={{ flexShrink: 0 }}
                  >
                    <Card
                      sx={{
                        height: "416px",
                        boxShadow: "none",
                        backgroundColor: "#FEFDF5",
                        borderWidth: 1,
                        borderColor: "#C9C5BA",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardActionArea
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height={"250px"}
                          image={item?.img}
                          sx={{
                            objectFit: "cover",
                            width: "100%",
                            margin: 0,
                            padding: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          alt="SVG Image"
                        />
                        <CardContent sx={{ flex: 1, padding: 1 }}>
                          <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            lg={12}
                            sx={{
                              justifyContent: "space-between",
                            }}
                          >
                            <MKTypography
                              variant="h6"
                              fontWeight="regular"
                              sx={{
                                maxWidth: "100%",
                                lineHeight: "30px",
                                color: "#017836",
                              }}
                            >
                              Travel Tips
                            </MKTypography>
                            <Grid>
                              <MKTypography
                                //color="black"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  fontFamily: "Lexend, sans-serif",
                                  lineHeight: "30px",
                                  color: "#484848",
                                }}
                              >
                                Oct 17 2024
                              </MKTypography>
                            </Grid>
                          </Grid>
                          <Divider
                            variant="middle"
                            sx={{
                              backgroundColor: "##C9C5BA",
                              height: "2px",
                              marginY: 1,
                            }}
                          />

                          <Grid container alignItems="center">
                            <Typography
                              sx={{
                                fontFamily: "Lexend, serif",
                                fontSize: "24px",
                                fontWeight: 400,
                                lineHeight: "100%",
                                color: "#484848",
                              }}
                              variant="h5"
                            >
                              {item?.title}
                            </Typography>
                          </Grid>
                          <MKTypography
                            sx={{
                              fontWeight: "500",
                              textDecoration: "underline",
                              color: "#000000",
                            }}
                            onClick={() => navigate("/pages/blog-article")}
                            mt={2}
                            variant="subtitle2"
                          >
                            Read More
                            <ArrowForwardIcon
                              sx={{
                                fontSize: "8px", // Icon size
                                marginLeft: "4px", // Gap between text and icon
                                textDecoration: "underline",
                                color: "#000000",
                              }}
                            />
                          </MKTypography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <MKButton
            circular
            variant="outlined"
            color="black"
            sx={{
              marginTop: 5,
            }}
          >
            Load More
          </MKButton>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default BlogArticle;
