import React, { useState, useRef } from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderTwo";
import { ReactComponent as VectorIcon } from "../../../assets/icons/Vector.svg";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import { Card, CardMedia, CardContent, Box, Typography } from "@mui/material";
import { GalleryPage } from "constants/images";
import "./styles.css";
import Slider from "react-slick";
import { UilArrowRight } from "@iconscout/react-unicons";
import { AboutUsPage, HomePage } from "constants/images";
import NavBar from "components/NavBar";

function AboutUs() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Nuwaraeliya Hotel");
  const sliderRef = useRef(null);
  const tabs = ["Nuwaraeliya Hotel", "Kandy Hotel"];

  const [currentSlide, setCurrentSlide] = useState(0);

  const NextArrow = ({ sliderRef }) => (
    <div
      className="custom-arrow next-arrow"
      onClick={() => {
        setActiveTab("Kandy Hotel");
        sliderRef.current.slickNext();
      }}
    >
      &gt; {/* Use &gt; for > */}
    </div>
  );

  const PrevArrow = ({ sliderRef }) => (
    <div
      className="custom-arrow prev-arrow"
      onClick={() => {
        setActiveTab("Nuwaraeliya Hotel");
        sliderRef.current.slickPrev();
      }}
    >
      &lt; {/* Use &lt; for < */}
    </div>
  );

  const OffersCard = ({ item, index }) => {
    const isEven = index % 2 === 0;

    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          flex: 1,
          marginBottom: 5,
          minHeight: "520px",
        })}
      >
        <Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              backgroundImage: `url(${item?.image1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "350px",
              zIndex: 100,
              [breakpoints.down("sm")]: {
                width: "100%",
                height: "350px",
              },
            })}
          ></Grid>
        </Grid>

        <CardContent
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
            paddingTop: 0,
            [breakpoints.down("sm")]: {
              marginLeft: 0,
              alignItems: "center",
              textAlign: "center",
            },
          })}
        >
          <Grid lg={12}>
            <MKTypography
              sx={({ breakpoints }) => ({
                fontSize: "25px",
                fontFamily: "Italiana",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#484848",
                [breakpoints.down("sm")]: {
                  fontSize: "30px",
                },
              })}
            >
              {item?.title}
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "14px",
                fontFamily: "Lexend",
                lineHeight: "15px",
                fontWeight: 500,
                marginTop: 1,
                [breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              })}
            >
              Available on
            </MKTypography>
            <MKTypography
              color="black"
              sx={({ breakpoints }) => ({
                fontSize: "12px",
                fontFamily: "Lexend",
                lineHeight: "15px",
                fontWeight: 400,
                [breakpoints.down("sm")]: {
                  fontSize: "12px",
                },
              })}
            >
              {item?.description}
            </MKTypography>
          </Grid>

          <Grid
            container
            sx={{
              gap: 1,
              display: "flex",
              marginTop: 1,
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <MKTypography
              onClick={() => navigate("/pages/contact-us")}
              sx={{
                fontSize: 13,
                fontFamily: "Lexend",
                color: "#017836",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
            >
              Contact us for inquiries
              <UilArrowRight />
            </MKTypography>
            <MKTypography
              sx={{
                fontSize: 12,
                fontFamily: "Lexend",
                color: "#484848",
                alignItems: "center",
                display: "flex",
              }}
            >
              {item?.date}
            </MKTypography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const CustomCarousel = ({ hotels }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      // nextArrow: <CustomArrow direction="next" />,
      // prevArrow: <CustomArrow direction="prev" />,
      afterChange: (current) => setCurrentSlide(current), // Update the slide count
    };

    return (
      <Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Explore
              </MKTypography>
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "40px",
                    fontWeight: 400,
                  })}
                >
                  Our Amenities
                </MKTypography>
              </div>
            </Grid>
          </Container>
        </Grid>
        <Box sx={{ position: "relative", width: "100%", padding: "0 20px" }}>
          <Slider {...settings}>
            {hotels.map((hotel, index) => (
              <Box key={index} sx={{ padding: "0 10px" }}>
                <Box
                  sx={{
                    borderRadius: "0px",
                    overflow: "hidden",
                    textAlign: "left",
                  }}
                >
                  <img
                    src={hotel.image1}
                    alt={hotel.title}
                    style={{ width: "100%", height: "250px" }}
                  />
                  <Typography variant="h6" color="green" mt={2}>
                    {hotel.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    mb={2}
                    mt={2}
                  >
                    {hotel.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>

          {/* Vertical Navigation and Count */}
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <Typography variant="body1" color="black">
              {`0${currentSlide + 1} / 0${hotels.length}`}
            </Typography> */}
          </Box>
        </Box>
      </Grid>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "ease-in-out",
    centerMode: true,
    centerPadding: "0", // Adjust for visible next/prev slides
    arrows: true,
    nextArrow:
      activeTab === "Nuwaraeliya Hotel" ? (
        <NextArrow
          sliderRef={sliderRef}
          onClick={() => setActiveTab("Kandy Hotel")}
        />
      ) : null,
    prevArrow:
      activeTab === "Kandy Hotel" ? (
        <PrevArrow
          sliderRef={sliderRef}
          onClick={() => setActiveTab("Nuwaraeliya Hotel")}
        />
      ) : null,
  };

  const hotels = [
    {
      title: `"Where the Mist Meets Luxury"`,
      description: [
        "Situated in the heart of Nuwara Eliya, offering breathtaking views of misty hills and lush tea plantations.",
        "Thoughtfully designed rooms blending modern comfort with timeless elegance.",
        "Close to Gregory Lake, Hakgala Botanical Garden, and renowned tea estates for easy access to local attractions.",
        "Enjoy the cool climate of Nuwara Eliya with cozy spaces and tranquil surroundings.",
        "Attentive staff committed to delivering a warm, personalized, and memorable experience.",
      ],
      image: AboutUsPage.Nuwara_Eliya_Hotel, // Replace with the actual image path
    },
    {
      title: `"Where Heritage Meets Comfort"`,
      description: [
        "Located in the cultural heart of Kandy, offering stunning views of rolling hills and the vibrant cityscape.",
        "Elegant rooms designed with a perfect blend of modern amenities and classic charm.",
        "Close to the Temple of the Tooth Relic, Kandy Lake, and the Royal Botanical Gardens for effortless exploration.",
        "Enjoy Kandy’s unique climate with relaxing spaces and serene surroundings.",
        "A dedicated team providing personalized service to create unforgettable stays.",
      ],
      image: AboutUsPage.Kandy_Hotel, // Replace with the actual image path
    },
  ];

  const offerCardData = [
    {
      title: "Romantic Getaway Package",
      image1: HomePage.Home_Offer1,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Family Vacation Bundle",
      image1: HomePage.Home_Offer2,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Honeymoon Special",
      image1: HomePage.Home_Offer3,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
    {
      title: "Corporate & Business Package",
      image1: HomePage.Home_Offer4,
      description: "Heaven Seven Kandy and Heaven Seven Nuwaraeliya",
      date: "Valid Till 31 Dec",
    },
  ];

  const amenetiesCardData = [
    {
      title: "Scenic Mountain Views",
      image1: AboutUsPage.Ameneties_2,
      description:
        "Situated in the heart of Srilanka, offering panoramic views of lush hills and tea plantations.",
    },
    {
      title: "Comfort & Elegance",
      image1: AboutUsPage.Ameneties_3,
      description:
        "Rooms are designed for comfort, combining modern amenities with a touch of elegance.",
    },
    {
      title: "Tour Assistance",
      image1: AboutUsPage.Ameneties_4,
      description:
        "Personalized recommendations and arrangements for exploring nearby attractions",
    },
  ];

  const CustomCard = ({ item, index }) => {
    const titleParts = item.title.split(" Meets ");
    const firstLine = titleParts[0]; // Part before "Meets"
    const secondLine = titleParts[1] ? `Meets ${titleParts[1]}` : "";
    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "row",
          borderRadius: "15px",
          //boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          justifyContent: "center",
          width: "100%",
          [breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
          },
        })}
      >
        <CardMedia
          component="img"
          alt="Image"
          image={item.image}
          title="title"
          sx={({ breakpoints }) => ({
            borderRadius: 0,
            width: "50%",
            minHeight: "340px",
            margin: 0,
            [breakpoints.down("sm")]: {
              width: "100%",
              height: "auto",
            },
          })}
        />

        <CardContent
          sx={({ breakpoints }) => ({
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            backgroundColor: "#F1F1F1",
            flex: 1,
            minHeight: "340px",
            [breakpoints.down("sm")]: {
              height: "auto",
            },
          })}
        >
          <Grid container lg={12}>
            <MKTypography
              variant="h1"
              color="black"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("xl")]: {
                  fontSize: size["2xl"],
                },
                fontFamily: "Italiana",
                fontSize: "34px",
                fontWeight: 500,
                marginBottom: "26px",
                width: "100%",
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <span style={{ textAlign: "left" }}>{firstLine}</span>
                <span style={{ textAlign: "right" }}>{secondLine}</span>
              </Box>
            </MKTypography>
            {item?.description.map((desc) => (
              <Grid
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                  marginBottom: "15px",
                }}
              >
                <VectorIcon
                  style={{
                    minWidth: "15px",
                    minHeight: "15px",
                    maxHeight: "15px",
                    maxWidth: "15px",
                  }}
                />
                <MKTypography
                  variant="h6"
                  fontWeight="regular"
                  fontFamily="Lexend"
                  fontSize="14px"
                  color="black"
                  sx={{
                    textAlign: "left",
                    maxWidth: "100%",
                    lineHeight: 1.1,
                  }}
                >
                  {desc}
                </MKTypography>
              </Grid>
            ))}

            <MKButton
              variant="outlined"
              color="black"
              onClick={() => {
                if (index === 0) {
                  navigate("/pages/acc_nuwaraeliya");
                } else {
                  navigate("/pages/accomadation");
                }
              }}
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 2,
                marginLeft: 3,
              }}
            >
              See Our Rooms
            </MKButton>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* The NavBar component should be placed at the top level of your main layout */}
      <NavBar /> {/* Include NavBar at the top */}
      <div style={{ overflow: "hidden" }}>
        {/* <HeaderOne bgImage={AccomadationPage.Header} /> */}
        {/* <NavBar /> */}
        <div>
          <HeaderTwo
            title="About Us"
            description_="Dive into the story of Heaven Seven Hotels where comfort meets elegance, 
            and every stay is crafted to leave lasting memories. Explore our rich heritage, exceptional services, 
            and the stunning locations that make us unique."
            backgroundImage={GalleryPage.Header}
          />
        </div>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "30px",
            marginTop: 7,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Our Nuwaraeliya Hotel
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Explore the serene beauty of Heaven Seven Nuwaraeliya through
                our gallery, featuring stunning views of misty hills, cozy
                interiors, and tranquil surroundings. Discover where luxury
                meets nature's charm.
              </MKTypography>
            </Grid>
          </Container>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "30px",
            marginTop: 7,
          }}
        >
          <div className="tabs-about">
            {tabs.map((tab, index) => (
              <div
                key={tab}
                ref={sliderRef}
                className={`tab-about ${activeTab === tab ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(tab);
                  if (tab === "Nuwaraeliya Hotel") {
                    sliderRef.current.slickPrev();
                  } else {
                    sliderRef.current.slickNext();
                  }

                  console.log("");

                  // sliderRef.current && sliderRef.current.slickGoTo(index);
                }}
              >
                {tab}
              </div>
            ))}
          </div>
        </Grid>

        <Grid container sx={{ marginBottom: 8 }}>
          <div className="slider-container">
            <Slider {...settings} ref={sliderRef}>
              {hotels.map((item, index) => (
                <CustomCard item={item} index={index} />
              ))}
            </Slider>
          </div>
        </Grid>
        <Grid
          spacing={2}
          lg={12}
          sx={{
            marginX: 0,
            paddingX: 0,
            flexDirection: "row",
            display: "flex",
            "@media (max-width:768px)": {
              display: "none",
            },
          }}
        >
          <Grid sx={{ width: "25%" }}>
            <img
              src={AboutUsPage.Ameneties_1}
              alt="Gallery"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid
            sx={{
              width: "70%",
            }}
          >
            <CustomCarousel hotels={amenetiesCardData} />
          </Grid>
        </Grid>
        {/*Offers */}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: 7,
            position: "relative",
            paddingBottom: 20,
            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${HomePage.OfferBg})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              opacity: 1,
              zIndex: 0,
            },
            zIndex: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px", marginTop: 2 }}
            >
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                You Might Also Like
              </MKTypography>
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Heaven Seven Offers
                </MKTypography>
              </div>
            </Grid>
          </Container>
          <Grid container spacing={2} lg={11.5} sx={{}}>
            {offerCardData?.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <OffersCard item={item} index={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
