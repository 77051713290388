import React, { useState } from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import View from "layouts/sections/components/View";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import { ReactComponent as LiBeach } from "../../assets/icons/li_beach.svg";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderTwo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import {
  UilPlaneDeparture,
  UilTicket,
  UilUtensils,
  UilBedDouble,
  UilSearch,
} from "@iconscout/react-unicons";
import { TextField, InputAdornment } from "@mui/material";
import NavBar from "components/NavBar";
import { BlogsPage } from "constants/images";
import {
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Divider,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { GalleryPage } from "constants/images";
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";
import "./styles.css";
import MKBox from "components/MKBox";
import Slider from "react-slick";

function Gallery() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Overall");

  const tabs = [
    "Overall",
    "Interior",
    "Exterior",
    "Facilities",
    "Dining",
    "Events",
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      &gt; {/* Use &gt; for > */}
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      &lt; {/* Use &lt; for < */}
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10%", // Adjust for visible next/prev slides
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const kandyGal = [
    GalleryPage?.Kandy_Gall1,
    GalleryPage?.Kandy_Gall2,
    GalleryPage?.Kandy_Gall3,
  ];

  const nuwaraEliyaGal = [
    GalleryPage?.NuwaraEliya_Gall1,
    GalleryPage?.NuwaraEliya_Gall2,
    GalleryPage?.NuwaraEliya_Gall3,
    GalleryPage?.NuwaraEliya_Gall4,
    GalleryPage?.NuwaraEliya_Gall5,
  ];

  const images = [
    GalleryPage?.Hotel_Img_2,
    GalleryPage?.Hotel_Img_2,
    GalleryPage?.Hotel_Img_2,
  ];

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* The NavBar component should be placed at the top level of your main layout */}
            <NavBar /> {/* Include NavBar at the top */}
      <div style={{ overflow: "hidden" }}>
        {/* <HeaderOne bgImage={AccomadationPage.Header} /> */}
        {/* <NavBar /> */}
        <div>
          <HeaderTwo
            title="Gallery"
            description_="Step into the world of Heaven Seven Kandy through our gallery. Discover elegant spaces, breathtaking views, 
            and serene surroundings that define your unforgettable stay. Let the images inspire your next adventure."
            backgroundImage={GalleryPage.Header}
          />
        </div>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "30px",
            marginTop: 7,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Our Nuwaraeliya Hotel
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Explore the serene beauty of Heaven Seven Nuwaraeliya through
                our gallery, featuring stunning views of misty hills, cozy
                interiors, and tranquil surroundings. Discover where luxury
                meets nature's charm.
              </MKTypography>
            </Grid>
          </Container>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "30px",
            marginTop: 7,
          }}
        >
          <div className="tabs">
            {tabs.map((tab) => (
              <div
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        </Grid>

        <Grid container>
          <div className="slider-container">
            <Slider {...settings}>
              {nuwaraEliyaGal.map((image, index) => (
                <div key={index} className="slide">
                  <img src={image} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </Slider>
            <div className="slider-indicator">01 / {nuwaraEliyaGal.length}</div>
          </div>
        </Grid>

        {/* // //////////////////////////// */}

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "30px",
            marginTop: 7,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="text-with-lines">
                <MKTypography
                  variant="h1"
                  color="black"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    fontFamily: "Italiana",
                    fontSize: "60px",
                    fontWeight: 400,
                  })}
                >
                  Our Kandy Hotel
                </MKTypography>
              </div>
              <MKTypography
                variant="h6"
                fontWeight="regular"
                color="black"
                sx={{
                  textAlign: "center",
                  maxWidth: "90%",
                  fontFamily: "Lexend",
                }}
              >
                Explore the beauty of Heaven Seven Kandy through our gallery,
                showcasing stunning views, elegant rooms, and serene
                surroundings. Discover the perfect blend of modern comfort and
                cultural heritage.
              </MKTypography>
            </Grid>
          </Container>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "30px",
            marginTop: 7,
          }}
        >
          <div className="tabs">
            {tabs.map((tab) => (
              <div
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        </Grid>

        <Grid container>
          <div className="slider-container">
            <Slider {...settings}>
              {kandyGal.map((image, index) => (
                <div key={index} className="slide">
                  <img src={image} alt={`Slide ${index + 1}`} />
                </div>
              ))}
            </Slider>
            <div className="slider-indicator">01 / {kandyGal.length}</div>
          </div>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Gallery;
