import React, { useState, useRef, useEffect } from "react";

const ElfsightWidgetFrame = () => {
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(300);
  const iframeRef = useRef(null);

  // Function to handle messages from the iframe
  const handleMessage = (event) => {
    // Make sure the message is coming from your iframe content
    if (event.data && event.data.type === "expandFrame") {
      // Increase height by the amount specified in the message
      setHeight(
        (currentHeight) => currentHeight + (event.data.heightIncrease || 200)
      );
    }
  };

  // Add and remove event listener for messages
  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: `${height}px`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f9f9f9",
          }}
        >
          Loading widget...
        </div>
      )}

      <iframe
        ref={iframeRef}
        src="/elfsight-widget.html"
        title="Elfsight Widget"
        width="100%"
        height={`${height}px`}
        frameBorder="0"
        scrolling="no"
        onLoad={() => setLoading(false)}
        style={{
          opacity: loading ? 0 : 1,
          transition: "opacity 0.3s ease",
        }}
      />
    </div>
  );
};

export default ElfsightWidgetFrame;
