import React from "react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import View from "layouts/sections/components/View";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import { ReactComponent as LiBeach } from "../../assets/icons/li_beach.svg";
import HeaderTwo from "layouts/sections/page-sections/page-headers/components/HeaderTwo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import {
  UilPlaneDeparture,
  UilTicket,
  UilUtensils,
  UilBedDouble,
  UilSearch,
} from "@iconscout/react-unicons";
import { TextField, InputAdornment } from "@mui/material";
import NavBar from "components/NavBar";
import { BlogsPage } from "constants/images";
import {
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Divider,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function Blogs() {
  const navigate = useNavigate();
  const travelPcgs = [
    {
      title: "Discover the Beauty of Sri Lanka’s Cultural Triangle",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_1,
    },
    {
      title: "Exploring Sri Lanka’s Tea Culture: From Plantation to Cup",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_2,
    },
    {
      title: "Street Food Delights: Exploring Sri Lanka’s Local Flavors",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_3,
    },
    {
      title: "Hidden Gems of Sri Lanka: Off-the-Beaten-Path Destinations",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_4,
    },
    {
      title: "Adventure Travel in Sri Lanka: Hiking, Surfing, and Beyond",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_5,
    },
    {
      title: "Warm Smiles and Open Arms: Experiencing Sri Lankan Hospitality",
      date: "Oct 17 2024",
      img: BlogsPage.Blogs_Card_6,
    },
  ];

  const btnArray = [
    {
      title: "Adventure & Nature",
    },
    {
      title: "Travel Tips",
    },
    {
      title: "Culture & Heritage",
    },
    {
      title: "Guides ",
    },
  ];

  const CustomCard = ({ item, index }) => {
    return (
      <Card
        sx={({ breakpoints }) => ({
          display: "flex",
          flexDirection: "row",
          borderRadius: "15px",
          //boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          border: "solid",
          borderColor: "#C9C5BA",
          backgroundColor: "#FEFDF5",
          flex: 1,
          [breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
          },
        })}
      >
        <CardMedia
          component="img"
          alt="Image"
          image={BlogsPage.Blogs_Top_Card}
          title="title"
          sx={({ breakpoints }) => ({
            borderRadius: "15px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            width: "650px",
            height: "340px",
            margin: 0,
            [breakpoints.down("sm")]: {
              width: "100%",
              height: "auto",
            },
          })}
        />

        <CardContent
          sx={({ breakpoints }) => ({
            display: "flex",
            flexDirection: "column",
            //justifyContent: "center",
            alignSelf: "center",
            backgroundColor: "#FEFDF5",
            flex: 1,
            minHeight: "340px",
            [breakpoints.down("sm")]: {
              height: "auto",
            },
          })}
        >
          <Grid
            container
            display="flex"
            flexDirection="row"
            lg={12}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <MKTypography
              variant="h6"
              fontWeight="regular"
              sx={{
                maxWidth: "100%",
                lineHeight: "30px",
                color: "#017836",
              }}
            >
              Travel Tips
            </MKTypography>
            <Grid>
              <MKTypography
                color="black"
                sx={{
                  fontSize: "16px",
                  fontFamily: "Poppins, sans-serif",
                  lineHeight: "30px",
                }}
              >
                Oct 17 2024
              </MKTypography>
            </Grid>
          </Grid>

          <Grid container display="flex" flexDirection="row" lg={12}>
            <Divider
              variant="middle"
              sx={{
                height: 2,
                width: "100%",
                backgroundColor: "#C9C5BA",
                marginY: 1,
              }}
            />
            <MKTypography
              variant="h1"
              color="black"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                fontFamily: "Lexend, serif",
                fontSize: "24px",
                fontWeight: 400,
                marginBottom: "16px",
              })}
            >
              Unwind on the Golden Beaches of Sri Lanka’s South Coast
            </MKTypography>
            <MKTypography
              variant="h6"
              fontWeight="regular"
              color="black"
              sx={{
                textAlign: "left",
                maxWidth: "100%",
                lineHeight: "19.5px",
              }}
            >
              Explore the most beautiful train journeys in Sri Lanka, offering
              stunning views of tea plantations, waterfalls, and mountains. A
              complete guide to planning an unforgettable wildlife safari in Sri
              Lanka, from choosing the right park to spotting elephants,
              leopards, and more. Find peace and rejuvenation at Sri Lanka’s top
              wellness retreats, offering yoga, meditation, Ayurvedic
              treatments, and more.
            </MKTypography>
            <MKTypography
              sx={{
                fontWeight: "500",
                textDecoration: "underline",
                color: "#000000",
              }}
              mt={2}
              variant="subtitle2"
            >
              Read More
              <ArrowForwardIcon
                sx={{
                  fontSize: "8px", // Icon size
                  marginLeft: "4px", // Gap between text and icon
                  textDecoration: "underline",
                  color: "#000000",
                }}
              />
            </MKTypography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <div style={{ backgroundColor: "#FEFDF5" }}>
      {/* <NavBar /> */}
      <div>
        <HeaderTwo
          title="Blogs"
          description_="Explore our blog for insights, tips, and stories that enhance your travel experience. 
          From local attractions and hidden gems to travel advice and hotel updates, our blog is your guide to making the most of your stay with us."
          backgroundImage={BlogsPage.Header}
        />
      </div>
      {/* Explore our travel Packages */}
      <div style={{ overflow: "hidden" }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginBottom: "40px",
            backgroundColor: "#FEFDF5",
          }}
        >
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            lg={5}
            mt={8}
            mb={1}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UilSearch />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "20px",
                },
              }}
            />
          </Grid>
          <Grid lg={10} sm={12} xs={12}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
              mt={3}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <MKButton
                circular
                variant="contained"
                color="#AF4D06"
                sx={{
                  backgroundColor: "#017836",
                  color: "white",
                  width: "100%",
                  fontSize: { xs: "14px" },
                }}
              >
                All Articles
              </MKButton>
              <MKButton
                circular
                variant="outlined"
                color="black"
                sx={{
                  width: "200px",
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Travel Tips
              </MKButton>
              <MKButton
                circular
                variant="outlined"
                color="black"
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Culture & Heritage
              </MKButton>
              <MKButton
                circular
                variant="outlined"
                color="black"
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Adventure & Nature
              </MKButton>
              <MKButton
                circular
                variant="outlined"
                color="black"
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                Guides
              </MKButton>
            </Stack>
          </Grid>

          <Box
            style={{
              backgroundColor: "#FEFDF5",
            }}
          >
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} sm={6} lg={10.5} sx={{ alignItems: "center" }}>
                <Divider
                  variant="middle"
                  sx={{
                    backgroundColor: "#C9C5BA",
                    height: "2px",
                    opacity: 1,
                    marginY: 4,
                  }}
                />
                <CustomCard />
              </Grid>
            </Grid>
          </Box>

          <Grid container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "90%",
                margin: "0 auto",
                padding: 3,
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                {travelPcgs.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4} // Adjusted for a 3-column layout
                    sx={{ flexShrink: 0 }}
                  >
                    <Card
                      sx={{
                        height: "416px",
                        boxShadow: "none",
                        backgroundColor: "#FEFDF5",
                        borderWidth: 1,
                        borderColor: "#C9C5BA",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardActionArea
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height={"250px"}
                          image={item?.img}
                          sx={{
                            objectFit: "cover",
                            width: "100%",
                            margin: 0,
                            padding: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          alt="SVG Image"
                        />
                        <CardContent sx={{ flex: 1, padding: 1 }}>
                          <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            lg={12}
                            sx={{
                              justifyContent: "space-between",
                            }}
                          >
                            <MKTypography
                              variant="h6"
                              fontWeight="regular"
                              sx={{
                                maxWidth: "100%",
                                lineHeight: "30px",
                                color: "#017836",
                              }}
                            >
                              Travel Tips
                            </MKTypography>
                            <Grid>
                              <MKTypography
                                //color="black"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  fontFamily: "Lexend, sans-serif",
                                  lineHeight: "30px",
                                  color: "#484848",
                                }}
                              >
                                Oct 17 2024
                              </MKTypography>
                            </Grid>
                          </Grid>
                          <Divider
                            variant="middle"
                            sx={{
                              backgroundColor: "##C9C5BA",
                              height: "2px",
                              marginY: 1,
                            }}
                          />

                          <Grid container alignItems="center">
                            <Typography
                              sx={{
                                fontFamily: "Lexend, serif",
                                fontSize: "24px",
                                fontWeight: 400,
                                lineHeight: "100%",
                                color: "#484848",
                              }}
                              variant="h5"
                            >
                              {item?.title}
                            </Typography>
                          </Grid>
                          <MKTypography
                            sx={{
                              fontWeight: "500",
                              textDecoration: "underline",
                              color: "#000000",
                            }}
                            onClick={() => navigate("/pages/blog-article")}
                            mt={2}
                            variant="subtitle2"
                          >
                            Read More
                            <ArrowForwardIcon
                              sx={{
                                fontSize: "8px", // Icon size
                                marginLeft: "4px", // Gap between text and icon
                                textDecoration: "underline",
                                color: "#000000",
                              }}
                            />
                          </MKTypography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <MKButton
            circular
            variant="outlined"
            color="black"
            sx={{
              marginTop: 6,
              width: "100%",
            }}
          >
            Load More
          </MKButton>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Blogs;
