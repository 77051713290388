//Accommadation

import HomeIcon from "assets/icons/homeIcon.png";
import OccupancyIcon from "assets/icons/occupancyIcon.png";
import BedIcon from "assets/icons/bedIcon.png";
import ShowerIcon from "assets/icons/showerIcon.png";
import WifiIcon from "assets/icons/wifiIcon.png";
import CheckmarkIcon from "assets/icons/checkmarkIcon.png";

export const AccomadationIcons = {
  HomeIcon: HomeIcon,
  OccupancyIcon: OccupancyIcon,
  BedIcon: BedIcon,
  ShowerIcon: ShowerIcon,
  WifiIcon: WifiIcon,
  CheckmarkIcon: CheckmarkIcon,
};
