//Tour Listing
import TourListingHeader from "assets/images/tour-listing/tour_listing_header.jpeg";
import Tour1 from "assets/images/tour-listing/round_tours_1.jpeg";
import Tour2 from "assets/images/tour-listing/round_tours_2.jpeg";
import Tour3 from "assets/images/tour-listing/round_tours_3.jpeg";
import Tour4 from "assets/images/tour-listing/round_tours_4.jpeg";
import Tour5 from "assets/images/tour-listing/round_tours_5.jpeg";
import Tour6 from "assets/images/tour-listing/round_tours_6.jpeg";
import DayTour1 from "assets/images/tour-listing/day_tours_1.jpeg";
import DayTour2 from "assets/images/tour-listing/day_tours_2.jpeg";

//MICE Tours
import MiceHeader from "assets/images/mice-tours/Mice-header.jpeg";
import MiceTour1 from "assets/images/mice-tours/mice_tour_1.jpeg";
import MiceTour2 from "assets/images/mice-tours/mice_tour_2.jpeg";
import MiceTour3 from "assets/images/mice-tours/mice_tour_3.jpeg";
import MiceTour4 from "assets/images/mice-tours/mice_tour_4.jpeg";
import MiceExp1 from "assets/images/mice-tours/Mice_Exp_1.png";
import MiceExp2 from "assets/images/mice-tours/Mice_Exp_2.png";
import MiceExp3 from "assets/images/mice-tours/Mice_Exp_3.png";
import MiceExp4 from "assets/images/mice-tours/Mice_Exp_4.png";
import MiceExp5 from "assets/images/mice-tours/Mice_Exp_5.png";
import MiceExp6 from "assets/images/mice-tours/Mice_Exp_6.png";

//Destination Wedding
import Destination_wedding_Header from "assets/images/destination-wedding/destination_wedding_header.jpeg";
import Wedding_Pck_1 from "assets/images/destination-wedding/destination_wedding_pck_1.jpeg";
import Wedding_Pck_2 from "assets/images/destination-wedding/destination_wedding_pck_2.png";
import Wedding_Pck_3 from "assets/images/destination-wedding/destination_wedding_pck_3.png";
import Featurs_1 from "assets/images/destination-wedding/feature_1.png";
import Featurs_2 from "assets/images/destination-wedding/feature_2.png";
import Featurs_3 from "assets/images/destination-wedding/feature_3.png";
import Featurs_4 from "assets/images/destination-wedding/feature_4.png";
import Featurs_5 from "assets/images/destination-wedding/feature_5.png";
import Featurs_6 from "assets/images/destination-wedding/feature_6.png";

//Meetings
import Meeting_Header from "assets/images/meetings/meeting_header.jpeg";
import Meeting_Pck_1 from "assets/images/meetings/meeting_pck_1.jpeg";
import Meeting_Pck_2 from "assets/images/meetings/meeting_pck_2.jpeg";
import Meeting_Feature_1 from "assets/images/meetings/meeting_feature_1.png";
import Meeting_Feature_2 from "assets/images/meetings/meeting_feature_2.png";
import Meeting_Feature_3 from "assets/images/meetings/meeting_feature_3.png";
import Meeting_Feature_4 from "assets/images/meetings/meeting_feature_4.png";
import Meeting_Feature_5 from "assets/images/meetings/meeting_feature_5.png";
import Meeting_Feature_6 from "assets/images/meetings/meeting_feature_6.png";

//Destinations
import Destination_Header from "assets/images/destinations/destination_header.jpeg";
import Destination_1 from "assets/images/destinations/destination_1.jpeg";
import Destination_2 from "assets/images/destinations/destination_2.jpeg";
import Destination_3 from "assets/images/destinations/destination_3.jpeg";
import Destination_4 from "assets/images/destinations/destination_4.jpeg";
import Destination_5 from "assets/images/destinations/destination_5.jpeg";
import Destination_6 from "assets/images/destinations/destination_6.jpeg";
import Destination_7 from "assets/images/destinations/destination_7.jpeg";
import Destination_8 from "assets/images/destinations/destination_8.jpeg";
import Destination_9 from "assets/images/destinations/destination_9.jpeg";

//About Us
import About_Us_Header from "assets/images/about-us/about_us_header.jpeg";
import About_Us_Sub_Header from "assets/images/about-us/about_us_sub_header.jpeg";
import About_Us_Card from "assets/images/about-us/about_us_card.jpeg";
import About_Us_Feature_1 from "assets/images/about-us/about_us_feature_1.png";
import About_Us_Feature_2 from "assets/images/about-us/about_us_feature_2.png";
import About_Us_Feature_3 from "assets/images/about-us/about_us_feature_3.png";
import About_Us_Feature_4 from "assets/images/about-us/about_us_feature_4.png";
import About_Us_Feature_5 from "assets/images/about-us/about_us_feature_5.png";
import Nuwara_Eliya_Hotel from "assets/images/about-us/nuwaraEliya.jpeg";
import Kandy_Hotel from "assets/images/about-us/kandy.jpeg";
import Ameneties_1 from "assets/images/about-us/ameneties_1.jpeg";
import Ameneties_2 from "assets/images/about-us/ameneties_2.jpg";
import Ameneties_3 from "assets/images/about-us/ameneties_3.jpg";
import Ameneties_4 from "assets/images/about-us/ameneties_4.png";

//Contact Us
import Contact_Us_Header from "assets/images/contact-us/contact_us_header.jpeg";
import Contact_Us_Method_1 from "assets/images/contact-us/contact_us_method_1.png";
import Contact_Us_Method_2 from "assets/images/contact-us/contact_us_method_2.png";
import Contact_Us_Method_3 from "assets/images/contact-us/contact_us_method_3.png";
import ContactUs_Map from "assets/images/contact-us/contactUsMap.png";
import ContactUsConnect from "assets/images/contact-us/contactUsConnect.jpeg";

//Accomadation
import Accomadation_Header from "assets/images/accomadation/accHeader.jpeg";
import Hotel_Img_1 from "assets/images/accomadation/hotel_img_1.jpeg";
import Hotel_Img_2 from "assets/images/accomadation/hotel_img_2.jpeg";
import Hotel_Img_3 from "assets/images/accomadation/hotel_img_3.jpeg";
import Mountains from "assets/images/accomadation/mountains.png";
import HotelObj from "assets/images/accomadation/hotelsObj.png";
import Resturant from "assets/images/accomadation/resturant.jpeg";
import Avatar from "assets/images/accomadation/person.jpeg";
import Acc1 from "assets/images/accomadation/Acc1.jpeg";
import Acc2 from "assets/images/accomadation/Acc2.jpeg";
import Exp1 from "assets/images/accomadation/Exp1.jpeg";
import Exp2 from "assets/images/accomadation/Exp2.jpeg";
import Exp3 from "assets/images/accomadation/Exp3.jpeg";
import Exp4 from "assets/images/accomadation/Exp4.jpeg";
import Acc_Gall1 from "assets/images/accomadation/Acc_Gall1.jpeg";
import Acc_Gall2 from "assets/images/accomadation/Acc_Gall2.jpeg";
import Acc_Gall3 from "assets/images/accomadation/Acc_Gall3.jpeg";
import Acc_Gall4 from "assets/images/accomadation/Acc_Gall4.jpeg";
import Acc_Gall5 from "assets/images/accomadation/Acc_Gall5.jpeg";
import Acc_Pub1 from "assets/images/accomadation/Acc_Pub1.jpeg";
import Acc_Pub2 from "assets/images/accomadation/Acc_Pub2.jpeg";
import Acc_Pub3 from "assets/images/accomadation/Acc_Pub3.jpeg";
import Acc_Pub4 from "assets/images/accomadation/Acc_Pub4.jpeg";
import Kandy_Delux_1 from "assets/images/accomadation/Kandy_delux_1.jpg";
import Kandy_Delux_2 from "assets/images/accomadation/Kandy_delux_2.jpg";
import Kandy_Delux_3 from "assets/images/accomadation/Kandy_delux_3.jpg";
import Kandy_Double_1 from "assets/images/accomadation/Kandy_double_1.jpg";
import Kandy_Double_2 from "assets/images/accomadation/Kandy_double_2.jpg";
import Kandy_Double_3 from "assets/images/accomadation/Kandy_double_3.jpg";
import Kandy_Double_4 from "assets/images/accomadation/Kandy_double_4.jpg";
import Kandy_Double_5 from "assets/images/accomadation/Kandy_double_5.jpg";
import Nuwara_Eliya_Gall_1 from "assets/images/accomadation/Kandy_Gall_1.jpg";
import Nuwara_Eliya_Gall_2 from "assets/images/accomadation/Kandy_Gall_2.jpg";
import Nuwara_Eliya_Gall_3 from "assets/images/accomadation/Kandy_Gall_3.jpg";
import Nuwara_Eliya_Gall_4 from "assets/images/accomadation/Kandy_Gall_4.jpg";
import Nuwara_Eliya_Gall_5 from "assets/images/accomadation/Kandy_Gall_5.jpg";
import Kandy_Gall_1 from "assets/images/accomadation/kandy_gall1.jpg";
import Kandy_Gall_2 from "assets/images/accomadation/kandy_gall2.jpg";
import Kandy_Gall_3 from "assets/images/accomadation/kandy_gall3.jpg";
import Kandy_Gall_4 from "assets/images/accomadation/kandy_gall4.jpg";
import Kandy_Gall_5 from "assets/images/accomadation/kandy_gall5.jpg";
import Nuwara_Eliya_Offer_2 from "assets/images/accomadation/nuwara_eliya_offer.jpg";
import Kandy_View from "assets/images/accomadation/kandy_view.jpg";

import Kandy_delux_double_1 from "assets/images/accomadation/Kandy_delux_double_1.jpg";
import Kandy_delux_double_2 from "assets/images/accomadation/Kandy_delux_double_2.jpg";
import Kandy_delux_double_3 from "assets/images/accomadation/Kandy_delux_double_3.jpg";
import Kandy_Exp1 from "assets/images/accomadation/Exp1.jpg";
import Kandy_Exp2 from "assets/images/accomadation/Exp2.jpg";
import Kandy_Exp3 from "assets/images/accomadation/Exp3.jpg";
import Kandy_Exp4 from "assets/images/accomadation/Exp4.jpg";
import Experience1 from "assets/images/accomadation/Experiance1.jpg";
import Experience2 from "assets/images/accomadation/Experiance2.jpg";
import Experience3 from "assets/images/accomadation/Experiance3.jpg";
import Experience5 from "assets/images/accomadation/Experiance5.jpg";

import NuwaraEliya_Super_D1 from "assets/images/accomadation/NuwaraEliya_Super_D1.jpg";
import NuwaraEliya_Super_T1 from "assets/images/accomadation/NuwaraEliya_Super_T1.jpg";
import NuwaraEliya_Super_T2 from "assets/images/accomadation/NuwaraEliya_Super_T2.jpg";
import NuwaraEliya_Super_T3 from "assets/images/accomadation/NuwaraEliya_Super_T3.jpg";
import NuwaraEliya_Super_T4 from "assets/images/accomadation/NuwaraEliya_Super_T4.jpg";
import NuwaraEliya_Super_T5 from "assets/images/accomadation/NuwaraEliya_Super_T5.jpg";
import NuwaraEliya_Super_Q1 from "assets/images/accomadation/NuwaraEliya_Super_Q1.jpg";
import NuwaraEliya_Super_Q2 from "assets/images/accomadation/NuwaraEliya_Super_Q2.jpg";
import NuwaraEliya_Super_Q3 from "assets/images/accomadation/NuwaraEliya_Super_Q3.jpg";
import NuwaraEliya_Delux_Double_1 from "assets/images/accomadation/NuwaraEliya_Delux_Double_1.jpg";
import NuwaraEliya_Delux_Double_2 from "assets/images/accomadation/NuwaraEliya_Delux_Double_2.jpg";
import NuwaraEliya_Delux_Double_3 from "assets/images/accomadation/NuwaraEliya_Delux_Double_3.jpg";
import NuwaraEliya_Delux_Double_Family_1 from "assets/images/accomadation/NuwaraEliya_Delux_Double_Family_1.jpg";
import NuwaraEliya_Delux_Double_Family_2 from "assets/images/accomadation/NuwaraEliya_Delux_Double_Family_2.jpg";
import NuwaraEliya_Delux_Double_Family_3 from "assets/images/accomadation/NuwaraEliya_Delux_Double_Family_3.jpg";
import NuwaraEliya_Delux_Double_Family_4 from "assets/images/accomadation/NuwaraEliya_Delux_Double_Family_4.jpg";
import NuwaraEliya_Attic1 from "assets/images/accomadation/NuwaraEliya_Attic1.jpg";
import NuwaraEliya_Attic2 from "assets/images/accomadation/NuwaraEliya_Attic2.jpg";
import NuwaraEliya_Attic3 from "assets/images/accomadation/NuwaraEliya_Attic3.jpg";
//Blogs
import Blogs_Header from "assets/images/blogs/blogs_header.jpg";
import Blogs_Top_Card from "assets/images/blogs/blog-top-card.png";
import Blogs_Card_1 from "assets/images/blogs/blogs_card_1.png";
import Blogs_Card_2 from "assets/images/blogs/blogs_card_2.png";
import Blogs_Card_3 from "assets/images/blogs/blogs_card_3.png";
import Blogs_Card_4 from "assets/images/blogs/blogs_card_4.png";
import Blogs_Card_5 from "assets/images/blogs/blogs_card_5.png";
import Blogs_Card_6 from "assets/images/blogs/blogs_card_6.png";

//Blog Articles
import Blog_Article_Header from "assets/images/blogArticles/blog_article_header.jpg";
import Blog_Article_1 from "assets/images/blogArticles/blog_article_1.png";
import Blog_Article_2 from "assets/images/blogArticles/blog_article_2.png";

//Tour Planner
import Car from "assets/images/tour-planner/car.png";
import Tour_Guide from "assets/images/tour-planner/Guide.png";
import Driver_Guide from "assets/images/tour-planner/Drive Guide.png";

//LOGOS
import FooterLogo from "assets/images/logos/footerLogo.png";

//Home
import Map from "assets/images/homePage/Map.svg";
import OfferBg from "assets/images/homePage/offerBg.jpeg";
import Home_Header from "assets/images/homePage/homeHeader.jpeg";
import Hotel1 from "assets/images/homePage/hotel1.png";
import Hotel2 from "assets/images/homePage/hotel2.jpg";
import Hotel3 from "assets/images/homePage/hotel3.jpeg";
import Hotel4 from "assets/images/homePage/hotel4.jpeg";
import Home_Acc1 from "assets/images/homePage/home_Acc1.jpeg";
import Home_Acc2 from "assets/images/homePage/home_Acc2.jpeg";
import Home_Acc3 from "assets/images/homePage/home_Acc3.jpeg";
import Home_Video from "assets/images/homePage/home_video.jpeg";
import Home_Exp1 from "assets/images/homePage/home_exp1.jpg";
import Home_Exp2 from "assets/images/homePage/home_exp2.jpg";
import Home_Exp3 from "assets/images/homePage/home_exp3.jpg";
import Home_Offer1 from "assets/images/homePage/home_Offer1.jpg";
import Home_Offer2 from "assets/images/homePage/home_Offer2.jpg";
import Home_Offer3 from "assets/images/homePage/home_Offer3.jpg";
import Home_Offer4 from "assets/images/homePage/home_Offer4.jpg";
import Home_Blog1 from "assets/images/homePage/home_Blog1.jpeg";
import Home_Blog2 from "assets/images/homePage/home_Blog2.jpeg";
import Home_Blog3 from "assets/images/homePage/home_Blog3.jpeg";
import Home_Blog4 from "assets/images/homePage/home_Blog4.jpeg";
import Home_Blog5 from "assets/images/homePage/home_Blog5.jpeg";
import Home_Offer_2 from "assets/images/homePage/home_offer_2.jpg";

//Gallery
import Gallery_Header from "assets/images/gallery/galleryHeader.jpg";
import Gallery_Hotel_Img_1 from "assets/images/gallery/gallery_img_1.jpg";
import Gallery_Hotel_Img_2 from "assets/images/gallery/gallery_img_2.jpg";
import Gallery_Hotel_Img_3 from "assets/images/gallery/gallery_img_3.jpg";
import NuwaraEliya_Gall1 from "assets/images/gallery/NuwaraEliya_Gall1.jpg";
import NuwaraEliya_Gall2 from "assets/images/gallery/NuwaraEliya_Gall2.jpg";
import NuwaraEliya_Gall3 from "assets/images/gallery/NuwaraEliya_Gall3.jpg";
import NuwaraEliya_Gall4 from "assets/images/gallery/NuwaraEliya_Gall4.jpg";
import NuwaraEliya_Gall5 from "assets/images/gallery/NuwaraEliya_Gall5.jpg";
import Kandy_Gall1 from "assets/images/gallery/Kandy_Gall1.jpg";
import Kandy_Gall2 from "assets/images/gallery/Kandy_Gall2.jpg";
import Kandy_Gall3 from "assets/images/gallery/Kandy_Gall3.jpg";

//Experience
import Exp_1 from "assets/images/experience/exp_1.jpeg";
import Exp_2 from "assets/images/experience/exp_2.jpeg";
import Exp_3 from "assets/images/experience/exp_3.jpeg";
import Exp_4 from "assets/images/experience/exp_4.jpeg";

export const ExperiencePage = {
  Exp_1: Exp_1,
  Exp_2: Exp_2,
  Exp_3: Exp_3,
  Exp_4: Exp_4,
};

export const HomePage = {
  Map: Map,
  OfferBg: OfferBg,
  Home_Header: Home_Header,
  Hotel1: Hotel1,
  Hotel2: Hotel2,
  Hotel3: Hotel3,
  Hotel4: Hotel4,
  Home_Acc1: Home_Acc1,
  Home_Acc2: Home_Acc2,
  Home_Acc3: Home_Acc3,
  Home_Video: Home_Video,
  Home_Exp1: Home_Exp1,
  Home_Exp2: Home_Exp2,
  Home_Exp3: Home_Exp3,
  Home_Offer1: Home_Offer1,
  Home_Offer2: Home_Offer_2,
  Home_Offer3: Home_Offer3,
  Home_Offer4: Home_Offer4,
  Home_Blog1: Home_Blog1,
  Home_Blog2: Home_Blog2,
  Home_Blog3: Home_Blog3,
  Home_Blog4: Home_Blog4,
  Home_Blog5: Home_Blog5,
};

export const TourListingPage = {
  Header: TourListingHeader,
  Round_Tour_1: Tour1,
  Round_Tour_2: Tour2,
  Round_Tour_3: Tour3,
  Round_Tour_4: Tour4,
  Round_Tour_5: Tour5,
  Round_Tour_6: Tour6,
  Day_Tour_1: DayTour1,
  Day_Tour_2: DayTour2,
  Day_Tour_3: Tour3,
};

export const MiceToursPage = {
  Header: MiceHeader,
  Mice_Tour_1: MiceTour1,
  Mice_Tour_2: MiceTour2,
  Mice_Tour_3: MiceTour3,
  Mice_Tour_4: MiceTour4,
  Mice_Exp_1: MiceExp1,
  Mice_Exp_2: MiceExp2,
  Mice_Exp_3: MiceExp3,
  Mice_Exp_4: MiceExp4,
  Mice_Exp_5: MiceExp5,
  Mice_Exp_6: MiceExp6,
};

export const DestinationWeddingPage = {
  Header: Destination_wedding_Header,
  Card_1: MiceTour3,
  Wedding_Pck_1: Wedding_Pck_1,
  Wedding_Pck_2: Wedding_Pck_2,
  Wedding_Pck_3: Wedding_Pck_3,
  Featurs_1: Featurs_1,
  Featurs_2: Featurs_2,
  Featurs_3: Featurs_3,
  Featurs_4: Featurs_4,
  Featurs_5: Featurs_5,
  Featurs_6: Featurs_6,
};

export const MeetingsPage = {
  Header: Meeting_Header,
  Meeting_Card_1: MiceTour1,
  Meeting_Pck_1: Meeting_Pck_1,
  Meeting_Pck_2: Meeting_Pck_2,
  Meeting_Pck_3: MiceTour1,
  Meeting_Feature_1: Meeting_Feature_1,
  Meeting_Feature_2: Meeting_Feature_2,
  Meeting_Feature_3: Meeting_Feature_3,
  Meeting_Feature_4: Meeting_Feature_4,
  Meeting_Feature_5: Meeting_Feature_5,
  Meeting_Feature_6: Meeting_Feature_6,
};

export const DestinationPage = {
  Header: Destination_Header,
  Destination_1: Destination_1,
  Destination_2: Destination_2,
  Destination_3: Destination_3,
  Destination_4: Destination_4,
  Destination_5: Destination_5,
  Destination_6: Destination_6,
  Destination_7: Destination_7,
  Destination_8: Destination_8,
  Destination_9: Destination_9,
};

export const AboutUsPage = {
  Header: About_Us_Header,
  About_Us_Card: About_Us_Card,
  Sub_Head: About_Us_Sub_Header,
  About_Us_Feature_1: About_Us_Feature_1,
  About_Us_Feature_2: About_Us_Feature_2,
  About_Us_Feature_3: About_Us_Feature_3,
  About_Us_Feature_4: About_Us_Feature_4,
  About_Us_Feature_5: About_Us_Feature_5,
  Nuwara_Eliya_Hotel: Nuwara_Eliya_Hotel,
  Kandy_Hotel: Kandy_Hotel,
  Ameneties_1: Ameneties_1,
  Ameneties_2: Ameneties_2,
  Ameneties_3: Ameneties_3,
  Ameneties_4: Ameneties_4,
};

export const ContactUsPage = {
  Header: Contact_Us_Header,
  Contact_Us_Card: About_Us_Card,
  Contact_Us_Method_1: Contact_Us_Method_1,
  Contact_Us_Method_2: Contact_Us_Method_2,
  Contact_Us_Method_3: Contact_Us_Method_3,
  ContactUs_Map: ContactUs_Map,
  ContactUsConnect: ContactUsConnect,
};

export const AccomadationPage = {
  Header: Accomadation_Header,
  Hotel_Img_1: Hotel_Img_1,
  Hotel_Img_2: Hotel_Img_2,
  Hotel_Img_3: Hotel_Img_3,
  Hotel_Img_4: Hotel_Img_1,
  Mountains: Mountains,
  HotelObj: HotelObj,
  Resturant: Resturant,
  Avatar: Avatar,
  Acc1: Acc1,
  Acc2: Acc2,
  Exp1: Exp1,
  Exp2: Exp2,
  Exp3: Exp3,
  Exp4: Exp4,
  Acc_Gall1: Acc_Gall1,
  Acc_Gall2: Acc_Gall2,
  Acc_Gall3: Acc_Gall3,
  Acc_Gall4: Acc_Gall4,
  Acc_Gall5: Acc_Gall5,
  Acc_Pub1: Acc_Pub1,
  Acc_Pub2: Acc_Pub2,
  Acc_Pub3: Acc_Pub3,
  Acc_Pub4: Acc_Pub4,
  Kandy_Delux_1: Kandy_Delux_1,
  Kandy_Delux_2: Kandy_Delux_2,
  Kandy_Delux_3: Kandy_Delux_3,
  Kandy_Double_1: Kandy_Double_1,
  Kandy_Double_2: Kandy_Double_2,
  Kandy_Double_3: Kandy_Double_3,
  Kandy_Double_4: Kandy_Double_4,
  Kandy_Double_5: Kandy_Double_5,
  Nuwara_Eliya_Gall_1: Nuwara_Eliya_Gall_1,
  Nuwara_Eliya_Gall_2: Nuwara_Eliya_Gall_2,
  Nuwara_Eliya_Gall_3: Nuwara_Eliya_Gall_3,
  Nuwara_Eliya_Gall_4: Nuwara_Eliya_Gall_4,
  Nuwara_Eliya_Gall_5: Nuwara_Eliya_Gall_5,
  Kandy_Gall_1: Kandy_Gall_1,
  Kandy_Gall_2: Kandy_Gall_2,
  Kandy_Gall_3: Kandy_Gall_3,
  Kandy_Gall_4: Kandy_Gall_4,
  Kandy_Gall_5: Kandy_Gall_5,
  Kandy_delux_double_1: Kandy_delux_double_1,
  Kandy_delux_double_2: Kandy_delux_double_2,
  Kandy_delux_double_3: Kandy_delux_double_3,
  Kandy_Exp1: Kandy_Exp1,
  Kandy_Exp2: Kandy_Exp2,
  Kandy_Exp3: Kandy_Exp3,
  Kandy_Exp4: Kandy_Exp4,
  NuwaraEliya_Super_D1: NuwaraEliya_Super_D1,
  NuwaraEliya_Super_T1: NuwaraEliya_Super_T1,
  NuwaraEliya_Super_T2: NuwaraEliya_Super_T2,
  NuwaraEliya_Super_T3: NuwaraEliya_Super_T3,
  NuwaraEliya_Super_T4: NuwaraEliya_Super_T4,
  NuwaraEliya_Super_T5: NuwaraEliya_Super_T5,
  NuwaraEliya_Super_Q1: NuwaraEliya_Super_Q1,
  NuwaraEliya_Super_Q2: NuwaraEliya_Super_Q2,
  NuwaraEliya_Super_Q3: NuwaraEliya_Super_Q3,
  NuwaraEliya_Delux_Double_1: NuwaraEliya_Delux_Double_1,
  NuwaraEliya_Delux_Double_2: NuwaraEliya_Delux_Double_2,
  NuwaraEliya_Delux_Double_3: NuwaraEliya_Delux_Double_3,
  NuwaraEliya_Delux_Double_Family_1: NuwaraEliya_Delux_Double_Family_1,
  NuwaraEliya_Delux_Double_Family_2: NuwaraEliya_Delux_Double_Family_2,
  NuwaraEliya_Delux_Double_Family_3: NuwaraEliya_Delux_Double_Family_3,
  NuwaraEliya_Delux_Double_Family_4: NuwaraEliya_Delux_Double_Family_4,
  NuwaraEliya_Attic1: NuwaraEliya_Attic1,
  NuwaraEliya_Attic2: NuwaraEliya_Attic2,
  NuwaraEliya_Attic3: NuwaraEliya_Attic3,
  Experience1: Experience1,
  Experience2: Experience2,
  Experience3: Experience3,
  Experience5: Experience5,
  Nuwara_Eliya_Offer_2: Nuwara_Eliya_Offer_2,
  Kandy_View: Kandy_View,
};

export const BlogsPage = {
  Header: Blogs_Header,
  Blogs_Top_Card: Blogs_Top_Card,
  Blogs_Card_1: Blogs_Card_1,
  Blogs_Card_2: Blogs_Card_2,
  Blogs_Card_3: Blogs_Card_3,
  Blogs_Card_4: Blogs_Card_4,
  Blogs_Card_5: Blogs_Card_5,
  Blogs_Card_6: Blogs_Card_6,
};

export const BlogArticlePage = {
  Header: Blog_Article_Header,
  Blog_Article_1: Blog_Article_1,
  Blog_Article_2: Blog_Article_2,
};

export const TourPlannerPage = {
  Car: Car,
  Tour_Guide: Tour_Guide,
  Driver_Guide: Driver_Guide,
};

export const Logos = {
  FooterLogo: FooterLogo,
};

export const GalleryPage = {
  Header: Gallery_Header,
  Hotel_Img_1: Gallery_Hotel_Img_1,
  Hotel_Img_2: Gallery_Hotel_Img_2,
  Hotel_Img_2: Gallery_Hotel_Img_3,
  NuwaraEliya_Gall1: NuwaraEliya_Gall1,
  NuwaraEliya_Gall2: NuwaraEliya_Gall2,
  NuwaraEliya_Gall3: NuwaraEliya_Gall3,
  NuwaraEliya_Gall4: NuwaraEliya_Gall4,
  NuwaraEliya_Gall5: NuwaraEliya_Gall5,
  Kandy_Gall1: Kandy_Gall1,
  Kandy_Gall2: Kandy_Gall2,
  Kandy_Gall3: Kandy_Gall3,
};
