export const hotelRooms = [
    {
        location: "Kandy",
        rooms: [
            "Deluxe Double Room",
            "Deluxe Triple Room",
            "Super Deluxe Double Room",
            "Super Deluxe TPL Room",
        ]
    },
    {
        location: "Nuwara Eliya",
        rooms: [
            "Deluxe Double Room without Balcony",
            "Family Room",
            "Deluxe Double Room with Balcony",
            "Attic Room",
            "Superior Quadruple Room",
            "Superior Triple Room",
            "Superior Double Room",
        ]
    }
  ];

