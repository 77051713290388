// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";
import Home from "layouts/pages/Home";
import TourListing from "pages/TourListing";
import Destination from "pages/Destination";
import Accomadation from "pages/Accomadation";
import Blogs from "pages/Blogs";
import BlogArticle from "pages/BlogArticle";
import TourDetails from "pages/TourDetails";
import TourPlanner from "pages/TourPlanner";
import TourReview from "pages/TourReview";
import TourMap from "pages/TourMap";
import Gallery from "pages/Gallery";
import Experience from "pages/Experience";
import GiftVouchers from "pages/GiftVouchers";
import AccomadationNuwaraEliya from "pages/Accomadation_NuwaraEliya";
import ContactUsTrail from "pages/LandingPages/ContactUsTrail";
import ThankYou from "pages/ThankYou";

const routes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "about us",
            route: "/pages/about-us",
            component: <AboutUs />,
          },
          {
            name: "contact us",
            route: "/pages/contact-us",
            component: <ContactUs />,
          },
          {
            name: "author",
            route: "/pages/landing-pages/author",
            component: <Author />,
          },
          {
            name: "home",
            route: "/pages/Home",
            component: <Home />,
          },
          {
            name: "tourList",
            route: "/pages/tour-list",
            component: <TourListing />,
          },
          {
            name: "destinations",
            route: "/pages/destinations",
            component: <Destination />,
          },
          {
            name: "accomadation",
            route: "/pages/accomadation",
            component: <Accomadation />,
          },
          {
            name: "blogs",
            route: "/pages/blogs",
            component: <Blogs />,
          },
          {
            name: "blogArticle",
            route: "/pages/blog-article",
            component: <BlogArticle />,
          },
          {
            name: "tourDetails",
            route: "/pages/tour-details",
            component: <TourDetails />,
          },
          {
            name: "tourPlanner",
            route: "/pages/tour-planner",
            component: <TourPlanner />,
          },
          {
            name: "tourReview",
            route: "/pages/tour-review",
            component: <TourReview />,
          },
          {
            name: "tourMap",
            route: "/pages/tour-map",
            component: <TourMap />,
          },
          {
            name: "gallery",
            route: "/pages/gallery",
            component: <Gallery />,
          },
          {
            name: "experience",
            route: "/pages/experience",
            component: <Experience />,
          },
          {
            name: "giftVouchers",
            route: "/pages/gift-vouchers",
            component: <GiftVouchers />,
          },
          {
            name: "accomadation_nuwaraEliya",
            route: "/pages/acc_nuwaraeliya",
            component: <AccomadationNuwaraEliya />,
          },
          {
            name: "conatctUsTrail",
            route: "/pages/contactUsTrail",
            component: <ContactUsTrail />,
          },
          {
            name: "thankYou",
            route: "/pages/thank-you",
            component: <ThankYou />,
          },
        ],
      },
      {
        name: "account",
        collapse: [
          {
            name: "sign in",
            route: "/pages/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
    ],
  }
];

export default routes;
